import { useState, useEffect } from "react";
import { getFileNameBasedOnCurrentDateTime } from "../utils/file";
import getNewOrdersModuleAssetUrl from "mocks/getNewOrdersModuleAssetUrl";
import { NewOrderSummaryDetailModel } from "components/NewOrders/NewOrdersHeader/new-order-summary-details-model.ts";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store.tsx";
import { AddOnText } from "constants/text";
import { NewOrderNewOrderLabel } from "components/NewOrders/constants";
import { ConfigurationGroup } from "components/NewOrders/utils/NewOrderUtils";
import { models } from "types/api/viewModels";
import { ValidateProductsResponseData } from "types/api/products/postValidate";

const useNewOrdersPDFGenerator = (
	configurationGroups: ConfigurationGroup[] | null | undefined,
	headerText: string,
	subtotal: number,
	orderSummary: NewOrderSummaryDetailModel | undefined,
	orderDetails?: PostOrdersRequestBody | undefined,
	isReview?: boolean,
	isConfirmation?: boolean,
	originalOrderDetails?: models["OrderDetailViewModel"] | null,
	isAddOn?: boolean,
	csvData?: (models["ImportedOrderViewModel"] | undefined)[],
	validatedOrderResponse?: ValidateProductsResponseData
) => {
	const [pdfData, setPdfData] = useState<PDFData>({ blob: null, loading: false, error: null });
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);

	const generateNewOrdersPDF = () => {
		const worker = getNewOrdersModuleAssetUrl();

		worker.postMessage({
			configurationGroups,
			orderSummary,
			headerText,
			subtotal,
			orderDetails,
			isReview,
			isConfirmation,
			productLineAccountInfo,
			originalOrderDetails,
			isAddOn,
			csvData,
			validatedOrderResponse
		});

		worker.onmessage = (e) => {
			if (e.data.blob) {
				setPdfData({ blob: e.data.blob, loading: false, error: null });
			} else if (e.data.error) {
				setPdfData((prevData) => ({ ...prevData, loading: false, error: e.data.error }));
			}
		};

		worker.onerror = (error) => {
			setPdfData((prevData) => ({
				...prevData,
				loading: false,
				error: error instanceof Error ? error : new Error("An unknown error occurred")
			}));
		};

		setPdfData((prevData) => ({ ...prevData, loading: true }));
	};
	const jobName = orderDetails?.jobName ? `_${orderDetails.jobName}` : "";
	const poNumber = orderDetails?.poNumber ? `_PO${orderDetails.poNumber}` : "";
	const filePrefix = `${isAddOn ? AddOnText : NewOrderNewOrderLabel.replace(" ", "")}${jobName}${poNumber}`;

	useEffect(() => {
		if (pdfData.blob) {
			const url = window.URL.createObjectURL(pdfData.blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${getFileNameBasedOnCurrentDateTime(filePrefix)}.pdf`);
			document.body.appendChild(link);
			link.click();
			setPdfData({ blob: null, loading: false, error: null });
		}
	}, [pdfData.blob, jobName, poNumber, filePrefix]);

	return { pdfData, generateNewOrdersPDF };
};

interface PDFData {
	blob: Blob | null;
	loading: boolean;
	error: Error | null;
}

export default useNewOrdersPDFGenerator;
