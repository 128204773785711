import styles from "../order-status-page-styles.module.css";
import { Typography } from "@mui/material";
import { NotFoundHeader, NotFoundText, ReturnToMyOrdersButtonText } from "constants/text";
import React from "react";
import { useNavigate } from "react-router";
import { ErrorButtonWrapper } from "../ErrorModal.styles";
const NotFound = () => {
	const navigateTo = useNavigate();

	return (
		<div
			className={styles.errorContainer}
			data-testid="not-found-container"
		>
			<div className={styles.errorContent}>
				<img
					src="/assets/tandem_logos/tandem.png"
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="not-found-tandem-logo"
				/>
				<img
					src="/assets/NotFound.svg"
					alt="Page not found logo"
					className={styles.noServiceSVG}
					data-testid="not-found-logo"
				/>

				<Typography sx={{ fontSize: "12px !important" }}>(Error Code: 404)</Typography>

				<div data-testid="not-found-content">
					<h1 data-testid="not-found-header">{NotFoundHeader}</h1>
					<p data-testid="not-found-description">{NotFoundText}</p>
				</div>

				<ErrorButtonWrapper
					variant="contained"
					onClick={() => navigateTo("/projects")}
					data-testid="not-found-error-home-button"
				>
					{ReturnToMyOrdersButtonText}
				</ErrorButtonWrapper>
			</div>
		</div>
	);
};

export default NotFound;
