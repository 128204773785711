import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";

import { baseQueryWithRedirect } from "features/api/utils/apiUtils.ts";
import { models } from "types/api/viewModels";

export const configurationsApiSlice = createApi({
	reducerPath: "configurationsApi",
	baseQuery: baseQueryWithRedirect(baseURL, false),
	endpoints: (builder) => ({
		getConfigurations: builder.query({
			query: () => endpoints.configurations.getConfigurations(),
			transformResponse: (response: { data: models["ConfigurationViewModel"] }) => response.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		})
	})
});

export const { useGetConfigurationsQuery } = configurationsApiSlice;
