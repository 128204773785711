import {
	BuildNewOrderLineItemsHeader,
	NewOrderNumberHeader,
	StaticComponentBackground,
	StaticComponentTitle
} from "../NewOrders.styles";
import {
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderConfirmationOrderNumber,
	NewOrderConfirmationOrderPending,
	NewOrderOrderListHeader,
	NewOrderViewPriceEstimateEstimatedSubtotalHeader,
	NewOrderViewPriceEstimateExtendedPriceColumnHeader,
	NewOrderViewPriceEstimateListPriceColumnHeader
} from "../constants";
import { ReviewOrderListLineItemContainer } from "./reviewOrder.styles";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { FC, useMemo } from "react";
import {
	EstimatesLineItemGrid,
	LineItemCount,
	TotalPriceContainer
} from "../ViewPriceEstimates/viewPriceEstimates.styles";
import { EditText, UnavailableDataPlaceholderText } from "constants/text";
import Header from "../../Common/LineItemGrid/Header/Header";
import EstimatedSubtotal from "../ViewPriceEstimates/EstimatedSubtotal";
import Modification from "components/Common/LineItemGrid/Modification/Modification";
import ReviewOrderAccountBillTo from "../ReviewOrderAccountBillTo/ReviewOrderAccountBillTo";
import {
	ConfigurationGroup,
	getAttributes,
	getConfigDisplay,
	getConfigurationGroupsByProductLine,
	getLineItemCount,
	getSubheaderAttributes
} from "components/NewOrders/utils/NewOrderUtils.tsx";
import { Divider } from "@mui/material";
import { LinkButton } from "../../Common/Link";
import { useNavigate, useParams } from "react-router";
import { components } from "types/declarations/api";
import { models } from "types/api/viewModels";

interface ReviewOrderListProps {
	isConfirmationPage?: boolean;
	isAddOn?: boolean;
}

const ReviewOrderList: FC<ReviewOrderListProps> = ({ isConfirmationPage, isAddOn }) => {
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const newOrderDetails = useSelector((state: RootState) => state.newOrder.newOrderDetails);
	const importedCSVData = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const navigate = useNavigate();
	const params = useParams();

	const groupedDisplayConfigs = useMemo<ConfigurationGroup[] | undefined>(
		() => getConfigurationGroupsByProductLine(newOrderDetails?.configurations),
		[newOrderDetails?.configurations]
	);

	const handleEdit = () => {
		navigate(`/${isAddOn ? "add-ons" : "new-order"}/build-order${isAddOn ? "/" + params.orderId : ""}`);
	};

	const lineItemsComponent: (
		config: models["PendingOrderConfigurationViewModel"] | models["ValidatedOrderConfigurationViewModel"],
		item: components["schemas"]["PendingLineItemViewModel"]
	) => any = (config, item) => (
		<ReviewOrderListLineItemContainer key={item?.lineItemNumber}>
			<EstimatesLineItemGrid
				mainRowContent={[
					<Header
						description={item.description ?? UnavailableDataPlaceholderText}
						key={config.configurationId}
						header={item.sku ?? UnavailableDataPlaceholderText}
						lineNumber={item.lineItemNumber}
					/>,
					<div key={`${String(item.lineItemNumber)}-door-style`}>
						<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
						<div data-testid="new-order-review-order-list-line-item-doorStyle">
							{config.globals?.style?.description}
						</div>
					</div>,
					<div key={`${String(item.lineItemNumber)}-finish`}>
						<div>{NewOrderBuildGridFinishColumnHeader}</div>
						<div data-testid="new-order-review-order-list-line-item-finish">
							{config.globals?.finish?.description}
						</div>
					</div>,
					<div key={`${item.lineItemNumber}-qty`}>
						<div>{NewOrderBuildGridQuantityColumnHeader}</div>
						<div data-testid="new-order-review-order-list-line-item-quantity">{item.quantityOrdered}</div>
					</div>,
					<div key={`${String(item.lineItemNumber)}-list-price`}>
						<div>{NewOrderViewPriceEstimateListPriceColumnHeader}</div>
						<div data-testid="new-order-review-order-list-line-item-listPrice">
							{item?.listPrice?.toLocaleString("en-US", {
								style: "currency",
								currency: "USD"
							})}
						</div>
					</div>,
					<div key={`${String(item.lineItemNumber)}-extended-price`}>
						<div>{NewOrderViewPriceEstimateExtendedPriceColumnHeader}</div>
						<div data-testid="new-order-review-order-list-line-item-extendedPrice">
							{item?.extendedPrice?.toLocaleString("en-US", {
								style: "currency",
								currency: "USD"
							})}
						</div>
					</div>
				]}
				modificationRows={item.modifications?.map((modification) => (
					<Modification
						description={modification.description ?? undefined}
						key={modification.sku}
						extendedPrice={item.extendedPrice}
						lineNumber={modification.lineItemNumber}
						listPrice={item.listPrice}
						title={modification.sku ?? undefined}
						values={modification.values}
					/>
				))}
				dimensionRows={item.requiredDimensions?.map((dimension: any, index: number) => (
					<Modification
						dimensionDescription={dimension.description}
						key={dimension.id}
						dimensionValue={dimension.value}
						lineNumber=""
					/>
				))}
				requiredCategoriesRows={item.requiredCategories?.map((category) => (
					<Modification
						key={category.categoryName}
						requiredCategory={category}
						lineNumber=""
					/>
				))}
			/>
		</ReviewOrderListLineItemContainer>
	);

	return (
		<>
			<StaticComponentBackground>
				<StaticComponentTitle data-testid="new-order-review-order-list-title">
					{NewOrderOrderListHeader}
					{!isConfirmationPage && (
						<span>
							<LinkButton
								data-testid="new-order-review-edit"
								onClick={handleEdit}
							>
								{EditText}
							</LinkButton>
						</span>
					)}
				</StaticComponentTitle>
				{groupedDisplayConfigs?.map((group, groupIndex) => (
					<div key={group.id}>
						{isConfirmationPage && !location.pathname.includes("add-ons") && (
							<NewOrderNumberHeader>
								<div>
									<div data-testid="new-order-confirmation-order-number">
										{NewOrderConfirmationOrderNumber}
									</div>
									<div data-testid="new-order-confirmation-pending">
										&nbsp;
										{NewOrderConfirmationOrderPending}
									</div>
								</div>
							</NewOrderNumberHeader>
						)}
						<ReviewOrderAccountBillTo newOrderConfig={group.configurations?.[0]} />
						<LineItemCount data-testid="new-order-build-table-count">
							{getLineItemCount(group)}
						</LineItemCount>
						{group.configurations?.map((config, configIndex) => (
							<div key={config.configurationId}>
								<BuildNewOrderLineItemsHeader>
									<div data-testid="new-order-build-label">
										<div>{getAttributes(config)}</div>
										<div>{getSubheaderAttributes(config)}</div>
									</div>
								</BuildNewOrderLineItemsHeader>
								{config?.lineItems?.map((item) => lineItemsComponent(config, item))}
								{getConfigDisplay(config, importedCSVData)}
								{configIndex !==
									(groupedDisplayConfigs?.[groupIndex]?.configurations?.length ?? 0) - 1 && (
									<Divider sx={{ margin: "24px 0", padding: 0 }} />
								)}
							</div>
						))}
						<TotalPriceContainer>
							<p>{NewOrderViewPriceEstimateEstimatedSubtotalHeader}</p>
							<p data-testid="new-order-review-order-list-extendedPrice-configuration">
								{group.configurations
									.reduce((accum: number, current) => {
										const price = "pricing" in current ? (current.pricing?.extendedPrice ?? 0) : 0;
										return accum + price;
									}, 0)
									?.toLocaleString("en-US", {
										style: "currency",
										currency: "USD"
									})}
							</p>
						</TotalPriceContainer>
						{groupIndex < groupedDisplayConfigs.length - 1 && (
							<Divider sx={{ margin: "24px 0", padding: 0 }} />
						)}
					</div>
				))}
			</StaticComponentBackground>
			<EstimatedSubtotal
				data-testid="new-order-review-order-list-extendedPrice-full-order"
				subtotal={validatedOrderResponse?.pricing?.extendedPrice ?? 0}
			/>
		</>
	);
};

export default ReviewOrderList;
