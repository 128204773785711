import styled from "@emotion/styled";

interface SummaryCardLogo {
	parentBrand: any;
}

const smallLogos: Array<string> = ["HAMPTON_BAY"];
const largeLogos: Array<string> = [
	"UNKNOWN",
	"YORKTOWNE",
	"CARDELL_CABINETRY",
	"MASTERCRAFT",
	"QUALITYCABINETS",
	"MASTERPIECE"
];

const StyledSummaryCardLogo = styled("img")<SummaryCardLogo>(({ parentBrand }) => ({
	// eslint-disable-next-line sonarjs/no-nested-conditional
	height: smallLogos.includes(parentBrand) ? ".75rem" : largeLogos.includes(parentBrand) ? "1.5rem" : "1.125rem",
	alignSelf: "end"
}));

export default StyledSummaryCardLogo;
