/**
 *
 * @export
 * @enum {string}
 */
export enum NewOrderModalTracking {
	CSV_UPLOAD = "CSV_UPLOAD",
	SELECT_BILLTO_ACCOUNT = "SELECT_BILLTO_ACCOUNT",
	CANCEL_ORDER = "CANCEL_ORDER",
	NONE = "NONE",
	BUILD_ORDER_OPTIONS = "BUILD_ORDER_OPTIONS",
	MANUAL_ORDER_ENTRY = "MANUAL_ORDER_ENTRY"
}
