import { type FC, type PropsWithChildren } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { schema } from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { convertNewOrderToShipTo } from "utils/address";

const NewOrderFormProvider: FC<PropsWithChildren> = ({ children }) => {
	const newOrder = useSelector((state: RootState) => state.newOrder.newOrderDetails);

	const methods = useForm({
		resolver: yupResolver(schema),
		context: {
			isPhoneRequired: true
		},
		defaultValues: {
			designer: newOrder?.designerNumber ?? undefined,
			poNumber: newOrder?.poNumber ?? undefined,
			jobName: newOrder?.jobName ?? undefined,
			email: newOrder?.shipToAddress?.email ?? undefined,
			phoneNumber: newOrder?.shipToAddress?.phoneNumber ?? undefined,
			deliveryInstructions: newOrder?.labelComments ?? undefined,
			address: newOrder ? convertNewOrderToShipTo(newOrder) : undefined,
			requestedDeliveryDate: newOrder?.requestedDeliveryDate ?? undefined,
			isCustomerPickup: newOrder?.isCustomerPickup ?? undefined
		}
	});

	return <FormProvider {...methods}>{children}</FormProvider>;
};

export default NewOrderFormProvider;
