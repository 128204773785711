import { useDispatch, useSelector } from "react-redux";
import { NewOrdersDialogWrapper } from "../NewOrders.styles";
import { RootState } from "stores/application.store";
import { NewOrderModalTracking } from "data/api/v1";
import AccountBillToDialogForm from "../AccountBillToDialogForm";
import { updateOrderDetails } from "pages/OrderPages/utils/OrderDetailUtil";
import { useLocation, useNavigate, useParams } from "react-router";
import CSVUpload from "../CSVUpload";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { models } from "types/api/viewModels.ts";
import CancelOrderDialog from "../CancelOrderDialog.tsx";
import BuildOrderOptionsDialog from "../BuildOrderOptionsDialog/BuildOrderOptionsDialog.tsx";

interface NewOrderDialog {
	isAddOn?: boolean;
	orderDetails?: models["OrderDetailViewModel"];
}

const NewOrdersDialog = ({ isAddOn, orderDetails }: NewOrderDialog) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const modalState = useSelector((state: RootState) => state.newOrder.modalStatus);
	const modalStateCSV = modalState === NewOrderModalTracking.CSV_UPLOAD;
	const modalStateCancelOrder = modalState === NewOrderModalTracking.CANCEL_ORDER;
	const modalStateAccount = modalState === NewOrderModalTracking.SELECT_BILLTO_ACCOUNT;
	const modalStateBuildOrderOptions = modalState === NewOrderModalTracking.BUILD_ORDER_OPTIONS;
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const tempCSVContents = useSelector((state: RootState) => state.newOrder.parsedTempCSV);
	const csvContents = tempCSVContents ?? parsedCSV;

	const handleCloseModal = () => {
		if (location.pathname === "/new-order" || location.pathname === `/add-ons/${params?.orderId}`) {
			dispatch(newOrderActions.clearNewOrder());
		}
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
	};

	const draftOrder = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);

	const handleStartOver = () => {
		dispatch(newOrderActions.clearNewOrder());
	};

	const handleModalContinue = () => {
		const csvConfigurations = csvContents
			?.map((file) => file?.configurations?.map((config) => config ?? []))
			.filter((file) => typeof file !== "undefined")
			.flatMap((file) => file);
		updateOrderDetails(
			[...(draftOrder?.configurations ?? []), ...(csvConfigurations ?? [])],
			dispatch,
			draftOrder,
			isAddOn,
			params.orderId
		);

		if (isAddOn) {
			navigate(`/add-ons/build-order/${params.orderId}`);
		} else {
			navigate("/new-order/build-order");
		}
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
		if (tempCSVContents) {
			dispatch(newOrderActions.overwriteParsedCSV(tempCSVContents));
			dispatch(newOrderActions.addMultiConfigsToOrder(tempCSVContents.flatMap((e) => e?.configurations ?? [])));
		}
	};

	return (
		<NewOrdersDialogWrapper
			open={modalStateCSV || modalStateAccount || modalStateCancelOrder || modalStateBuildOrderOptions}
			onClose={handleCloseModal}
			disableEscapeKeyDown={modalStateAccount}
			isCSVModal={modalStateCSV}
			isCancelModal={modalStateCancelOrder}
		>
			{modalStateAccount && (
				<AccountBillToDialogForm
					handleFindOriginalOrder={handleStartOver}
					handleModalContinue={handleModalContinue}
					csvContents={csvContents}
					isAddOn={isAddOn}
					orderDetails={orderDetails}
				/>
			)}

			{modalStateCSV && <CSVUpload handleClose={handleCloseModal} />}
			{modalStateCancelOrder && (
				<CancelOrderDialog
					handleClose={handleCloseModal}
					orderDetails={orderDetails}
				/>
			)}
			{modalStateBuildOrderOptions && <BuildOrderOptionsDialog onClose={handleCloseModal} />}
		</NewOrdersDialogWrapper>
	);
};

export default NewOrdersDialog;
