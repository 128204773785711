import { FilterType } from "../../../../OrderSearch/filterType.ts";
import ChipDropdownMenu, { ChipDropdownMenuProps } from "../../../../OrderSearch/ChipDropdownMenu.tsx";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { SaveDraftFilters } from "../SaveDraftTable.styles";
import { models } from "types/api/viewModels.ts";
import { displayDraftDesigner } from "utils/order.ts";

interface DraftDetails extends ChipDropdownMenuProps {
	dataTestId: string;
	isVisible: boolean;
	GTMIdentifier: string;
}

interface SaveDraftTableFilterProps {
	filterOptions: FilterOpts;
	setFilterOptions: Dispatch<SetStateAction<FilterOpts>>;
	drafts: models["DraftOrderViewModel"][];
	activeView: "template" | "draft";
}

const SaveDraftTableFilters = ({ filterOptions, setFilterOptions, drafts, activeView }: SaveDraftTableFilterProps) => {
	const designerName: string[] | undefined = useMemo(
		() =>
			drafts
				?.map((draft) => {
					return displayDraftDesigner(draft.designerName, draft.designerNumber, true);
				})
				.filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index)
				.sort(),
		[drafts]
	);

	const accounts: (string[] | undefined)[] | undefined = useMemo(
		() =>
			drafts?.map((draft) => {
				return draft.configurations
					?.map((config) => {
						return `${config.accountNumber} - ${config.accountName}`;
					})
					.filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index);
			}),
		[drafts]
	);

	const orderTypes = useMemo(
		() =>
			drafts
				?.reduce((accum: string[], order) => {
					if (order.orderType) {
						accum.push(order.orderType.replace(/_/g, " "));
					}
					return accum;
				}, [])
				.filter((item, index, arr) => arr.indexOf(item) === index)
				.sort((a, b) => a.localeCompare(b)),
		[drafts]
	);

	const flattenedAccounts: (string | undefined)[] | undefined = useMemo(() => accounts?.flat().sort(), [accounts]);
	const filteredAccounts: any = useMemo(() => [...new Set(flattenedAccounts)], [flattenedAccounts]);

	const onFilterChange = useCallback(
		(id: string, values: string[]) => {
			setFilterOptions((prev: FilterOpts) => ({
				...prev,
				[id]: values
			}));
		},
		[setFilterOptions]
	);

	const draftDetails: DraftDetails[] = useMemo(() => {
		const filterConfig: DraftDetails[] = [
			{
				selectedItems: filterOptions?.accounts,
				onChange: (v) => onFilterChange("accounts", v),
				filterType: FilterType.DRAFT_ACCOUNT,
				items: filteredAccounts ?? [],
				dataTestId: "save-draft-table-account-filter-chip",
				isSearchable: true,
				hasWarning: false,
				inactiveAccounts: [],
				activeAccounts: [],
				children: undefined,
				isVisible: true,
				GTMIdentifier: "save-draft-table-account-filter-chip"
			},
			{
				selectedItems: filterOptions?.designers,
				onChange: (v) => onFilterChange("designers", v),
				filterType: FilterType.DRAFT_DESIGNER,
				items: designerName ?? [],
				dataTestId: "save-draft-table-designer-filter-chip",
				isSearchable: true,
				hasWarning: false,
				inactiveAccounts: [],
				activeAccounts: [],
				children: undefined,
				isVisible: true,
				GTMIdentifier: "save-draft-table-designer-filter-chip"
			}
		];

		if (activeView === "draft") {
			filterConfig.push({
				selectedItems: filterOptions?.types,
				onChange: (v) => onFilterChange("types", v),
				filterType: FilterType.ORDER_TYPE,
				items: orderTypes ?? [],
				dataTestId: "save-draft-table-type-filter-chip",
				isSearchable: false,
				hasWarning: false,
				inactiveAccounts: [],
				activeAccounts: [],
				children: undefined,
				isVisible: true,
				GTMIdentifier: "save-draft-table-type-filter-chip"
			});
		}

		return filterConfig;
	}, [
		filterOptions?.accounts,
		filterOptions?.designers,
		filterOptions?.types,
		filteredAccounts,
		designerName,
		activeView,
		onFilterChange,
		orderTypes
	]);

	return (
		<SaveDraftFilters>
			{draftDetails?.map(
				(draft) =>
					draft.isVisible && (
						<ChipDropdownMenu
							selectedItems={draft.selectedItems}
							onChange={draft.onChange}
							filterType={draft.filterType}
							items={draft.items}
							data-testid={draft.dataTestId}
							hasWarning={draft.hasWarning}
							isSearchable={draft.isSearchable}
							key={draft.filterType}
							GTMIdentifier={draft.GTMIdentifier}
						>
							{draft.children}
						</ChipDropdownMenu>
					)
			)}
		</SaveDraftFilters>
	);
};

export default SaveDraftTableFilters;
