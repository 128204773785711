import { FormHelperText, TextField } from "@mui/material";
import {
	JobNameOptionalText,
	PONumberText,
	ReplacementReviewOrderDetailsHeader,
	RequiredFieldsText
} from "constants/text";
import { Controller, useFormContext } from "react-hook-form";
import {
	JOB_NAME_MAX_LENGTH,
	PO_NUMBER_MAX_LENGTH,
	schema
} from "components/NewOrders/NewOrderShippingDetailsForm/schema";
import { getFormFieldProps } from "utils/form";
import { NewOrdersDesignersWrapper, RequiredFieldsTextWrapper, ShippingDetailsCard } from "./NewOrders.styles";
import { useEffect, useState } from "react";
import {
	DesignerConnectionHeaderShippingDetails,
	DesignerIsNotHereText,
	EnterDesignerHelperText,
	NewOrderDesignerNumberText,
	NewOrderEnterDesignerNumberText,
	OptionalText,
	RequiredText
} from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { models } from "types/api/viewModels";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { DesignerViewModel } from "data/api/v1";
import { displayDesigner } from "utils/order";
import useNewOrderDesigners from "hooks/useNewOrderDesigners";
import DesignerFormAlert from "./DesignerFormAlert/DesignerFormAlert";

export interface ShippingOrderDetailsDefaultValues {
	poNumber?: string;
	jobName?: string;
}

export interface ShippingOrderDetailsProp {
	defaultValues?: ShippingOrderDetailsDefaultValues;
	originalOrderDesigner?: models["DesignerViewModel"];
}

const ShippingOrderDetails = ({ defaultValues, originalOrderDesigner }: ShippingOrderDetailsProp) => {
	const { formState, register, watch, control, setValue, clearErrors } = useFormContext();
	const { errors } = formState;
	const formFieldData = { formState, register, schema };
	const poNumberInput = watch("poNumber") ?? "";
	const jobNameInput = watch("jobName") ?? "";
	const designer = watch("designer");
	const currentOrder = useSelector((state: RootState) => state.newOrder.newOrderDetails);
	const {
		findDesignerNumberInDesignerResponse,
		setDefaultDesigner,
		addDesignerNumberToOrder,
		designerOptions,
		isLoadingDesigners
	} = useNewOrderDesigners();
	useEffect(() => {
		if (!currentOrder?.poNumber && defaultValues?.poNumber) setValue("poNumber", defaultValues?.poNumber);
		if (!currentOrder?.jobName && defaultValues?.jobName) setValue("jobName", defaultValues?.jobName);
	}, [defaultValues?.poNumber, setValue, defaultValues?.jobName, currentOrder?.jobName, currentOrder?.poNumber]);
	const [isCustomDesigner, setIsCustomDesigner] = useState(false);

	useEffect(() => {
		if (designer !== DesignerIsNotHereText) {
			clearErrors("customDesigner");
		}
	}, [designer, clearErrors]);

	useEffect(() => {
		const designerNumber = currentOrder?.designerNumber;
		const foundDesignerNumber = findDesignerNumberInDesignerResponse(
			designerNumber ?? originalOrderDesigner?.designerNumber
		);
		if (!foundDesignerNumber && (designerNumber || originalOrderDesigner?.designerNumber)) {
			setValue("customDesigner", designerNumber ?? originalOrderDesigner?.designerNumber);
			setIsCustomDesigner(true);
		} else {
			setValue("designer", foundDesignerNumber);
			setIsCustomDesigner(false);
		}
	}, [setValue, originalOrderDesigner, currentOrder?.designerNumber, findDesignerNumberInDesignerResponse]);

	useEffect(() => {
		setDefaultDesigner();
	}, [setDefaultDesigner]);

	// Below code allows the DesignerIsNotHereText to ALWAYS appear as an option as the user searches in the AutoComplete component, no matter what they type in
	const filterOptions = (options: (DesignerViewModel | string)[], { inputValue }: { inputValue: string }) => {
		const filtered = options.filter(
			(option) =>
				typeof option !== "string" && displayDesigner(option).toUpperCase().includes(inputValue.toUpperCase())
		);
		if (!filtered.includes(DesignerIsNotHereText) && options.includes(DesignerIsNotHereText)) {
			filtered.push(DesignerIsNotHereText);
		}
		return filtered;
	};

	return (
		<ShippingDetailsCard>
			<div data-testid="new-orders-shipping-order-details-header">{ReplacementReviewOrderDetailsHeader}</div>
			{(designer === DesignerIsNotHereText || isCustomDesigner) && (
				<DesignerFormAlert header={DesignerConnectionHeaderShippingDetails} />
			)}
			<RequiredFieldsTextWrapper>{RequiredFieldsText}</RequiredFieldsTextWrapper>
			<NewOrdersDesignersWrapper isCustomDesigner={designer === DesignerIsNotHereText}>
				<Controller
					name="designer"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							options={Array.from(new Set([...designerOptions, DesignerIsNotHereText]))}
							onChange={(_event, value) => {
								if (value !== DesignerIsNotHereText) {
									setIsCustomDesigner(false);
									setValue("customDesigner", undefined);
									addDesignerNumberToOrder(value?.designerNumber ?? null);
								}
								onChange(value);
							}}
							getOptionLabel={(option: DesignerViewModel | string) => {
								if (typeof option === "string") {
									return option;
								} else {
									return `${option.firstName ? option.firstName + " " : ""}${option.lastName ? option.lastName + " " : ""}${option.firstName || option.lastName ? "- " : ""}${option.designerNumber}`;
								}
							}}
							filterOptions={filterOptions}
							value={isCustomDesigner ? DesignerIsNotHereText : value}
							isError={!!error}
							errorText={error?.message}
							label={`${NewOrderDesignerNumberText} ${designer === DesignerIsNotHereText ? RequiredText : OptionalText}`}
							dataTestId="shipping-order-details-designer"
							isLoading={isLoadingDesigners}
						/>
					)}
				/>
				{(designer === DesignerIsNotHereText || isCustomDesigner) && (
					<div>
						<TextField
							{...getFormFieldProps({
								name: "customDesigner",
								...formFieldData
							})}
							label={NewOrderEnterDesignerNumberText}
							data-testid="shipping-order-details-customDesigner"
							inputProps={{ maxLength: 6 }}
						/>
						{!errors.customDesigner?.message && <FormHelperText>{EnterDesignerHelperText}</FormHelperText>}
					</div>
				)}
			</NewOrdersDesignersWrapper>
			<div>
				<TextField
					{...getFormFieldProps({
						name: "poNumber",
						characterLimit: {
							currentLength: poNumberInput.length,
							limit: PO_NUMBER_MAX_LENGTH
						},
						...formFieldData
					})}
					data-testid="shipping-order-details-poNumber"
					fullWidth
					label={PONumberText}
					size="small"
				/>
				<TextField
					{...getFormFieldProps({
						name: "jobName",
						characterLimit: {
							currentLength: jobNameInput.length,
							limit: JOB_NAME_MAX_LENGTH
						},
						...formFieldData
					})}
					fullWidth
					data-testid="shipping-order-details-jobName"
					label={JobNameOptionalText}
					size="small"
				/>
			</div>
		</ShippingDetailsCard>
	);
};

export default ShippingOrderDetails;
