import { Autocomplete, AutocompleteRenderInputParams, FormHelperText, PopperProps } from "@mui/material";
import { ReactNode, Ref, SyntheticEvent, useMemo } from "react";
import { AutoCompletePopper } from "./Autocomplete.styles";
import HelperText from "components/Form/HelperText/HelperText";
import DefaultInput from "./DefaultRenderInput.tsx";
import { muiVirtualizedProps } from "../../NewOrders/AddLineItem/VirtualizedList.tsx";

interface AutoCompleteProps {
	options: any[];
	onMouseDownCapture?: (e: SyntheticEvent) => void;
	getOptionLabel?: (option: any) => string;
	isLoading?: boolean;
	onChange: (_event: SyntheticEvent, value: any, reason: string) => void;
	onInputChange?: (event: SyntheticEvent, value: any, reason: string) => void;
	defaultValue?: any;
	value?: any;
	isError?: boolean;
	errorText: string | undefined;
	label: string;
	dataTestId: string;
	renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
	required?: boolean;
	disableClearable?: boolean;
	disabled?: boolean;
	PopperComponent?: (props: PopperProps) => ReactNode | null;
	noOptionsText?: string;
	filterOptions?: (options: any[], { inputValue }: { inputValue: string }) => any[];
	autoFocus?: boolean;
	id?: string;
	virtualize?: boolean;
	isOptionEqualToValue?: (option: any, value: any) => boolean;
	inputRef?: Ref<HTMLInputElement | undefined>;
}

const CustomPopper = (props: PopperProps) => {
	return <AutoCompletePopper {...props} />;
};

const AutoComplete = ({
	options,
	onInputChange,
	onMouseDownCapture,
	getOptionLabel = (option: any) => String(option),
	isLoading,
	onChange,
	defaultValue,
	value,
	isError,
	errorText,
	label,
	dataTestId,
	renderInput,
	required,
	disableClearable,
	disabled,
	PopperComponent,
	noOptionsText,
	filterOptions,
	autoFocus,
	id,
	virtualize,
	isOptionEqualToValue = () => true,
	inputRef
}: AutoCompleteProps) => {
	const virtualizedProps = useMemo(() => (virtualize ? muiVirtualizedProps : undefined), [virtualize]);
	const popperProp = useMemo(
		() => (!virtualize ? (PopperComponent ?? CustomPopper) : virtualizedProps?.PopperComponent),
		[PopperComponent, virtualize, virtualizedProps?.PopperComponent]
	);

	return (
		<div>
			<Autocomplete
				{...virtualizedProps}
				onInputChange={onInputChange}
				options={options}
				size="small"
				disabled={disabled}
				onMouseDownCapture={onMouseDownCapture}
				getOptionLabel={getOptionLabel}
				loading={isLoading}
				onChange={onChange}
				id={id}
				noOptionsText={noOptionsText}
				isOptionEqualToValue={isOptionEqualToValue}
				filterOptions={filterOptions}
				defaultValue={defaultValue}
				value={value}
				disableClearable={disableClearable}
				renderInput={(params) =>
					renderInput ? (
						renderInput(params)
					) : (
						<DefaultInput
							params={params}
							isError={isError ?? false}
							label={label}
							dataTestId={dataTestId}
							autoFocus={autoFocus}
							isLoading={isLoading}
							required={required ?? false}
							inputRef={inputRef}
						/>
					)
				}
				PopperComponent={popperProp}
			/>
			{isError && errorText && (
				<FormHelperText error>
					<HelperText
						isError={true}
						text={errorText}
					/>
				</FormHelperText>
			)}
		</div>
	);
};

export default AutoComplete;
