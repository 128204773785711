import { Alert } from "@mui/material";
import styled from "@emotion/styled";

export const DesignerFormAlertWrapper = styled(Alert)<{ isConfirmation?: boolean }>(({ theme, isConfirmation }) => ({
	div: {
		color: theme.project.colors.primaryColors.main + " !important"
	},
	svg: {
		width: 22,
		height: 22
	},
	a: {
		textDecorationColor: theme.project.colors.primaryColors.main,
		span: {
			color: theme.project.colors.primaryColors.main
		},
		svg: {
			width: 16,
			height: 16,
			verticalAlign: "middle"
		}
	},
	"& .MuiAlertTitle-root": {
		margin: "0 !important"
	},
	margin: isConfirmation ? "28px 0" : 0
}));
