import {
	AddLineItemButtonWrapper,
	AddLineItemContainer,
	AddLineItemInputFormControl,
	AddLineItemStandardContainer,
	AddLineItemStandardDimensions
} from "./AddLineItemStyles.ts";
import { Button } from "@mui/material";
import { FormEventHandler, MutableRefObject, SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { lineItemIdSymbol, useGetLineItemsQuery } from "features/api/newOrderApi.ts";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SkuAndDescription, schema } from "./schema.ts";
import {
	AddLineAddAnotherItemButton,
	AddLineAddAnotherTextOne,
	AddLineCancelButton,
	AddLineDepthLabel,
	AddLineEnterText,
	AddLineHeightLabel,
	AddLineItemHeader,
	AddLineItemInvalidProductError,
	AddLineItemProductPlaceholder,
	AddLineItemQuantityPlaceholder,
	AddLineItemRequired,
	AddLineItemStandardDimensionsHeader,
	AddLineSaveButton,
	AddLineWidthLabel,
	ProductSelectFormError,
	RequiredDimensionError,
	SelectRequiredSpecifications
} from "./constants.ts";
import { models } from "types/api/viewModels.ts";
import { calculateIntervals, isDimensionValid } from "components/NewOrders/utils/NewOrderUtils.tsx";
import {
	DimensionContainer,
	RequiredCategoryWrapper,
	RequiredDimensionsValues
} from "../RequiredDimensions/RequiredDimensionsStyles.ts";
import AddModification from "../AddModification/AddModification.tsx";
import { getFormFieldProps } from "utils/form.tsx";
import useAddModification from "components/NewOrders/AddModification/hooks/useAddModification.ts";
import { ProductViewModel } from "data/api/v1/index.ts";
import { useLocation } from "react-router";
import { matchSorter } from "match-sorter";
import { v4 as uuidv4 } from "uuid";

interface AddNewLineItemModalProps {
	addItemVisibility(groupIndex?: number, configIndex?: number): void;
	configuration: models["PendingOrderConfigurationViewModel"];
	handleLineItem: (configurationId: string, newLineItem: models["PendingLineItemViewModel"]) => void;
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
	isAddLineItem?: boolean;
}

const convertSkuAndDescriptionToString = (sku?: string | null, desc?: string | null) =>
	[sku, desc].filter(Boolean).join(" - ");

type LineItemFilterOptions = (items: SkuAndDescription[], state: { inputValue: string }) => SkuAndDescription[];
const filterOptions: LineItemFilterOptions = (items, { inputValue }) =>
	matchSorter(items, inputValue, { keys: [(item) => convertSkuAndDescriptionToString(item.sku, item.description)] });

const AddLineItem = ({
	addItemVisibility,
	configuration,
	handleLineItem,
	selectedEditItem,
	isAddLineItem
}: AddNewLineItemModalProps) => {
	const [quantityValue, setQuantityValue] = useState(1);
	const [searchValue, setSearchValue] = useState<string | null>(null);

	const dimensions = useRef<Record<string, number | null>>({});
	const [submitted, setSubmitted] = useState(new Date().getTime());
	const originalSubmitted = useRef(submitted);
	const [requiredDimensionsValues, setRequiredDimensionsValues] = useState<
		(models["PendingValueViewModel"] | null)[]
	>([]);
	const location = useLocation();
	const isAddOn = location.pathname.includes("add-ons/");

	const param = configuration.globals?.productLine?.code ?? "";
	const { data: newLineItems, isLoading } = useGetLineItemsQuery({ productLineCode: param }, { skip: !param });

	const foundSearch = useMemo(() => {
		return newLineItems?.find((item) =>
			convertSkuAndDescriptionToString(item.sku, item.description).includes(searchValue!)
		);
	}, [newLineItems, searchValue]);

	const { control, handleSubmit, trigger, formState, register, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange"
	});
	const formFieldData = { formState, register, schema };
	const { productSelect } = getValues();

	const {
		control: requiredCategoriesControl,
		getValues: requiredCategoriesGetValues,
		handleSubmit: requiredCategoriesHandleSubmit,
		trigger: triggerRequiredCategories,
		reset: resetRequiredCategoriesForm
	} = useForm({
		mode: "onChange",
		reValidateMode: "onChange"
	});

	const {
		getModificationFormErrors,
		handleSetModificationFormData,
		handleSetModificationFormErrors,
		modificationFormErrors,
		modificationFormValues,
		modifications
	} = useAddModification(foundSearch?.id ?? selectedEditItem?.current?.id, configuration?.globals?.productLine?.id);

	useEffect(() => {
		if (foundSearch?.requiredDimensions?.length !== 0) {
			foundSearch?.requiredDimensions?.forEach((dimension) => {
				setRequiredDimensionsValues((prev) => {
					const dimensionValues = [...prev];
					dimensionValues.push(null);
					return dimensionValues;
				});
			});
		} else {
			setRequiredDimensionsValues([]);
		}
	}, [foundSearch?.requiredDimensions]);

	const inputRef = useRef<HTMLInputElement | undefined>(undefined);

	const handleAddAnother = () => {
		Promise.all([trigger(), triggerRequiredCategories()]).then((validity) => {
			const { errors, isValid } = getModificationFormErrors(modificationFormValues);

			handleSetModificationFormErrors(errors);
			validity.every(Boolean) && isValid && handleSaveAction(true, requiredCategoriesGetValues());
			inputRef?.current?.focus({ preventScroll: true });
			inputRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
		});
	};

	useEffect(() => {
		const handleQuickKeys = (event: any) => {
			if (event.key === "\\") {
				event.preventDefault();
				handleAddAnother();
			}
		};
		document.addEventListener("keydown", handleQuickKeys);

		return () => {
			document.removeEventListener("keydown", handleQuickKeys);
		};
	});

	const handleChangeProduct = (_event: SyntheticEvent, values: SkuAndDescription | null) => {
		setSearchValue(convertSkuAndDescriptionToString(values?.sku, values?.description) ?? null);
		handleSetModificationFormData(new Map());
		handleSetModificationFormErrors(new Map());
		setRequiredDimensionsValues([]);
		setSubmitted(originalSubmitted.current);
		resetRequiredCategoriesForm();
	};

	const quantityValues = Array.from({ length: 99 }, (_, i) => i + 1);
	const quantity = quantityValues.join().split(",");

	const newLineItemMap: SkuAndDescription[] | undefined = useMemo(() => {
		if (!isLoading) {
			return newLineItems?.map((item) => {
				return { sku: item.sku, description: item.description };
			});
		}
	}, [isLoading, newLineItems]);

	const sortSkuAndDescription = (items: SkuAndDescription[] | undefined): SkuAndDescription[] | undefined => {
		return items?.sort((a, b) => {
			const aKey = a.sku?.trim().toLowerCase() ?? a.description?.trim().toLowerCase() ?? "";
			const bKey = b.sku?.trim().toLowerCase() ?? b.description?.trim().toLowerCase() ?? "";
			return aKey.localeCompare(bKey);
		});
	};

	const sortedOptions = useMemo(() => sortSkuAndDescription(newLineItemMap), [newLineItemMap]);

	const handleMouseDownCapture = (e: SyntheticEvent) => {
		e.stopPropagation();
	};

	const handleQuantityChange = (_event: SyntheticEvent, value: number) => {
		setQuantityValue(value);
	};

	const handleDimensionChange = (
		id: string | null | undefined,
		value: number,
		description: string | null | undefined,
		increment: number | undefined,
		maxValue: number | undefined,
		minValue: number | undefined,
		index: number
	) => {
		const requiredDimensions: models["RequiredDimensionsViewModel"] & models["PendingValueViewModel"] = {
			id: id,
			description: description,
			value: value,
			increment: increment,
			maxValue: maxValue,
			minValue: minValue
		};

		setRequiredDimensionsValues((prev) =>
			prev.map((dimension, prevIndex) => {
				if (index === prevIndex) return requiredDimensions;
				return dimension;
			})
		);
	};

	const handleSaveAction = (
		addAnother = false,
		requiredCategoryValues?: { [key: string]: models["RequiredCategoryValueViewModel"] }
	) => {
		setSubmitted(new Date().getTime());
		const { errors, isValid } = getModificationFormErrors(modificationFormValues);

		handleSetModificationFormErrors(errors);

		const modifications: models["PendingModificationViewModel"][] = Array.from(modificationFormValues.values()).map(
			(mod) => ({
				id: mod?.id,
				sku: mod?.sku,
				description: mod?.description,
				lineItemNumber: "",
				itemKey: mod?.sku,
				values: mod?.values
			})
		);

		const { productSelect } = getValues();
		if (
			searchValue !== null &&
			(requiredDimensionsValues.length ?? 0) === (foundSearch?.requiredDimensions?.length ?? 0) &&
			requiredDimensionsValues?.every((dimension) => dimension !== null) &&
			isValid
		) {
			const requiredValues = Object.values(requiredCategoryValues ?? {});
			const newLineItem: models["PendingLineItemViewModel"] = {
				...foundSearch,
				[lineItemIdSymbol]: uuidv4(),
				description: productSelect?.description,
				lineItemNumber: "1",
				sku: productSelect?.sku,
				modifications: modifications,
				quantityOrdered: quantityValue,
				requiredDimensions: requiredDimensionsValues.filter((dimension) => dimension !== null),
				requiredCategories: requiredValues
			};

			handleLineItem(configuration?.configurationId ?? "", newLineItem);
			setSearchValue(null);
			setQuantityValue(1);
			reset();

			if (!addAnother) {
				addItemVisibility();
			}
		}
	};

	const formOnSubmit: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		setSubmitted(new Date().getTime());
		const { errors } = getModificationFormErrors(modificationFormValues);

		handleSetModificationFormErrors(errors);
		requiredCategoriesHandleSubmit((requiredCategoryValues) => {
			handleSubmit(() => handleSaveAction(false, requiredCategoryValues))(event);
		})(event);
	};

	const containerRef = useRef<HTMLFormElement | null>(null);

	useEffect(() => {
		if (containerRef.current) {
			containerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, []);

	return (
		<form
			ref={containerRef}
			onSubmit={formOnSubmit}
			noValidate
		>
			<AddLineItemContainer>
				<h6 data-testid="add-line-item-header">{AddLineItemHeader}</h6>
				<div data-testid="add-line-item-required">{AddLineItemRequired}</div>
				<AddLineItemInputFormControl>
					<Controller
						name="productSelect"
						control={control}
						render={({ field: { onChange }, fieldState: { error } }) => (
							<div>
								<AutoComplete
									dataTestId="add-line-item-product-search"
									options={sortedOptions ?? []}
									value={productSelect ?? ""}
									onChange={(event, values: ProductViewModel) => {
										handleChangeProduct(event, {
											sku: values.sku,
											description: values.description
										});
										onChange({ sku: values.sku, description: values.description });
									}}
									getOptionLabel={(value: ProductViewModel) =>
										convertSkuAndDescriptionToString(value.sku, value.description)
									}
									isLoading={isLoading}
									onMouseDownCapture={handleMouseDownCapture}
									isError={Boolean(error)}
									errorText={ProductSelectFormError}
									label={AddLineItemProductPlaceholder}
									virtualize
									required
									disableClearable
									autoFocus
									noOptionsText={AddLineItemInvalidProductError}
									inputRef={inputRef}
									filterOptions={filterOptions}
								/>
							</div>
						)}
					/>

					<AutoComplete
						{...getFormFieldProps({
							name: "productSelectQuantity",
							...formFieldData
						})}
						options={quantity}
						value={quantityValue}
						onChange={handleQuantityChange}
						onMouseDownCapture={handleMouseDownCapture}
						getOptionLabel={(option) => String(option) || ""}
						defaultValue={quantityValue}
						isError={false}
						errorText=""
						label={AddLineItemQuantityPlaceholder}
						dataTestId="add-line-item-quantity"
						required
						disableClearable
					/>
				</AddLineItemInputFormControl>

				{foundSearch && searchValue && (
					<>
						{(isDimensionValid(foundSearch.standardDepth) ||
							isDimensionValid(foundSearch.standardHeight) ||
							isDimensionValid(foundSearch.standardWidth)) && (
							<AddLineItemStandardContainer>
								<div data-testid="add-line-item-standard-dimensions-header">
									{AddLineItemStandardDimensionsHeader}
								</div>
								<AddLineItemStandardDimensions isAddLineItem={isAddLineItem}>
									{isDimensionValid(foundSearch.standardDepth) && (
										<div data-testid="add-line-item-standard-dimensions-depth">
											<span>{AddLineDepthLabel}</span>
											<span>{foundSearch.standardDepth}&quot;</span>
										</div>
									)}

									{isDimensionValid(foundSearch.standardHeight) && (
										<div data-testid="add-line-item-standard-dimensions-height">
											<span>{AddLineHeightLabel}</span>
											<span>{foundSearch.standardHeight}&quot;</span>
										</div>
									)}

									{isDimensionValid(foundSearch.standardWidth) && (
										<div data-testid="add-line-item-standard-dimensions-width">
											<span>{AddLineWidthLabel}</span>
											<span>{foundSearch.standardWidth}&quot;</span>
										</div>
									)}
								</AddLineItemStandardDimensions>
							</AddLineItemStandardContainer>
						)}

						{((foundSearch?.requiredCategories && foundSearch.requiredCategories.length !== 0) ||
							(foundSearch?.requiredDimensions && foundSearch?.requiredDimensions?.length !== 0)) && (
							<h6 data-testid="add-line-item-required-specifications-header">
								{SelectRequiredSpecifications}
							</h6>
						)}

						{foundSearch?.requiredDimensions && foundSearch?.requiredDimensions.length !== 0 && (
							<DimensionContainer>
								{foundSearch?.requiredDimensions?.map((dimension, dimensionIndex) => {
									return (
										dimension && (
											<div key={dimension.id}>
												<AutoComplete
													key={dimension.id}
													options={calculateIntervals(
														dimension.minValue,
														dimension.maxValue,
														dimension.increment
													)}
													onMouseDownCapture={(e: SyntheticEvent) => {
														if (
															e.target instanceof HTMLElement &&
															e.target.nodeName === "INPUT"
														) {
															e.stopPropagation();
														}
													}}
													onChange={(_, value) =>
														handleDimensionChange(
															dimension.id,
															value,
															dimension.description,
															dimension.increment,
															dimension.maxValue,
															dimension.minValue,
															dimensionIndex
														)
													}
													isLoading={false}
													label={
														dimension.description ? "Select " + dimension.description : ""
													}
													dataTestId="add-line-item-dimensions"
													getOptionLabel={(option) => String(option) + '"'}
													required
													isError={
														originalSubmitted.current !== submitted &&
														!requiredDimensionsValues[dimensionIndex]
													}
													errorText={RequiredDimensionError}
													disableClearable
												/>

												{(submitted || dimensions.current[dimension.id ?? ""]) && (
													<RequiredDimensionsValues data-testid="add-line-item-dimension-mix-max">
														{`Min: ${dimension.minValue}", Max: ${dimension.maxValue}"`}
													</RequiredDimensionsValues>
												)}
											</div>
										)
									);
								})}
							</DimensionContainer>
						)}
					</>
				)}

				{foundSearch?.requiredCategories && foundSearch.requiredCategories.length > 0 && (
					<RequiredCategoryWrapper>
						{foundSearch.requiredCategories.map((category) => (
							<Controller
								key={category.name}
								name={category.name ?? ""}
								control={requiredCategoriesControl}
								rules={{ required: true }}
								render={({ field: { onChange, value = null }, fieldState: { error } }) => (
									<AutoComplete
										onChange={(_e, value) => onChange(value)}
										value={value}
										options={
											category.values?.map((value) => ({
												...value,
												categoryName: category.name
											})) ?? []
										}
										isLoading={false}
										getOptionLabel={(category) => `${category?.sku} - ${category?.description}`}
										label={`Select ${category.name}`}
										dataTestId="add-line-item-required-category"
										required
										isError={Boolean(error)}
										errorText={RequiredDimensionError}
										disableClearable
									/>
								)}
							/>
						))}
					</RequiredCategoryWrapper>
				)}

				{foundSearch?.allowModifications && (
					<AddModification
						key={searchValue}
						handleClearFieldError={handleSetModificationFormErrors}
						onModificationChange={handleSetModificationFormData}
						selectedEditItem={selectedEditItem}
						errors={modificationFormErrors}
						formValues={modificationFormValues}
						modifications={modifications}
					/>
				)}

				<AddLineItemButtonWrapper isAddLineItem={isAddLineItem}>
					<div>
						<Button
							data-testid="add-line-item-cancel-button"
							variant="text"
							onClick={() => addItemVisibility()}
						>
							{AddLineCancelButton}
						</Button>
					</div>

					<div>
						<Button
							data-testid="add-line-item-save-button"
							variant="outlined"
							type="submit"
							data-id={`${isAddOn ? "add-ons" : "new-order"}-build-order-save-item-button`}
						>
							{AddLineSaveButton}
						</Button>
						<div>{AddLineEnterText}</div>
					</div>

					<div>
						<Button
							data-testid="add-line-item-add-another-button"
							variant="contained"
							onClick={handleAddAnother}
							data-id={`${isAddOn ? "add-ons" : "new-order"}-build-order-add-another-item-button`}
						>
							{AddLineAddAnotherItemButton}
						</Button>
						<div>{AddLineAddAnotherTextOne}</div>
					</div>
				</AddLineItemButtonWrapper>
			</AddLineItemContainer>
		</form>
	);
};

export default AddLineItem;
