import {
	CarrierText,
	ETAText,
	OrderNumberText,
	TrackingNumberText,
	TruckNumberText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { StyledOrderDetailsShipmentHeaderContainer } from "./OrderDetailsShipmentHeader.styles";
import { models } from "types/api/viewModels";

interface HeaderProps {
	orderDetail: models["OrderDetailViewModel"] | null;
	trackingNumber: string[];
	truckNumber: string[];
}

export const OrderDetailsShipmentHeader = ({ orderDetail, trackingNumber, truckNumber }: HeaderProps) => {
	const eta = orderDetail?.eta;

	return (
		<StyledOrderDetailsShipmentHeaderContainer>
			<div>
				<span>{OrderNumberText}</span>
				<span>{orderDetail?.orderNumber ?? UnavailableDataPlaceholderText}</span>
			</div>

			<div>
				<span>{ETAText} </span>
				<span>{eta ?? UnavailableDataPlaceholderText}</span>
			</div>

			<div>
				<span>{CarrierText} </span>
				<span>{orderDetail?.shippingDetails?.carrierName ?? UnavailableDataPlaceholderText}</span>
			</div>

			{trackingNumber.length ? (
				<div>
					<span>{TrackingNumberText}</span>
					<span>{trackingNumber[0] ?? UnavailableDataPlaceholderText}</span>
				</div>
			) : undefined}

			{truckNumber.length ? (
				<div>
					<span>{TruckNumberText}</span>
					<span>{truckNumber ?? UnavailableDataPlaceholderText}</span>
				</div>
			) : undefined}
		</StyledOrderDetailsShipmentHeaderContainer>
	);
};

export default OrderDetailsShipmentHeader;
