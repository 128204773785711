import { type FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store.tsx";
import { useGetUserDetailsQuery, userApiSlice } from "features/api/userApi.ts";
import { orderApiSlice } from "features/api/orderApi.ts";
import { useLocation } from "react-router";
import { impersonationActions } from "features/reducers/settings/impersonation.ts";
import Bar from "components/Impersonation/components/Bar.tsx";
import Modal from "components/Impersonation/components/Modal.tsx";
import { CacheKeys } from "pages/OrderPages/OrderListPage/orderListConstants.ts";
import { write } from "hooks/useSession.ts";
import { coerceOrderResponse } from "pages/OrderPages/utils/orderListUtil.ts";
import { BoxOverlay } from "./impersonation.styles.ts";
import ConfigurationsContext from "context/configurations/ConfigurationsContext.ts";

const Impersonation: FC<PropsWithChildren> = ({ children }) => {
	const location = useLocation();
	const isErrorRoute = useMemo(
		() =>
			location.pathname.includes("/unavailable") ||
			location.pathname.includes("/unauthorized") ||
			location.pathname.includes("/unapproved"),
		[location.pathname]
	);
	const { data: userData } = useGetUserDetailsQuery(undefined, { skip: isErrorRoute });
	const impersonationOriginalUserData = useSelector((state: RootState) => state.impersonation.originalUserData);
	const impersonationUser = useSelector((state: RootState) => state.impersonation.impersonationUser);
	const previousUser = useRef(impersonationUser);
	const [reloadKey, setReloadKey] = useState(new Date().getTime());
	const [isModalOpen, setIsModalOpen] = useState(false);
	const isOriginallyCareUser = useMemo(
		() => userData?.user?.role === "CARE" || impersonationOriginalUserData?.role === "CARE",
		[impersonationOriginalUserData?.role, userData?.user?.role]
	);
	const dispatch = useDispatch();

	const impersonationDisabled = useMemo(
		() => !isOriginallyCareUser || isErrorRoute,
		[isOriginallyCareUser, isErrorRoute]
	);

	const handleSetReloadKey = useCallback(() => {
		write(CacheKeys.Search, "");
		write(CacheKeys.Orders, coerceOrderResponse(undefined));
		dispatch(userApiSlice.util.resetApiState());
		dispatch(orderApiSlice.util.resetApiState());
		setReloadKey(new Date().getTime());
	}, [dispatch]);

	useEffect(() => {
		if (impersonationUser && previousUser.current !== impersonationUser) {
			// Update current user before resetting app state
			previousUser.current = impersonationUser;
			handleSetReloadKey();
		} else {
			// Always update the user on user change. This code would be written in one place if it didn't need to be before resetting the app state above.
			previousUser.current = impersonationUser;
		}
	}, [dispatch, handleSetReloadKey, impersonationUser]);

	const { configurationsData, isBannerVisible } = useContext(ConfigurationsContext);

	useEffect(() => {
		if (userData?.user && !impersonationOriginalUserData) {
			dispatch(impersonationActions.setOriginalUserDetails(userData.user));
		}
	}, [dispatch, impersonationOriginalUserData, userData]);

	useEffect(() => {
		dispatch(impersonationActions.setIsCareUser(isOriginallyCareUser));
	}, [dispatch, isOriginallyCareUser]);

	const handleModalState = (isOpen: boolean) => {
		setIsModalOpen(isOpen);
	};

	return (
		<>
			{(!impersonationDisabled || (isBannerVisible && !isErrorRoute)) && (
				<>
					<Bar
						handleModalState={handleModalState}
						handleSetReloadKey={handleSetReloadKey}
						isModalOpen={isModalOpen}
						maintenanceBanner={configurationsData?.maintenanceBanner}
						impersonationEnabled={!impersonationDisabled}
					/>
					<Modal
						handleModalState={handleModalState}
						handleSetReloadKey={handleSetReloadKey}
						open={isModalOpen}
					/>

					{impersonationUser && <BoxOverlay />}
				</>
			)}

			<div key={reloadKey}>{children}</div>
		</>
	);
};

export default Impersonation;
