import styled from "@emotion/styled";
import { allColors } from "styles/themes/common/colors";
import {
	Accordion,
	alertClasses,
	Dialog,
	dialogTitleClasses,
	formGroupClasses,
	linearProgressClasses,
	paperClasses
} from "@mui/material";
import { StyledDialogTitle } from "components/Dialog/dialog.styles";
import { LoadingSkeletonProps } from "../Common/LineItemGrid/LineItemGridStyles";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo.tsx";

interface NewOrdersProps {
	isNewOrderPage?: boolean;
	isConfirmation?: boolean;
	isCareUser?: boolean;
}

export const DraftNewOrderHeader = styled.h2({
	color: allColors.hex.blue.cwg.cwgBlue900,
	fontSize: "var(--font-size-7)",
	fontWeight: "var(--font-weight-regular)",
	margin: 0
});

export const ImportCSVTextContainer = styled.h6({
	margin: "0 0 8px"
});

export const OrderOrAddOnText = styled.h3(({ theme }) => ({
	color: theme.project.colors.fontColors.main,
	fontSize: 20,
	margin: 0,
	fontWeight: 400
}));

export const UnsetStyles = styled.p({
	marginBottom: "unset"
});

export const UnsetSecondaryCopy = styled(UnsetStyles)<{ isError?: boolean }>(({ theme, isError }) => ({
	...theme.project.typography.body.body1,
	color: theme.project.colors.fontColors.secondary,
	paddingTop: isError ? "16px" : 0
}));

export const GridChildTwoContainer = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "2fr 1fr",
	columnGap: "20px",
	padding: "16px",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
		gridTemplateRows: "1fr auto 1fr",
		button: {
			gridArea: "3",
			width: "100%",
			maxHeight: "37px"
		},
		p: {
			marginBottom: "16px"
		}
	}
}));

export const ManualOrderEntryAddOn = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	padding: "16px",
	p: {
		marginBottom: "36px"
	},
	width: "calc(50% - 24px)",
	[theme.breakpoints.down("md")]: {
		width: "calc(100% - 32px)",
		p: {
			marginBottom: "16px"
		}
	}
}));

export const AddOnEnterOrder = styled.div(({ theme }) => ({
	marginTop: "24px",
	display: "flex",
	gap: "24px",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

export const SupportedFilesTextSize = styled.p(({ theme }) => ({
	...theme.project.typography.caption,
	color: theme.project.colors.fontColors.secondary,
	fontSize: "14px",
	margin: "7px 0",
	b: {
		fontWeight: 500
	}
}));

export const OldExperienceContainer = styled.div(({ theme }) => ({
	span: {
		...theme.project.typography.body.body1,
		color: theme.project.colors.fontColors.secondary
	}
}));

export const NewOrdersHeaderGrid = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "3fr 4fr",
	gap: "20px",
	margin: "0 0 16px 0",
	[theme.breakpoints.down("md")]: {
		display: "flex",
		flexDirection: "column"
	}
}));

export const TruckContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	backgroundColor: "white",
	padding: "16px",
	div: {
		h4: {
			fontSize: 20,
			margin: 0,
			fontWeight: 400,
			marginBottom: 8
		},
		span: {
			...theme.project.typography.body.body1,
			color: theme.project.colors.fontColors.secondary
		}
	},
	button: {
		height: "fit-content",
		alignItems: "center"
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		div: {
			h4: {
				marginBottom: 16
			}
		},
		button: {
			marginTop: 16
		}
	}
}));

export const GridChildOneStyles = styled.div<{ isAddOn?: boolean; isLandingPage?: boolean }>(
	({ theme, isAddOn, isLandingPage }) => ({
		backgroundColor: "white",
		padding: "16px",
		color: theme.project.colors.fontColors.main,
		display: "flex",
		flexDirection: "column",
		width: isAddOn ? "calc(50% - 24px)" : "auto",
		border: isLandingPage ? `1px solid ${theme.project.colors.allColors.hex.grayscale.gray300}` : "none",
		flex: isLandingPage ? 1 : "none",
		span: {
			...theme.project.typography.body.body1
		},
		[theme.breakpoints.down("md")]: {
			width: isAddOn ? "calc(100% - 32px)" : "auto"
		}
	})
);

export const GridChildTwoStyles = styled.div(({ theme }) => ({
	backgroundColor: "white",
	color: theme.project.colors.fontColors.secondary,
	height: "max-content"
}));

export const AccountBillToDialogFormContainer = styled.div({
	maxHeight: "600px"
});

export const AccountInfoContainer = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 16
});

export const HeaderTitleStyles = styled.div({
	h2: {
		paddingBottom: "unset"
	}
});

export const NewOrdersDialogWrapper = styled(Dialog)<{ isCSVModal?: boolean; isCancelModal?: boolean }>(
	({ isCSVModal, isCancelModal }) => ({
		[`.${paperClasses.root}`]: {
			width: "100%",
			...(isCSVModal &&
				isCancelModal && {
					maxHeight: "600px"
				})
		}
	})
);

export const AllFieldsText = styled.div({
	paddingLeft: "24px"
});

/// // CSV UPLOAD MODAL STYLES
export const NewOrderModalDisclaimer = styled.div({
	marginTop: "unset",
	marginBottom: "unset",
	p: {
		margin: 0
	}
});

export const UploadCSVDragDropUploadFile = styled.div({
	svg: {
		fontSize: 40,
		marginTop: 36
	}
});

export const UploadErrorWarning = styled.div({
	marginTop: 6,
	display: "flex",
	color: "#D2393B",
	svg: {
		alignItems: "flex-start"
	}
});

export const UploadErrorWaringText = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start",
	fontWeight: 500,
	marginLeft: 8,
	marginTop: "2px",
	ul: {
		fontWeight: 400,
		margin: 0,
		paddingLeft: 24
	}
}));

export const UploadCSVDragDropButton = styled.div({
	marginTop: 0,
	marginBottom: "1rem",
	span: {
		marginLeft: 6
	}
});

interface CSVUploadStatus {
	isError?: boolean;
	isSuccess?: boolean;
}

export const UploadCSVDragDropContent = styled.div({
	display: "flex",
	flexDirection: "column",
	alignItems: "center"
});

export const UploadModalCSVFileInfoContainer = styled.div<CSVUploadStatus>(({ theme, isError, isSuccess }) => ({
	color: isError ? theme.project.colors.allColors.hex.red.red700 : theme.project.colors.fontColors.main,
	p: {
		margin: 0,
		...theme.project.typography.body.body1
	},
	svg: {
		color: theme.project.colors.fontColors.main
	},
	"& .MuiLinearProgress-bar": {
		backgroundColor:
			isSuccess || !isError
				? theme.project.colors.primaryColors.main
				: theme.project.colors.allColors.hex.red.red700
	}
}));

export const CSVFileUploads = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: "8px",
	margin: "8px 0 0"
});

export const UploadCSVFileInfoProgressBar = styled.div<CSVUploadStatus>({
	display: "grid",
	gridTemplateColumns: "1fr auto",
	gap: "10px",
	alignItems: "center",
	svg: {
		width: "20px",
		height: "20px",
		":hover": {
			cursor: "pointer"
		}
	}
});

export const UploadModalCSVFileStatusText = styled.div<CSVUploadStatus>(({ theme, isSuccess }) => ({
	display: "flex",
	alignItems: "flex-start",
	color: isSuccess ? theme.project.colors.primaryColors.main : theme.project.colors.allColors.hex.red.red700,
	marginRight: "30px",
	svg: {
		color: isSuccess ? theme.project.colors.primaryColors.main : theme.project.colors.allColors.hex.red.red700,
		width: 22,
		height: 22
	}
}));

export const UploadModalCSVFileInfoContainerButton = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-end",
	marginTop: 16,
	[theme.breakpoints.down("lg")]: {
		"& .MuiButtonBase-root": {
			width: "100%"
		}
	}
}));

interface IsBuildOrderPageProps {
	isBuildOrderPage: boolean | undefined;
	isBillToError?: boolean;
}

export const AccountBillToContentWrapper = styled.div<IsBuildOrderPageProps>(
	({ theme, isBuildOrderPage, isBillToError }) =>
		isBuildOrderPage
			? {
					display: "grid",
					gridTemplateColumns: "auto 1fr 1fr min-content min-content",
					backgroundColor: theme.project.colors.backgroundColors.secondary,
					padding: 16,
					alignItems: isBillToError ? "flex-start" : "center",
					margin: "0",
					border: "1px solid rgba(0, 0, 0, 0.12)",
					gap: theme.project.layout.gap.large,
					width: "100%",
					"button:nth-of-type(1)": {
						textWrap: "nowrap",
						alignItems: "center"
					},
					"button:nth-of-type(2)": {
						textWrap: "nowrap",
						gap: 8
					},

					[theme.breakpoints.down("md")]: {
						flexDirection: "column",
						alignItems: "flex-start",
						gridTemplateColumns: "1fr",
						"button:nth-of-type(1)": {
							order: 2
						},
						"button:nth-of-type(2)": {
							order: 1
						}
					}
				}
			: {
					display: "flex",
					flexDirection: "column",
					gap: theme.project.layout.gap.large,
					overflowWrap: "anywhere"
				}
);

export const FileContentsContainer = styled.div<IsBuildOrderPageProps>(({ theme, isBuildOrderPage }) => ({
	display: !isBuildOrderPage ? "grid" : "block",
	gridTemplateColumns: "repeat(6, 1fr)",
	gap: "16px",
	div: {
		[theme.breakpoints.up("md")]: {
			"&:nth-of-type(2)": {
				gridColumn: "span 2"
			},
			"&:nth-of-type(n + 3)": {
				gridColumn: "span 1"
			}
		},
		[theme.breakpoints.down("md")]: {
			gridColumn: "1 / -1",
			display: "flex",
			gap: "8px"
		}
	}
}));

export const AccountBillToLogo = styled(StyledSummaryCardLogo)({
	height: "0.875rem",
	alignSelf: "start"
});

export const FileContentsLogo = styled.div<IsBuildOrderPageProps>(({ isBuildOrderPage, theme }) => ({
	gridColumn: "span 2",
	alignItems: "flex-start",
	alignSelf: "flex-start",
	display: "flex",
	flexDirection: "column",
	height: !isBuildOrderPage ? "50px" : "auto",
	img: {
		height: isBuildOrderPage ? "16px" : "14px"
	}
}));

export const EmptyMobileBlock = styled.div(({ theme }) => ({
	gridColumn: "span 2",
	[theme.breakpoints.down("md")]: {
		"&&": {
			display: "none"
		}
	}
}));

interface SummaryProductLineProps {
	fontVariant: "small" | "large";
}
export const SummaryProductLine = styled.div<SummaryProductLineProps>(({ fontVariant, theme }) => ({
	...(fontVariant === "small" && theme.project.typography.caption),
	...(fontVariant === "large" && theme.project.typography.body.body2),
	color: theme.project.colors.fontColors.main,
	marginTop: 8,
	width: "max-content"
}));

export const StartOverContinueContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	gap: "16px",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column-reverse"
	}
}));

export const BuildNewOrderLineItemsFlexbox = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 16,
	"& .Mui-expanded": {
		margin: 0
	}
});

export const BuildNewOrderLineItemsContainer = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100
}));

export const BuildNewOrderLineItemsHeaderWrapper = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	display: isLoading ? "none" : "flex",
	position: "relative",
	[theme.breakpoints.down("md")]: {
		paddingBottom: 4
	}
}));

export const BuildNewOrderLineItemsHeader = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	alignItems: "center",
	display: isLoading ? "none" : "flex",
	gap: 16,
	justifyContent: "space-between",
	margin: 0,
	width: "100%",
	button: {
		height: 36
	},
	div: {
		"&:first-of-type": {
			...theme.project.typography.heading.h6,
			color: theme.project.colors.fontColors.main,
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between"
		},
		"&:nth-child(2)": {
			...theme.project.typography.overline,
			color: theme.project.colors.fontColors.secondary,
			lineHeight: "200%",
			textTransform: "uppercase",
			gap: 16
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		div: {
			"&:first-child": {
				display: "block",
				marginBottom: 16
			},
			"&:nth-child(2)": {
				alignItems: "center",
				display: "flex",
				flexDirection: "column",
				gap: 16,
				paddingBottom: 16,
				width: "100%",
				button: {
					width: "100%"
				}
			}
		}
	}
}));

export const NewOrderFlowButtons = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	marginTop: 24,
	display: isLoading ? "none" : "flex",
	gap: 16,
	justifyContent: "flex-end",
	button: {
		textTransform: "uppercase",
		"&:nth-of-type(2)": {
			minWidth: 343
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column-reverse",
		gap: 10,
		"button:nth-of-type(2)": {
			minWidth: "auto"
		}
	}
}));

export const UploadModalCSVFileAttachmentViewBytes = styled.span<{ isError?: boolean }>(({ theme, isError }) => ({
	marginLeft: 10,
	color: isError ? theme.project.colors.allColors.hex.red.red700 : theme.project.colors.fontColors.secondary
}));

export const AccountBillToDialogTitle = styled(StyledDialogTitle)({
	padding: "24px 24px 0 24px"
});

export const ErrorWrapper = styled.div({
	padding: "0 20px"
});

// New Orders
export const NewOrdersContent = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100,
	"& > div:nth-of-type(2)": {
		display: "flex",
		flexDirection: "column",
		gap: 24
	}
}));

export const NewOrdersSubheaderWrapper = styled.div<LoadingSkeletonProps>(({ theme, isLoading }) => ({
	div: {
		":first-of-type": {
			display: isLoading ? "none" : "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
			padding: "16px 0",
			h1: {
				color: theme.project.colors.primaryColors.main,
				fontSize: "var(--font-size-7)",
				margin: 0,
				fontWeight: 400,
				flexShrink: 0,
				marginRight: "16px"
			},
			div: {
				display: "flex",
				gap: 16,
				padding: 0,
				button: {
					flexShrink: 0
				},
				div: {
					flexGrow: 1,
					width: "341px",
					div: {
						width: "100%"
					}
				}
			},
			"& .MuiAutocomplete-endAdornment": {
				flexDirection: "row",
				justifyContent: "flex-end",
				gap: "0"
			},
			[theme.breakpoints.down("md")]: {
				div: {
					width: "100%",
					flexDirection: "column",
					alignItems: "flex-start",
					div: {
						width: "100%"
					}
				},
				flexDirection: "column",
				gap: 16,
				alignItems: "flex-start"
			}
		}
	}
}));

export const NewOrdersButtonWrapper = styled.div(({ theme }) => ({
	display: "flex",
	gap: 16,
	justifyContent: "flex-end",
	"button:nth-of-type(1)": {
		textTransform: "uppercase"
	},
	"button:nth-of-type(2)": {
		width: 345
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		"button:nth-of-type(1)": {
			order: 2
		},
		"button:nth-of-type(2)": {
			width: "100%"
		}
	}
}));

export const CardWrapper = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	border: theme.project.borders.presets.outline,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	padding: 16
}));

export const ShippingDetailsCard = styled(CardWrapper)(({ theme }) => ({
	"& > div:nth-of-type(1)": {
		...theme.project.typography.subtitle.subtitle1,
		color: theme.project.colors.fontColors.secondary
	},
	"& > div:last-of-type": {
		display: "flex",
		gap: 16,
		[theme.breakpoints.down("md")]: {
			flexDirection: "column"
		}
	}
}));

export const RequiredFieldsTextWrapper = styled.div(({ theme }) => ({
	...theme.project.typography.caption,
	color: theme.project.colors.fontColors.secondary
}));

export const NewOrdersDesignersWrapper = styled.div<{ isCustomDesigner?: boolean }>(({ theme, isCustomDesigner }) => ({
	display: "flex",
	gap: 16,
	flexDirection: "column",
	input: {
		padding: "8.5px 14px"
	},
	"& .MuiAutocomplete-endAdornment": {
		position: "absolute",
		display: "flex",
		justifyContent: "flex-end"
	},
	div: {
		width: "100%"
	},
	"div:nth-of-type(2)": {
		"& label:not(.MuiInputLabel-shrink)": {
			marginTop: "-8px"
		}
	},
	[theme.breakpoints.up("md")]: {
		flexDirection: "row",
		div: {
			width: isCustomDesigner ? "100%" : "50%",
			div: {
				width: isCustomDesigner ? "100%" : "calc(100% - 2.75px)"
			}
		}
	}
}));

export const NewOrdersLayoutContainer = styled.div(({ theme }) => ({
	margin: "0 0 20px 248px",
	[theme.breakpoints.down("lg")]: {
		margin: "unset"
	}
}));

const HEADER_HEIGHT = 56;
const EMULATION_BAR_HEIGHT = 54;
export const NewOrdersHeaderContainer = styled.div<NewOrdersProps>(({ theme, isCareUser, isConfirmation }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	margin: "0px 0px 8px 0px",
	padding: isConfirmation ? "24px" : "20px",
	position: "sticky",
	top: isCareUser ? EMULATION_BAR_HEIGHT : 0,
	zIndex: "98",
	h1: {
		color: theme.project.colors.fontColors.main,
		fontSize: 34,
		margin: 0
	},
	[theme.breakpoints.down("lg")]: {
		top: isCareUser ? HEADER_HEIGHT + EMULATION_BAR_HEIGHT : HEADER_HEIGHT, // height of top nav menu
		width: "calc(100% - 40px)" // 100v%- header-paddings (under lg mobile view, top nav is present & does not effect width of header)
	},
	[theme.breakpoints.down("md")]: {
		position: "unset",
		top: "unset",
		width: "unset"
	}
}));

export const AddOnsHeaderContainer = styled(NewOrdersHeaderContainer)(({ theme }) => ({
	position: "relative",
	top: 0,
	width: "calc(100% - 40px)",
	[theme.breakpoints.down("lg")]: {
		top: 0
	}
}));

export const AddOnsLandingPageEnterOrderContainer = styled.div({
	padding: "0 20px"
});

export const NewOrdersHeaderHead = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	h5: {
		margin: 0
	}
});

export const NewOrdersSummaryDetails = styled.div<NewOrdersProps>(({ isNewOrderPage, isConfirmation, theme }) => ({
	...(isConfirmation && {
		display: "flex",
		justifyContent: "space-between",
		div: {
			"div:first-of-type": {
				...theme.project.typography.subtitle.subtitle2,
				color: theme.project.colors.fontColors.main
			}
		},
		fontSize: 14,
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			gap: "8px",
			div: {
				...theme.project.typography.body.body2,
				display: "flex",
				gap: "12px"
			}
		}
	}),
	alignItems: "flex-start",
	backgroundColor: isNewOrderPage || isConfirmation ? "none" : theme.palette.grey[100],
	padding: isNewOrderPage || isConfirmation ? 0 : 16,
	[`${NewOrderDetailsGrid}:nth-of-type(1)`]: {
		padding: "4px 0px",
		div: {
			...theme.project.typography.subtitle.subtitle2,
			color: theme.project.colors.fontColors.main
		}
	},
	[`${NewOrderDetailsGrid}:nth-of-type(n + 1)`]: {
		...theme.project.typography.body.body2,
		color: theme.project.colors.fontColors.secondary
	}
}));

export const NewOrdersSummaryDetailsMobile = styled(NewOrdersSummaryDetails)(({ theme }) => ({
	"div:nth-of-type(n + 3):has(> div > div)": {
		flexDirection: "column",
		gap: 8,
		div: {
			"div:last-of-type": {
				...theme.project.typography.body.body2,
				color: theme.project.colors.fontColors.secondary
			}
		}
	},
	[theme.breakpoints.up("md")]: {
		display: "none"
	}
}));

export const NewOrdersSummaryDetailsDesktop = styled(NewOrdersSummaryDetails)(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		display: "none"
	}
}));

export const NewOrderDetailsGrid = styled.div<NewOrdersProps>(({ isNewOrderPage, isConfirmation, theme }) => ({
	gap: 16,
	textTransform: "none",
	display: "grid",
	gridTemplateColumns: "repeat(5, 1fr)",
	[theme.breakpoints.down("md")]: {
		"& > div:nth-of-type(n + 2)": {
			display: "none"
		}
	}
}));

export const NewOrdersSummaryCounts = styled.div(({ theme }) => ({
	display: "flex",
	gap: 16,
	justifyContent: "space-between",
	marginTop: 16,
	"& > div": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		"& > div:nth-of-type(1)": {
			...theme.project.typography.subtitle.subtitle2,
			color: theme.project.colors.fontColors.main
		},
		"& > div:nth-of-type(2)": {
			...theme.project.typography.body.body2,
			color: theme.project.colors.fontColors.secondary
		}
	},
	[theme.breakpoints.up("md")]: {
		display: "none"
	}
}));

export const OrderTypeMobile = styled.div(({ theme }) => ({
	marginTop: 16,
	"div:nth-of-type(1)": {
		...theme.project.typography.subtitle.subtitle2,
		color: theme.project.colors.fontColors.main
	},
	"div:nth-of-type(2)": {
		...theme.project.typography.body.body2,
		color: theme.project.colors.fontColors.secondary
	},
	[theme.breakpoints.up("md")]: {
		display: "none"
	}
}));

export const NewOrdersContentContainer = styled.div(({ theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.grayscale.gray100,
	padding: "0 20px",
	display: "flex",
	flexDirection: "column",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		marginTop: 0
	}
}));

export const NewOrdersDesktopBanner = styled.img(({ theme }) => ({
	marginTop: "8px",
	[theme.breakpoints.down("md")]: {
		display: "none"
	},
	[theme.breakpoints.down("lg")]: {
		marginTop: "16px"
	}
}));

export const NewOrdersTabletBanner = styled.img(({ theme }) => ({
	marginTop: "8px",
	[theme.breakpoints.up("md")]: {
		display: "none"
	},
	[theme.breakpoints.down("sm")]: {
		display: "none"
	}
}));

export const NewOrdersMobileBanner = styled.img(({ theme }) => ({
	marginTop: "8px",
	[theme.breakpoints.up("sm")]: {
		display: "none"
	}
}));

export const AddOnsMobileBanner = styled.img(({ theme }) => ({
	width: "100%",
	margin: "16px 0",
	[theme.breakpoints.up("sm")]: {
		display: "none"
	}
}));

export const AddOnsTabletBanner = styled.img(({ theme }) => ({
	width: "100%",
	margin: "16px 0",
	[theme.breakpoints.up("md")]: {
		display: "none"
	},
	[theme.breakpoints.down("sm")]: {
		display: "none"
	}
}));

export const AddOnsDesktopBanner = styled.img(({ theme }) => ({
	width: "100%",
	margin: "16px 0",
	[theme.breakpoints.down("md")]: {
		display: "none"
	}
}));

export const NewOrdersHeaderRow = styled.div(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	justifyContent: "space-between",
	[theme.breakpoints.down("md")]: {
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "column",
		gap: 16
	}
}));

export const NewOrderDetailsWrapper = styled.div<NewOrdersProps>(({ isConfirmation, theme }) => ({
	border: isConfirmation ? "1px solid" : "none",
	borderColor: isConfirmation ? theme.project.colors.allColors.hex.grayscale.gray300 : "none",
	padding: isConfirmation ? 16 : 0
}));

export const StaticComponentBackground = styled.div(({ theme }) => ({
	padding: 16,
	marginBottom: 16,
	backgroundColor: theme.project.colors.backgroundColors.main
}));

export const NewOrderConfirmationOrderListHeader = styled.div(({ theme }) => ({
	padding: "16px 16px 0",
	[theme.breakpoints.down("lg")]: {
		paddingBottom: 10
	},
	backgroundColor: theme.project.colors.backgroundColors.main,
	color: theme.project.colors.fontColors.secondary,
	"& > div:nth-of-type(1)": {
		...theme.project.typography.overline,
		marginBottom: 8
	}
}));

export const NewOrderNumberHeader = styled.div(({ theme }) => ({
	display: "inline-block",
	marginBottom: 16,
	color: theme.project.colors.fontColors.main,
	"& > div": {
		display: "flex",
		alignItems: "center",
		"& > div:nth-of-type(1)": {
			...theme.project.typography.heading.h6
		},
		"& > div:nth-of-type(2)": {
			...theme.project.typography.heading.h6,
			fontWeight: 400,
			textTransform: "uppercase"
		}
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		marginBottom: 0
	}
}));

export const AddOnOriginalOrderNumber = styled.span(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-end",
	fontSize: 14,
	textTransform: "uppercase",

	[theme.breakpoints.down("md")]: {
		...theme.project.typography.overline,
		marginBottom: 16
	}
}));

export const OrderTypeContainerMobile = styled.div(({ theme }) => ({
	marginTop: 16,
	[theme.breakpoints.up("md")]: {
		display: "none"
	}
}));

export const StaticComponentTitle = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle1,
	color: theme.project.colors.fontColors.secondary,
	marginBottom: 8,
	span: {
		float: "right"
	}
}));

export const StaticComponentGridDividerWrapper = styled.div(({ theme }) => ({
	marginTop: 16,
	marginBottom: 16
}));

export const StaticComponentGrid = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(2, 1fr)",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr"
	}
}));

export const Label = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	color: theme.project.colors.fontColors.secondary
}));

export const InfoText = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	color: theme.project.colors.fontColors.main
}));

export const OriginalOrderDetailsAccordion = styled(Accordion)(({ theme }) => ({
	"& .MuiAccordionSummary-content": {
		...theme.project.typography.overline,
		margin: "9px 0"
	},
	boxShadow: "none",
	position: "initial",
	border: "1px solid " + theme.palette.grey[300],
	marginTop: "0px !important",
	"& .MuiAccordionDetails-root": {
		padding: 0,
		div: {
			margin: 0,
			paddingTop: 0
		}
	},
	"& .Mui-expanded": {
		margin: "0px !important"
	}
}));

export const ConfigurationFileName = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	color: theme.project.colors.fontColors.secondary
}));

// CANCEL MODAL STYLES
export const CancelModalWrapper = styled.div<{ isSuccess?: boolean; isLoading?: boolean }>(
	({ isSuccess, isLoading }) => ({
		"> div": {
			display: "flex",
			gap: 16,
			margin: "0 24px"
		},
		[`& .${dialogTitleClasses.root}`]: {
			svg: {
				display: isLoading || isSuccess ? "none" : "block"
			}
		}
	})
);

export const CancellationAcknowledgementWrapper = styled.div<{ hasError?: boolean; isCancelling: boolean }>(
	({ theme, hasError, isCancelling }) => ({
		display: "flex",
		flexDirection: "column",
		[`& .${alertClasses.root}`]: {
			...theme.project.typography.body.body2,
			display: isCancelling ? "none" : "flex",
			color: theme.project.colors.semanticColors.error,
			width: "auto",
			flexDirection: "column",
			gap: 16,
			backgroundColor: theme.project.colors.backgroundColors.error,
			padding: 16,
			[`& .${alertClasses.message}`]: {
				"> div": {
					display: "flex",
					flexDirection: "column"
				},
				display: "flex",
				flexDirection: "column",
				gap: 16,
				padding: 0
			}
		},
		[`& .${formGroupClasses.root}`]: {
			display: isCancelling ? "none" : "flex",
			border: hasError
				? `1px solid ${theme.project.colors.semanticColors.error}`
				: theme.project.borders.presets.outline,
			borderWidth: 1,
			color: hasError ? theme.project.colors.semanticColors.error : theme.project.colors.fontColors.secondary,
			padding: 8,
			borderRadius: 4
		},
		":nth-child(3)": {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			"> :nth-child(2)": {
				backgroundColor: theme.project.colors.semanticColors.error
			}
		}
	})
);

export const ProcessingCancellationContainer = styled.div<{ hasError?: boolean; isCancelling: boolean }>(
	({ theme, hasError, isCancelling }) => ({
		display: "flex",
		flexDirection: "column",
		paddingBottom: 24,
		div: {
			color: hasError ? theme.project.colors.semanticColors.error : theme.project.colors.fontColors.secondary
		},
		h1: {
			...theme.project.typography.heading.h6,
			display: isCancelling ? "flex" : "none",
			justifyContent: "center"
		}
	})
);

export const ProgressBarWrapper = styled.div<{ hasError?: boolean; isCancelling: boolean }>(
	({ theme, hasError, isCancelling }) => ({
		[`& .${linearProgressClasses.root}`]: {
			backgroundColor: !hasError
				? theme.project.colors.primaryColors.main
				: theme.project.colors.allColors.hex.red.red100
		},
		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: !hasError
				? theme.project.colors.primaryColors.main
				: theme.project.colors.allColors.hex.red.red700
		},
		[`& .${linearProgressClasses.indeterminate}`]: {
			backgroundColor: !hasError
				? theme.project.colors.backgroundColors.secondary
				: theme.project.colors.allColors.hex.red.red700
		},
		div: {
			display: isCancelling ? "flex" : "none",
			alignItems: "center",
			"> :nth-child(1)": {
				marginRight: "8px",
				color: hasError
					? theme.project.colors.semanticColors.error
					: theme.project.colors.allColors.hex.blue.cwg.cwgBlue900
			},
			"> :nth-child(2)": {
				...theme.project.typography.subtitle.subtitle2,
				color: hasError
					? theme.project.colors.semanticColors.error
					: theme.project.colors.allColors.hex.blue.cwg.cwgBlue900
			}
		}
	})
);

export const CancellationButtonWrapper = styled.div(({ theme }) => ({
	flexDirection: "row",
	justifyContent: "space-between",
	padding: "0 0 24px 0",
	"> :nth-child(2)": {
		backgroundColor: theme.project.colors.semanticColors.error
	}
}));
