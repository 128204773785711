import { IconButton } from "@mui/material";
import ImportCSV from "../ImportCSV/ImportCSV";
import ManualOrderEntry from "../ManualOrderEntry/ManualOrderEntry";
import CloseIcon from "@mui/icons-material/Close";
import {
	BuildOrderOptionsDialogContent,
	BuildOrderOptionsDialogHeader,
	BuildOrderOptionsDialogWrapper
} from "./BuildOrderOptionsDialog.styles";
import { BuildOrderOptionsTitle } from "../constants";

interface BuildOrderOptionsDialogProps {
	onClose: () => void;
}

const BuildOrderOptionsDialog = ({ onClose }: BuildOrderOptionsDialogProps) => {
	return (
		<BuildOrderOptionsDialogWrapper>
			<BuildOrderOptionsDialogHeader>
				<h6>{BuildOrderOptionsTitle}</h6>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</BuildOrderOptionsDialogHeader>
			<BuildOrderOptionsDialogContent>
				<ImportCSV isLandingPage />
				<ManualOrderEntry />
			</BuildOrderOptionsDialogContent>
		</BuildOrderOptionsDialogWrapper>
	);
};

export default BuildOrderOptionsDialog;
