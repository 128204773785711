import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { msalInstance } from "auth/authConfig";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router";
import { Provider } from "react-redux";
import { store } from "stores/application.store";
import { themeMap } from "styles/themes/baseTheme";
import reportWebVitals from "./reportWebVitals";
import useInitWindowSettings from "./hooks/useInitWindowSettings";
import ServiceUnavailablePage from "./pages/ServiceUnavailable/ServiceUnavailablePage";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import LandingPage from "./pages/LandingPage/LandingPage";
import OrderListPage from "./pages/OrderPages/OrderListPage/OrderListPage";
import OrderDetailPage from "./pages/OrderPages/OrderDetailPage/OrderDetailPage";
import ReplacementsPage from "./pages/Replacements/ReplacementsPage";
import ReplacementReasonPage from "./pages/Replacements/ReplacementReasonPage";
import ReplacementsReviewPage from "./pages/Replacements/ReplacementsReviewPage";
import ReplacementConfirmationPage from "./pages/Replacements/ReplacementConfirmationPage";
import StatusUnapproved from "./pages/Errors/StatusUnapproved";
import ReplacementResubmit from "./pages/Errors/ReplacementResubmit";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import StatusUnapprovedAccount from "./pages/Errors/StatusUnapprovedAccount";
import OrderNotFound from "./pages/Errors/OrderNotFound";
import NewOrderPage from "./pages/OrderPages/NewOrderPage/NewOrderPage";
import BuildOrderPage from "./pages/OrderPages/BuildOrderPage/BuildOrderPage";
import ViewPriceEstimatesPage from "pages/OrderPages/ViewPriceEstimatesPage/ViewPriceEstimatesPage";
import NewOrderShippingDetailsPage from "pages/OrderPages/NewOrderShippingDetailsPage/NewOrderShippingDetailsPage";
import NewOrderFormProvider from "pages/OrderPages/NewOrderShippingDetailsPage/contexts/Form/NewOrderFormProvider";
import ReviewOrderPage from "./pages/OrderPages/ReviewOrderPage/ReviewOrderPage";
import NewOrderConfirmationPage from "./pages/OrderPages/NewOrderConfirmation/NewOrderConfirmation";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import AddOnLandingPage from "pages/OrderPages/AddOnLandingPage/AddOnLandingPage.tsx";
import SidebarProvider from "context/SidebarProvider.tsx";
import Impersonation from "components/Impersonation/Impersonation.tsx";
import { isDevEnv, isProductionEnv } from "constants/environment.ts";
import { flags } from "./constants/featureFlags.ts";
import ConfigurationProvider from "context/configurations/ConfigurationProvider.tsx";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const luckyOrangeFeatureFlagProd = `${flags.env}` === "prod";
const luckyOrangeFeatureFlagLowerEnvs = `${flags.env}` !== "prod";

const Application = ({ children }: any) => {
	useInitWindowSettings();

	return (
		<MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				{children}
			</MsalAuthenticationTemplate>
		</MsalProvider>
	);
};

export const windowScrollToTop = () => setTimeout(() => window.scrollTo(0, 0), 0);

const ScrollToTop = ({ children }: any) => {
	const location = useLocation();

	useLayoutEffect(() => {
		windowScrollToTop();
	}, [location.pathname]);

	useLayoutEffect(() => {
		window.addEventListener("popstate", windowScrollToTop);
		return () => {
			window.removeEventListener("popstate", windowScrollToTop);
		};
	}, []);

	return <>{children}</>;
};

const loadLuckyOrangeScript = () => {
	if (luckyOrangeFeatureFlagProd) {
		const prodScriptTag = document.createElement("script");
		prodScriptTag.src = "https://tools.luckyorange.com/core/lo.js?site-id=51dd4464";
		prodScriptTag.async = true;
		prodScriptTag.defer = true;

		document.head.appendChild(prodScriptTag);
	} else if (luckyOrangeFeatureFlagLowerEnvs) {
		const lowerEnvsScriptTag = document.createElement("script");
		lowerEnvsScriptTag.src = "https://tools.luckyorange.com/core/lo.js?site-id=1d1dd2b8";
		lowerEnvsScriptTag.async = true;
		lowerEnvsScriptTag.defer = true;

		document.head.appendChild(lowerEnvsScriptTag);
	}
};

if (isProductionEnv) {
	window.onload = loadLuckyOrangeScript;
}

function render() {
	root.render(
		<React.StrictMode>
			<ErrorBoundary>
				<Provider store={store}>
					<Application>
						<SidebarProvider>
							<ThemeProvider theme={themeMap.defaultTheme}>
								<BrowserRouter>
									<ScrollToTop>
										<ConfigurationProvider>
											<Impersonation>
												<Routes>
													<Route
														path="/unavailable"
														element={<ServiceUnavailablePage />}
													/>
													<Route
														path="/unauthorized"
														element={<Unauthorized />}
													/>

													<Route
														path="/"
														element={<LandingPage />}
													>
														<Route
															path="/projects"
															element={<OrderListPage />}
														/>
														<Route
															path="/new-order"
															element={<NewOrderPage />}
														/>
														<Route
															path="/new-order/build-order/:draftId?"
															element={<BuildOrderPage />}
														/>
														<Route
															path="/new-order/view-price-estimates"
															element={<ViewPriceEstimatesPage />}
														/>
														<Route
															path="/new-order/shipping-details"
															element={
																<NewOrderFormProvider>
																	<NewOrderShippingDetailsPage />
																</NewOrderFormProvider>
															}
														/>
														<Route
															path="/new-order/review-order"
															element={<ReviewOrderPage />}
														/>
														<Route
															path="/new-order/confirmation"
															element={<NewOrderConfirmationPage />}
														/>
														<Route
															path="details/:orderId"
															element={<OrderDetailPage />}
														/>
														<Route
															path="replacements/:orderId"
															element={<ReplacementsPage />}
														/>
														<Route
															path="replacements/reason/:orderId/"
															element={<ReplacementReasonPage />}
														/>
														<Route
															path="replacements/review/:orderId"
															element={<ReplacementsReviewPage />}
														/>
														<Route
															path="replacements/confirmation/:orderId"
															element={<ReplacementConfirmationPage />}
														/>
														<Route
															path="/add-ons/:orderId"
															element={<AddOnLandingPage />}
														/>
														<Route
															path="/add-ons/build-order/:orderId/:draftId?"
															element={<BuildOrderPage isAddOn />}
														/>
														<Route
															path="/add-ons/view-price-estimates/:orderId"
															element={<ViewPriceEstimatesPage isAddOn />}
														/>
														<Route
															path="/add-ons/shipping-details/:orderId"
															element={
																<NewOrderFormProvider>
																	<NewOrderShippingDetailsPage isAddOn />
																</NewOrderFormProvider>
															}
														/>
														<Route
															path="/add-ons/review-order/:orderId"
															element={<ReviewOrderPage isAddOn />}
														/>
														<Route
															path="/add-ons/confirmation/:orderId"
															element={<NewOrderConfirmationPage isAddOn />}
														/>
													</Route>

													<Route
														path="/unapproved/:status"
														element={<StatusUnapproved />}
													/>
													<Route
														path="/unapproved/account"
														element={<StatusUnapprovedAccount />}
													/>
													<Route
														path="/ordernotfound"
														element={<OrderNotFound />}
													/>
													<Route
														path="/replacements/resubmit/:orderId"
														element={<ReplacementResubmit />}
													/>
													<Route
														path="*"
														element={<NotFoundPage />}
													/>
													<Route
														path="replacements/review/:orderId"
														element={<ReplacementsReviewPage />}
													/>
												</Routes>
											</Impersonation>
										</ConfigurationProvider>
									</ScrollToTop>
								</BrowserRouter>
							</ThemeProvider>
						</SidebarProvider>
					</Application>
				</Provider>
			</ErrorBoundary>
		</React.StrictMode>
	);
}

if (isDevEnv && import.meta.env.VITE_APP_MOCKED) {
	import("mocks/server/devServer").then((startDevServer) => {
		startDevServer.default().then(() => render());
	});
} else {
	render();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
