import { useMemo, useState, useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import styles from "../../order-status-page-styles.module.css";
import { OrderList, OrderSearch } from "components";
import useSession from "hooks/useSession";
import { OrderStatus, OrderSummaryViewModel } from "data/api/v1";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import { useLazyFilterQuery } from "features/api/orderApi.ts";
import { useDispatch } from "react-redux";
import useWindowSettings from "hooks/useWindowSettings";
import {
	coerceOrderResponse,
	getFilteredOrders,
	objectToDateRange,
	StandardOrderResponse
} from "../utils/orderListUtil";
import { InitialFilterOptions, InitialSearchDates, CacheKeys, InitialOrderResponse } from "./orderListConstants";
import { cartActions } from "features/reducers/replacementOrder/cart.ts";
import DefaultMessage from "components/Common/DefaultMessage/DefaultMessage";
import { useLocation, useNavigate } from "react-router";
import { defaultMessageForCareUserText, searchBarHelperText } from "constants/text";

const OrderListPage = () => {
	const { data: userDetails } = useGetUserDetailsQuery();
	const { isMobile } = useWindowSettings();
	const location = useLocation();
	const navigate = useNavigate();
	const [orderStatus, setOrderStatus] = useState<number>(200);
	const [currentSearch, setCurrentSearch] = useSession<string>(CacheKeys.Search, "");
	const [searchDates, setSearchDates] = useSession<DateRange>(CacheKeys.Dates, InitialSearchDates, objectToDateRange);
	const [filterOptions, setFilterOptions] = useSession<FilterOpts>(CacheKeys.Filters, InitialFilterOptions);
	const [{ orders, isPartialDataset }, setOrderResponse] = useSession<StandardOrderResponse>(
		CacheKeys.Orders,
		InitialOrderResponse
	);
	const [searchLoading, setSearchLoading] = useState(false);

	const dispatch = useDispatch();

	const [trigger, { isLoading, isUninitialized }] = useLazyFilterQuery();

	const isCareRole = userDetails?.user?.role === "CARE";

	const params = new URLSearchParams(location.search);
	const searchValue = params.get("search");
	useEffect(() => {
		if (searchValue) {
			setCurrentSearch(searchValue);
			// eslint-disable-next-line sonarjs/void-use
			void invokeSearch(searchValue);
			setFilterOptions(() => ({
				accounts: [],
				designers: [],
				brands: [],
				status: [],
				types: [],
				hasStatusTriggered: false
			}));
			params.delete("search");
			navigate(
				{
					search: params.toString()
				},
				{ replace: true }
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search]);

	const invokeSearch = useCallback(
		async (searchQuery?: string) => {
			try {
				setSearchLoading(true);

				// Runs in different microtask to prevent bad state setter errors
				await Promise.resolve();
				const response = await trigger({
					searchDates,
					searchTerm: searchQuery ?? currentSearch
				}).unwrap();

				const formattedResponse = coerceOrderResponse(response);
				setOrderResponse(formattedResponse);
				setSearchLoading(false);
			} catch (err) {
				setSearchLoading(false);
				setOrderStatus((err as any)?.status);
			}
		},
		[currentSearch, searchDates, setOrderResponse, trigger]
	);

	const filteredOrders = useMemo(() => getFilteredOrders(filterOptions, orders), [orders, filterOptions]);

	const showOrders = useMemo(() => {
		if (orders.length > 0) return true;
		return !isUninitialized || !isCareRole;
	}, [isUninitialized, isCareRole, orders]);

	const hasErrors = useMemo(() => {
		return orders?.some((order) => {
			return order.status === OrderStatus.SUBMITTEDWITHERRORS || order.status === OrderStatus.HOLD;
		});
	}, [orders]);

	useEffect(() => {
		dispatch(cartActions.resetCart());
	}, [dispatch]);

	return (
		<>
			<div
				className={styles.orderSearchFilterContainer}
				data-testid="order-list-page-order-search"
			>
				<OrderSearch
					isCareRole={isCareRole}
					currentSearch={currentSearch}
					setCurrentSearch={setCurrentSearch}
					invokeSearch={invokeSearch}
					hasSearchQueryParam={searchValue !== null && searchValue !== undefined}
					isLoading={isLoading}
					isPartialDataset={isPartialDataset && filteredOrders.length >= 1000}
					searchDates={searchDates}
					setSearchDates={setSearchDates}
				/>
			</div>
			{showOrders && (
				<Grid
					container
					data-testid="order-list-page-orders"
					sx={{ paddingTop: "1rem" }}
				>
					<Grid
						item
						xs={12}
					>
						<div className={styles.orderListContainer}>
							<OrderList
								isLoading={isLoading || searchLoading}
								orderStatus={orderStatus}
								filteredOrders={filteredOrders}
								orders={orders}
								searchFilters={filterOptions}
								searchDates={searchDates}
								totalStatuses={orders?.map((obj: OrderSummaryViewModel) => obj.status)}
								currentSearch={currentSearch}
								isMobile={isMobile}
								userDetails={userDetails}
								filterOptions={filterOptions}
								setFilterOptions={setFilterOptions}
								showOrders={showOrders}
								hasErrors={hasErrors}
							/>
						</div>
					</Grid>
				</Grid>
			)}
			{!showOrders && (
				<Grid
					container
					data-testid="care-user-message"
					sx={{ paddingTop: "1rem" }}
				>
					<Grid
						item
						xs={12}
					>
						<div className={styles.orderListContainer}>
							<DefaultMessage
								dataTestId="default-message-for-care-user"
								asset="/assets/CareSearchImage.svg"
								altMessage="Default message for care user"
								title={defaultMessageForCareUserText}
								subtitle={searchBarHelperText}
							/>
						</div>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default OrderListPage;

export interface DateRange {
	fromDate: Date | undefined;
	toDate: Date | undefined;
}

export interface FilterOpts {
	accounts: string[];
	designers: string[];
	brands: string[];
	status: string[];
	types: string[];
	hasStatusTriggered: boolean;
	sortedBy?: string[];
}
