import { ConfirmationThanksContainer } from "./ConfirmationThanks.styles";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { useDispatch, useSelector } from "react-redux";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { RootState } from "stores/application.store";
import useNewOrderDesigners from "hooks/useNewOrderDesigners";
import DesignerFormAlert from "components/NewOrders/DesignerFormAlert/DesignerFormAlert";
import { DesignerConnectionHeaderConfirmation } from "components/NewOrders/constants";

interface ConfirmationThanksProps {
	subheader: string;
	button: string;
	isAddOn?: boolean;
}

const ConfirmationThanks = ({ subheader, button, isAddOn }: ConfirmationThanksProps) => {
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const returnToOrders = () => {
		dispatch(newOrderActions.clearNewOrder());
		navigateTo("/");
	};

	const { findDesignerNumberInDesignerResponse } = useNewOrderDesigners();

	const orderDesignerNumber = useSelector((state: RootState) => state.newOrder.newOrderDetails?.designerNumber);
	const isCustomDesigner = !findDesignerNumberInDesignerResponse(orderDesignerNumber);

	return (
		<ConfirmationThanksContainer>
			<NewOrdersHeader
				includeDetails={false}
				includeStepper={false}
				isConfirmation
				isPDFExport
				isAddOn={isAddOn}
			/>

			<div>
				<div data-testid="new-order-confirmation-thank-you-subheader">{subheader}</div>
				{isCustomDesigner && orderDesignerNumber && (
					<DesignerFormAlert
						header={DesignerConnectionHeaderConfirmation}
						isConfirmation
					/>
				)}
				<Button
					variant="contained"
					onClick={returnToOrders}
					data-testid="new-order-confirmation-details-button"
					data-id={`${isAddOn ? "add-ons" : "new-order"}-return-to-my-orders-button`}
				>
					{button}
				</Button>
			</div>
		</ConfirmationThanksContainer>
	);
};

export default ConfirmationThanks;
