export const ORDER_DETAIL_CTA_PROBLEMS = [
	{
		id: 1,
		statusReason: "DELIVERY_DATE_CHANGED",
		description: "The ETA has been updated to the best available date."
	},
	{
		id: 2,
		statusReason: "MISSING_INFORMATION",
		description:
			"Additional information is needed to process your order and schedule delivery. At this time we cannot honor the requested delivery date due to the missing details. However, we will schedule the best available date when all of the required information is received. Please email dealercare@cabinetworksgroup.com with your Account Number, PO Number and Order Number for support on this order."
	},
	{
		id: 3,
		statusReason: "INCORRECT_PRICING",
		description:
			"There is a pricing discrepancy on this order. Please review and contact dealercare@cabinetworksgroup.com. We will schedule the best date available for your delivery when the hold is removed."
	},
	{
		id: 4,
		statusReason: "PROCESSING_HOLD",
		description:
			"This order is placed on hold while Cabinetworks Group reviews the order. We will schedule the best date available for your delivery when the hold is removed."
	},
	{
		id: 5,
		statusReason: "CUSTOMER_HOLD",
		description:
			"This order is placed on hold at the customers request. Cabinetworks Group will hold this order up to 90 days. Cabinetworks Group reserves the right to cancel orders on hold longer than 90 days."
	},
	{
		id: 6,
		statusReason: "PRODUCT_VALIDATION_HOLD",
		description:
			"An item on the order doesn't match our system. Please review the items on this order and contact dealercare@cabinetworksgroup.com. We will schedule the best date available for your delivery when the hold is removed."
	},
	{
		id: 7,
		statusReason: "PARTIALLY_SHIPPED",
		description: "Some items in your order have shipped separately. See below for tracking information. "
	}
];
