import { Divider } from "@mui/material";
import { CloseSubMenuButton, NavigationPlaceNewOrderButton } from "./appNavigation.styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { NavigationOrderPlaceNewOrder } from "constants/text";
import {
	CompressedMenuNavigationContainer,
	CompressedMenuPersonIcon,
	CompressedMenuSection,
	CompressedNavigationContainer,
	CompressedNavigationIcon,
	CompressedNavigationMenuButton,
	NavigationSubMenuItemButtonContainer,
	NavigationSubMenuItemText,
	NavigationSubMenuLinks,
	SubMenuListItemButton
} from "./compressedNavigation.styles";
import { useParams } from "react-router";
import { models } from "types/api/viewModels.ts";
import { useContext } from "react";
import ConfigurationsContext from "context/configurations/ConfigurationsContext.ts";

interface Props {
	handleClickNavigationOption: (navItem: models["UserNavigationItemViewModel"], index: number) => void;
	handleCloseSubMenu: () => void;
	mainNavigationItems: Array<models["UserNavigationItemViewModel"]>;
	subNavigation: models["UserNavigationItemViewModel"] | null;
	selectedIndex: number;
}
const CompressedNavigation = ({
	handleClickNavigationOption,
	handleCloseSubMenu,
	mainNavigationItems,
	subNavigation,
	selectedIndex
}: Props) => {
	const params = useParams();
	const { configurationsData } = useContext(ConfigurationsContext);

	const renderCompressedNavigationMenuIcon = (navItem: models["UserNavigationItemViewModel"], index: number) => {
		return (
			<CompressedNavigationMenuButton
				disableRipple
				data-id={navItem.id}
				id={navItem?.id ?? ""}
				data-testid={`${navItem.id}-link-compressed`}
				key={navItem.id}
				aria-describedby={`${navItem.id}-link-compressed`}
				onClick={() => handleClickNavigationOption(navItem, index)}
			>
				{(navItem.title === "Orders" && selectedIndex === 1) ||
				(navItem.title === "Products" && selectedIndex === 3) ? (
					<img
						alt={navItem.title}
						data-testid="main-menu-item-selected"
						src={"/assets/navigation_logos/" + navItem.selectedIcon}
					/>
				) : (
					<CompressedNavigationIcon src={"/assets/navigation_logos/" + navItem.icon} />
				)}
			</CompressedNavigationMenuButton>
		);
	};

	const renderCompressedSubNavigation = (navItem: models["UserNavigationItemViewModel"], index: number) => {
		const selectedTabToHighlight = navItem.link === "/projects" || navItem.link === `/details/${params.orderId}`;

		if (
			navItem.title?.toUpperCase() === NavigationOrderPlaceNewOrder &&
			configurationsData?.featureFlags?.createOrdersEnabled
		) {
			return (
				<NavigationPlaceNewOrderButton
					variant="contained"
					data-id="navigation-place-new-order-button"
					data-testid="navigation-place-new-order-button"
					fullWidth
					onClick={() => handleClickNavigationOption(navItem, index)}
				>
					{navItem.title}
				</NavigationPlaceNewOrderButton>
			);
		} else if (navItem.title?.toUpperCase() !== NavigationOrderPlaceNewOrder) {
			return (
				<NavigationSubMenuItemButtonContainer>
					<SubMenuListItemButton
						disableRipple
						data-id={navItem.id}
						id={navItem.id ?? ""}
						key={navItem.id}
						aria-describedby={`${navItem.id}-link-submenu`}
						data-testid={`navigation-subMenu-${navItem.id}`}
						onClick={() => handleClickNavigationOption(navItem, index)}
						selected={selectedTabToHighlight}
					>
						<NavigationSubMenuItemText>
							{navItem.title}
							{selectedTabToHighlight && (
								<img
									alt={navItem.title ?? ""}
									src="/assets/navigation_logos/SelectedNavItemBlueDot.svg"
								/>
							)}
						</NavigationSubMenuItemText>
					</SubMenuListItemButton>
				</NavigationSubMenuItemButtonContainer>
			);
		}
	};

	return (
		<CompressedNavigationContainer>
			<div>
				<CompressedMenuSection>
					<img
						src="/assets/tandem_logos/CWGLogoNoText.svg"
						alt="CWG Logo"
						data-testid="tandem-branding"
					/>
				</CompressedMenuSection>
				<Divider />
				<CompressedMenuSection>
					<CompressedMenuPersonIcon />
				</CompressedMenuSection>
				<Divider />
				<CompressedMenuNavigationContainer>
					{mainNavigationItems.map((navItem, index) => renderCompressedNavigationMenuIcon(navItem, index))}
				</CompressedMenuNavigationContainer>
			</div>
			<div>
				<NavigationSubMenuLinks>
					<CloseSubMenuButton
						onClick={handleCloseSubMenu}
						data-id="navigation-close-sub-menu-button"
						data-testid="navigation-close-sub-menu-button"
					>
						<ChevronLeft />
						<span>{subNavigation?.title}</span>
					</CloseSubMenuButton>
					{subNavigation?.navigationItems?.map((navItem, index) =>
						renderCompressedSubNavigation(navItem, index)
					)}
				</NavigationSubMenuLinks>
			</div>
		</CompressedNavigationContainer>
	);
};

export default CompressedNavigation;
