import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { models } from "types/api/viewModels";

interface ShipToToAddressReturn {
	address?: models["AddressViewModel"];
	shipToId?: string | null;
}

export const convertShipToToAddress = (shipTo: models["ShipToViewModel"]): ShipToToAddressReturn => ({
	address: shipTo.address ?? undefined,
	shipToId: shipTo.shipToId ?? undefined
});

export const convertNewOrderToShipTo = (newOrder: PostOrdersRequestBody): models["ShipToViewModel"] | undefined =>
	newOrder.shipToId || (newOrder.shipToAddress && Object.keys(newOrder.shipToAddress).length > 0)
		? {
				shipToId: newOrder.shipToId,
				address: newOrder.shipToAddress
			}
		: undefined;
