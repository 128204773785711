import { type ChangeEvent, type FC } from "react";
import {
	ImpersonationBar,
	MaintenanceOrImpersonationBar,
	NowViewingText,
	UserWrapper
} from "components/Impersonation/impersonation.styles.ts";
import { Alert, AlertTitle, FormControlLabel, FormGroup, Switch, switchClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "stores/application.store.tsx";
import { LinkButton } from "components/Common/Link";
import { useTheme } from "@emotion/react";
import { impersonationActions } from "features/reducers/settings/impersonation.ts";
import { UserEmulationChange, UserEmulationNowViewing, UserEmulationSwitchLabel } from "constants/text.ts";
import { models } from "types/api/viewModels";

interface BarProps {
	handleModalState(isOpen: boolean): void;
	handleSetReloadKey(): void;
	isModalOpen: boolean;
	maintenanceBanner?: models["MaintenanceBannerViewModel"] | null;
	impersonationEnabled?: boolean;
}

const Bar: FC<BarProps> = ({
	handleModalState,
	handleSetReloadKey,
	isModalOpen,
	maintenanceBanner,
	impersonationEnabled
}) => {
	const dispatch = useDispatch();
	const impersonationUser = useSelector((state: RootState) => state.impersonation.impersonationUser);
	const emulatedUserDisplay =
		impersonationUser?.email ?? `${impersonationUser?.accountNumber} - ${impersonationUser?.accountName}`;
	const theme = useTheme();

	const handleEmulationChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			handleModalState(true);
		} else if (impersonationUser) {
			dispatch(impersonationActions.setImpersonationUser(undefined));
			handleSetReloadKey();
			handleModalState(false);
		}
	};

	return (
		<MaintenanceOrImpersonationBar>
			{impersonationEnabled && (
				<ImpersonationBar
					isActive={Boolean(impersonationUser)}
					data-testid="user-impersonation-bar"
				>
					<FormGroup>
						<FormControlLabel
							control={
								<Switch
									data-testid="user-impersonation-toggle"
									onChange={handleEmulationChange}
									checked={Boolean(impersonationUser) || isModalOpen}
									sx={{
										[`& .${switchClasses.switchBase}.${switchClasses.checked}`]: {
											color: theme.project.colors.fontColors.contrast
										},
										[`& .${switchClasses.switchBase}.${switchClasses.checked} + .${switchClasses.track}`]:
											{
												backgroundColor: theme.project.colors.fontColors.contrast
											}
									}}
								/>
							}
							label={UserEmulationSwitchLabel}
							sx={{
								"& .MuiFormControlLabel-label": {
									color:
										Boolean(impersonationUser) || isModalOpen
											? theme.project.colors.fontColors.contrast
											: theme.project.colors.fontColors.main
								}
							}}
						/>
					</FormGroup>
					{impersonationUser && (
						<UserWrapper>
							<NowViewingText>
								{UserEmulationNowViewing}: {emulatedUserDisplay}
							</NowViewingText>
							<LinkButton
								data-testid="user-impersonation-change-link-button"
								onClick={() => handleModalState(true)}
							>
								{UserEmulationChange}
							</LinkButton>
						</UserWrapper>
					)}
				</ImpersonationBar>
			)}
			{maintenanceBanner && (
				<Alert severity="warning">
					<AlertTitle>{maintenanceBanner.title}</AlertTitle>
					<span>{maintenanceBanner.description}</span>
				</Alert>
			)}
		</MaintenanceOrImpersonationBar>
	);
};

export default Bar;
