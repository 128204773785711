import {
	BuildNewOrderLineItemsContainer,
	NewOrdersButtonWrapper,
	NewOrdersLayoutContainer,
	NewOrdersContentContainer
} from "components/NewOrders/NewOrders.styles";
import {
	BackText,
	ContinueText,
	NewOrderPleaseContactCare,
	ReplacementPost400ErrorDescription,
	ReplacementPost400ErrorHeader
} from "constants/text";
import { NewOrderShippingDetailsLabel } from "components/NewOrders/constants";
import ShippingOrderDetails from "components/NewOrders/ShippingOrderDetails";
import { FieldValues, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import ShipToForm, { DefaultFormValues } from "components/ShipToForm/ShipToForm";
import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { AddressFormFields } from "pages/Replacements/forms/AddressForm/schema";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import NewOrdersSubheader from "components/NewOrders/NewOrdersSubheader/NewOrdersSubheader";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { BillToShipToBody, useNewOrderShipToAddressesMutation } from "features/api/newOrderApi.ts";
import { AddOnsProps } from "../BuildOrderPage/BuildOrderPage";
import useInitOrderDetail from "hooks/useInitOrderDetail";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { models } from "types/api/viewModels.ts";
import { getConfigurationAccountInfo } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { useGetAccountsByIdQuery } from "features/api/accountApi";
import { ShippingType } from "data/api/v1";
import { trackGA4Event } from "utils/googleAnalytics";
import ErrorBanner from "components/Common/ErrorBanner/ErrorBanner.tsx";

const handleErrorMessaging = (error: FieldValues) => {
	return (
		<div>
			<div>{ReplacementPost400ErrorHeader}</div>
			<div>{ReplacementPost400ErrorDescription}</div>
			<ul>
				{error?.address?.message && <li data-testid="address-field-error-message">Address</li>}
				{error?.poNumber?.message && <li data-testid="poNumber-field-error-message">PO #</li>}
				{error?.phoneNumber?.message && <li data-testid="phoneNumber-field-error-message">Phone Number</li>}
				{error?.email?.message && <li data-testid="email-field-error-message">Email</li>}
				{error?.customDesigner?.message && <li data-testid="customDesigner-field-error-message">Designer</li>}
				{error?.jobName?.message && <li data-testid="jobName-field-error-message">Job Name</li>}
			</ul>
			{NewOrderPleaseContactCare}
		</div>
	);
};

const NewOrderShippingDetailsPage = ({ isAddOn }: AddOnsProps) => {
	const params = useParams();
	const { orderDetail } = useInitOrderDetail(params.orderId);
	const dispatch = useDispatch();
	const navigateTo = useNavigate();
	const addedAddresses = useSelector((state: RootState) => state.newOrder.newAddresses);
	const newOrderDetails = useSelector((state: RootState) => state.newOrder.newOrderDetails);
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);
	const draftOrder = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);
	const defaultConfiguration = newOrderDetails?.configurations?.[0] ?? draftOrder?.configurations?.[0];
	const defaultAccountInfo = useMemo(
		() => getConfigurationAccountInfo(defaultConfiguration ?? undefined, productLineAccountInfo),
		[defaultConfiguration, productLineAccountInfo]
	);
	const [draft, setDraft] = useState(draftOrder);

	const isCustomerPickupAllowed = Boolean(defaultAccountInfo?.customerPickupAllowed);
	const allowLabelComments = Boolean(defaultAccountInfo?.allowLabelComments);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);

	const [newOrderShipToAddresses, { data: addressData, isLoading: addressesLoading }] =
		useNewOrderShipToAddressesMutation();

	const { data: accounts } = useGetAccountsByIdQuery(orderDetail?.orderId ?? "");
	const originalOrderShipTos = useMemo(
		() =>
			accounts
				?.filter((account) => account.isActive)
				.flatMap((account) => account.billTos?.flatMap((billTo) => billTo?.shipTos)),
		[accounts]
	);

	const [combinedAddresses, setCombinedAddresses] = useState<models["ShipToViewModel"][]>([]);

	useEffect(() => {
		if (!addressesLoading && addressData === undefined) {
			const shipToBodyCallback = (config: models["PendingOrderConfigurationViewModel"]): BillToShipToBody => ({
				accountId: String(config.accountId),
				billToId: String(config.billToId)
			});
			const allIds: BillToShipToBody[] =
				newOrderDetails?.configurations?.map(shipToBodyCallback) ??
				draftOrder?.configurations?.map(shipToBodyCallback) ??
				[];

			if (allIds.length > 0) {
				newOrderShipToAddresses(allIds)
					.unwrap()
					.catch((err) => err);
			}
		}
	}, [
		addressData,
		addressesLoading,
		draftOrder?.configurations,
		newOrderShipToAddresses,
		newOrderDetails?.configurations
	]);

	const shipToAddresses: models["ShipToViewModel"][] | undefined = useMemo(
		() =>
			(isAddOn ? originalOrderShipTos : addressData)?.map((address) => ({
				shipToId: address?.shipToId,
				isActive: address?.isActive ?? false,
				isSelected: address?.isSelected ?? false,
				address: {
					name: address?.address?.name ?? null,
					line1: address?.address?.line1,
					line2: address?.address?.line2,
					line3: address?.address?.line3,
					phoneNumber: address?.address?.phoneNumber,
					email: address?.address?.email,
					city: address?.address?.city,
					state: address?.address?.state,
					zip: address?.address?.zip,
					county: address?.address?.county
				}
			})),
		[addressData, originalOrderShipTos, isAddOn]
	);

	const { handleSubmit, watch, formState } = useFormContext();
	const { errors } = formState;
	const jobName = watch("jobName");
	const poNumber = watch("poNumber");
	const designer: models["DesignerViewModel"] | string | null | undefined = watch("designer");
	const customDesigner = watch("customDesigner");
	const address = watch("address");
	const email = watch("email");
	const isCustomerPickup = watch("isCustomerPickup");
	const phoneNumber = watch("phoneNumber");
	const requestedDeliveryDate = watch("requestedDeliveryDate");
	const deliveryInstructions = watch("deliveryInstructions");

	const saveShippingDetailsFormValuesToRedux = useCallback(
		(
			address?: models["ShipToViewModel"] | null,
			deliveryInstructions?: string | null,
			email?: string | null,
			phoneNumber?: string | null,
			poNumber?: string | null,
			jobName?: string | null
		) => {
			const newOrder: PostOrdersRequestBody = {
				...newOrderDetails,
				labelComments: deliveryInstructions,
				shipToAddress: {
					...address?.address,
					email: email ?? newOrderDetails?.shipToAddress?.email,
					phoneNumber: phoneNumber
				},
				shipToId: address?.shipToId,
				poNumber: poNumber,
				jobName: jobName
			};
			dispatch(newOrderActions.overwriteOrderDetails(newOrder));
		},
		[dispatch, newOrderDetails]
	);

	useEffect(() => {
		if (draft) {
			dispatch(newOrderActions.overwriteDraftOrder(draft));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Below useEffect updates draft when the form values change. This allows user to save draft without needing to submit form first
	useEffect(() => {
		const isCustomDesigner = typeof designer === "string";
		setDraft((prev) => ({
			...prev,
			jobName,
			poNumber,
			designerName: !isCustomDesigner
				? [designer?.firstName, designer?.lastName].filter(Boolean).join(" ")
				: null,
			designerNumber: isCustomDesigner ? customDesigner : (designer?.designerNumber ?? null),
			isCustomerPickup: isCustomerPickup,
			shipToAddress: {
				name: !isCustomerPickup ? address?.address?.name : undefined,
				line1: !isCustomerPickup ? address?.address?.line1 : undefined,
				line2: !isCustomerPickup ? address?.address?.line2 : undefined,
				line3: !isCustomerPickup ? address?.address?.line3 : undefined,
				mailStop: !isCustomerPickup ? address?.address?.mailStop : undefined,
				phoneNumber: phoneNumber ?? undefined,
				email: email ?? undefined,
				city: !isCustomerPickup ? (address?.address?.city ?? null) : undefined,
				state: !isCustomerPickup ? (address?.address?.state ?? null) : undefined,
				zip: !isCustomerPickup ? (address?.address?.zip ?? null) : undefined,
				county: !isCustomerPickup ? (address?.address?.county ?? null) : undefined
			},
			shipToId: !isCustomerPickup ? address?.shipToId : undefined,
			requestedDeliveryDate: requestedDeliveryDate,
			labelComments: deliveryInstructions
		}));
	}, [
		jobName,
		poNumber,
		designer,
		address,
		email,
		isCustomerPickup,
		phoneNumber,
		requestedDeliveryDate,
		deliveryInstructions,
		customDesigner
	]);

	const handleAddNewAddressModalSubmit = (address: AddressFormFields) => {
		const shippingAddress: models["ShipToViewModel"] = {
			shipToId: null,
			isActive: true,
			isSelected: true,
			address: {
				name: address.fullName,
				email: address.email,
				phoneNumber: address.contactPhoneNumber,
				line1: address.address,
				line2: address.address2,
				line3: null,
				city: address.city,
				state: address.state,
				zip: address.zip,
				county: address.county
			}
		};
		const updatedAddresses = combinedAddresses.map((addr) => ({
			...addr,
			isSelected: false
		}));

		const newCombinedAddresses = [...updatedAddresses, shippingAddress];

		setCombinedAddresses(newCombinedAddresses);
		dispatch(newOrderActions.addNewAddress(shippingAddress));
	};

	const handleOnCancel = () => {
		saveShippingDetailsFormValuesToRedux(address, deliveryInstructions, email, phoneNumber, poNumber, jobName);
		dispatch(newOrderActions.clearDraftError());
		draft && dispatch(newOrderActions.overwriteDraftOrder(draft));
		navigateTo(`/${isAddOn ? "add-ons" : "new-order"}/view-price-estimates${isAddOn ? "/" + params.orderId : ""}`);
	};

	const handleOnSave = (values: any) => {
		saveShippingDetailsFormValuesToRedux(address, deliveryInstructions, email, phoneNumber);
		dispatch(newOrderActions.clearDraftError());
		const formattedDeliveryDate = `${values.requestedDeliveryDate}T00:00:00.000Z`;
		const isCustomerPickup = Boolean(values.isCustomerPickup);
		if (values.customDesigner) {
			trackGA4Event({
				event: `${isAddOn ? "Add Ons" : "New Order"} - Submit custom designer #`,
				eventCategory: isAddOn ? "add ons" : "new order",
				eventAction: "submit",
				eventLabel: "custom designer number"
			});
		}
		/*
			Explanation of isCustomDesigner const below:
			Every option in the designer dropdown is a DesignerViewModel object, except for the DesignerIsNotHereText, which is a string.
			If the user has that option selected, then they input a custom designer.
		*/
		const isCustomDesigner = typeof designer === "string";

		const customerPickupOrShipToAddress = isCustomerPickup
			? {}
			: {
					name: address?.address?.name,
					line1: address?.address?.line1,
					line2: address?.address?.line2,
					line3: address?.address?.line3,
					mailStop: address?.address?.mailStop,
					city: address?.address?.city,
					state: address?.address?.state,
					zip: address?.address?.zip,
					county: address?.address?.county
				};

		const submittingOrder: PostOrdersRequestBody = {
			tandemOrderId: newOrderDetails?.tandemOrderId,
			jobName: values?.jobName?.trim(),
			poNumber: values?.poNumber?.trim(),
			orderType: newOrderDetails?.orderType,
			designerNumber: isCustomDesigner ? customDesigner : (designer?.designerNumber ?? null),
			labelComments: values.deliveryInstructions,
			requestedDeliveryDate: values.requestedDeliveryDate ? formattedDeliveryDate : null,
			originalOrderId: newOrderDetails?.originalOrderId,
			shipToId: !isCustomerPickup ? address?.shipToId : undefined,
			isCustomerPickup: values.isCustomerPickup ?? false,
			shipToAddress: {
				...customerPickupOrShipToAddress,
				phoneNumber: values.phoneNumber ?? undefined,
				email: values.email ?? undefined
			},
			configurations: validatedOrderResponse?.configurations
		};
		const draft: models["DraftOrderViewModel"] = {
			...submittingOrder,
			configurations: validatedOrderResponse?.configurations,
			draftOrderId: draftOrder?.draftOrderId,
			draftName: draftOrder?.draftName,
			designerNumber: isCustomDesigner ? customDesigner : (designer?.designerNumber ?? null),
			designerName: !isCustomDesigner
				? [designer?.firstName, designer?.lastName].filter(Boolean).join(" ")
				: null,
			type: draftOrder?.type
		};
		dispatch(newOrderActions.overwriteOrderDetails(submittingOrder));
		dispatch(newOrderActions.overwriteDraftOrder(draft));
		navigateTo(`/${isAddOn ? "add-ons" : "new-order"}/review-order${isAddOn ? "/" + params.orderId : ""}`);
	};

	const potentialFieldErrors = errors.poNumber || errors.customDesigner || errors.address || errors.email;
	const errorBannerElement = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (errors) {
			errorBannerElement?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}, [errors]);

	const defaultAddress = useMemo<models["ShipToViewModel"] | undefined>(() => {
		return orderDetail?.shippingDetails?.shipToAddress
			? {
					shipToId: "ORIGINALSHIPTOADDRESS",
					isActive: true,
					isSelected: true,
					address: {
						name: orderDetail.shippingDetails.shipToAddress.name,
						line1: orderDetail.shippingDetails.shipToAddress.line1,
						line2: orderDetail.shippingDetails.shipToAddress.line2,
						line3: orderDetail.shippingDetails.shipToAddress.line3,
						mailStop: "",
						phoneNumber: orderDetail.shippingDetails.shipToAddress.phoneNumber,
						email: orderDetail.shippingDetails.shipToAddress.email,
						city: orderDetail.shippingDetails.shipToAddress.city,
						state: orderDetail.shippingDetails.shipToAddress.state,
						zip: orderDetail.shippingDetails.shipToAddress.zip,
						county: orderDetail.shippingDetails.shipToAddress.county
					}
				}
			: undefined;
	}, [orderDetail]);

	const [defaultFormValues, setDefaultFormValues] = useState<DefaultFormValues | undefined>();

	useEffect(() => {
		setDefaultFormValues(() => {
			return orderDetail || newOrderDetails?.shipToAddress
				? {
						phoneNumber:
							newOrderDetails?.shipToAddress?.phoneNumber ??
							orderDetail?.shippingDetails?.shipToAddress?.phoneNumber ??
							undefined,
						address: defaultAddress,
						email:
							newOrderDetails?.shipToAddress?.email ??
							orderDetail?.shippingDetails?.shipToAddress?.email ??
							undefined,
						deliveryInstructions: newOrderDetails?.labelComments ?? orderDetail?.comments ?? undefined,
						requestedDeliveryDate: newOrderDetails?.requestedDeliveryDate ?? undefined
					}
				: undefined;
		});
	}, [
		defaultAddress,
		email,
		orderDetail,
		newOrderDetails?.shipToAddress,
		newOrderDetails?.labelComments,
		newOrderDetails?.requestedDeliveryDate
	]);

	useEffect(() => {
		if (!addressesLoading && shipToAddresses) {
			setCombinedAddresses([...addedAddresses, ...shipToAddresses]);
		}
		if (defaultAddress) {
			setCombinedAddresses((prev) => {
				const foundDefaultAddress = prev.find((address) => address.shipToId === defaultAddress.shipToId);
				if (foundDefaultAddress) {
					return prev;
				} else {
					return [...prev, defaultAddress];
				}
			});
		}
	}, [addressesLoading, shipToAddresses, addedAddresses, defaultAddress]);

	return (
		<form
			onSubmit={handleSubmit(handleOnSave)}
			noValidate
		>
			<NewOrdersLayoutContainer>
				<NewOrdersHeader
					draftOrder={draft}
					isAddOn={isAddOn}
				/>
				<NewOrdersContentContainer>
					<BuildNewOrderLineItemsContainer>
						<NewOrdersSubheader
							title={NewOrderShippingDetailsLabel}
							dataTestId="new-order-shipping-label"
							handleSearch={() => {}}
							draftOrder={draft}
							isAddOn={isAddOn}
						/>
						{potentialFieldErrors && (
							<ErrorBanner
								errorMessaging={handleErrorMessaging(errors)}
								ref={errorBannerElement}
							/>
						)}
						<ShippingOrderDetails
							defaultValues={{
								poNumber: newOrderDetails?.poNumber ?? orderDetail?.poNumber ?? undefined,
								jobName: newOrderDetails?.jobName ?? orderDetail?.jobName ?? undefined
							}}
							originalOrderDesigner={orderDetail?.designer}
						/>

						<ShipToForm
							allowLabelComments={allowLabelComments}
							analyticsDataId="new-order-shipping-details-new-address-link"
							combinedAddresses={combinedAddresses}
							defaultFormValues={defaultFormValues}
							customerPickupAllowed={isCustomerPickupAllowed ?? false}
							isCustomerPickupDefault={
								newOrderDetails?.isCustomerPickup ??
								orderDetail?.shippingDetails?.shipVia === ShippingType.CUSTOMERPICKUP
							}
							handleAddNewAddressModalSubmit={handleAddNewAddressModalSubmit}
							handleOnCancel={handleOnCancel}
							handleSetShippingInformation={saveShippingDetailsFormValuesToRedux}
							handleOnSave={handleOnSave}
							showCancelSaveButtons={false}
							hasFormContext
						/>
					</BuildNewOrderLineItemsContainer>
					<NewOrdersButtonWrapper>
						<Button
							variant="text"
							data-testid="newOrderShippingDetails-backButton"
							onClick={handleOnCancel}
						>
							{BackText}
						</Button>
						<Button
							fullWidth
							variant="contained"
							data-testid="newOrderShippingDetails-continueButton"
							data-id={`${isAddOn ? "add-ons" : "new-order"}-continue-to-review-button`}
							type="submit"
						>
							{ContinueText}
						</Button>
					</NewOrdersButtonWrapper>
				</NewOrdersContentContainer>
			</NewOrdersLayoutContainer>
		</form>
	);
};

export default NewOrderShippingDetailsPage;
