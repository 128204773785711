import styles from "pages/replacements-page-styles.module.css";
import { Grid } from "@mui/material";
import { ReturnToOriginalOrderText, OrderReplacementsText } from "constants/text";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ShoppingCartIcon from "./ShoppingCartIcon";
import LeavePageModal from "components/LeavePageModal/LeavePageModal";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { GenerateStepperWrapper } from "./ReplacementHeaderText.styles";
import { LinkButton } from "components/Common/Link";
import ProgressStepper from "../Common/Stepper/Stepper";
import PlaceOrderOriginalOrderDetails from "components/Common/PlaceOrderOriginalOrderDetails/PlaceOrderOriginalOrderDetails";
import { models } from "types/api/viewModels";

export interface ReplacementsHeaderProp {
	orderDetail: models["OrderDetailViewModel"] | null;
}
const ReplacementsHeader = ({ orderDetail }: ReplacementsHeaderProp) => {
	const location = useLocation();
	const orderReplacementsPage = location.pathname.includes(`replacements/${orderDetail?.orderId}`);

	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const navigate = useNavigate();
	const [leavePageModalOpen, setLeavePageModalOpen] = useState(false);
	const handleLeavePageModalOpen = (url: string) => {
		if (cartItems && cartItems.length > 0) {
			setLeavePageModalOpen(!leavePageModalOpen);
		} else {
			navigate(url);
		}
	};

	return (
		<div>
			<LeavePageModal
				open={leavePageModalOpen}
				url={`/details/${orderDetail?.orderId}`}
				continueOrdering={() => handleLeavePageModalOpen(`/details/${orderDetail?.orderId}`)}
				closeModal={() => setLeavePageModalOpen(false)}
			/>
			<Grid
				container
				className={styles.replacementsMainHeader}
			>
				<Grid item>
					<LinkButton
						id="order-details-my-orders-link"
						hasBackIcon
						onClick={() => handleLeavePageModalOpen(`/details/${orderDetail?.orderId}`)}
						data-testid="orderDetailsHeader-myOrdersLink"
					>
						{ReturnToOriginalOrderText}
					</LinkButton>
				</Grid>
				<ShoppingCartIcon showButtons={orderReplacementsPage} />
			</Grid>

			<Grid
				container
				className={styles.orderReplacementsHeaderContainer}
			>
				<Grid
					item
					xl={6}
				>
					<h4
						className={styles.replacementPageHeader}
						data-testid="replacements-header-text"
					>
						{OrderReplacementsText}
					</h4>
				</Grid>
				<GenerateStepperWrapper
					container
					xl={6}
					lg={7.5}
					md={8}
				>
					<ProgressStepper />
				</GenerateStepperWrapper>
			</Grid>
			<PlaceOrderOriginalOrderDetails orderDetail={orderDetail} />
		</div>
	);
};

export default ReplacementsHeader;
