import { Alert, Button, Checkbox, FormControlLabel, FormGroup, LinearProgress } from "@mui/material";
import { DialogTitle } from "../Dialog";
import {
	CancellationAcknowledgementText,
	CancellationCheckboxLabel,
	HaltProductionText,
	HangTightCancellationText,
	NoDoNotCancelButton,
	RequestCancellationButton,
	RequestFailedText,
	RequestToCancelHeaderText,
	RequestToCancelText,
	SuccessfulCancellationText,
	YouCannotUndoThisActionText
} from "./constants.ts";
import {
	CancellationAcknowledgementWrapper,
	CancellationButtonWrapper,
	CancelModalWrapper,
	ProcessingCancellationContainer,
	ProgressBarWrapper
} from "./NewOrders.styles.ts";
import { useEffect, useRef, useState } from "react";
import { useCancelOrderMutation } from "features/api/newOrderApi.ts";
import { models } from "types/api/viewModels.ts";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import {
	GenericOrderErrorMaintenanceSubHeader,
	FileSuccessText,
	NewOrderErrorMaintenanceHeader
} from "constants/text.ts";
import ErrorBanner from "components/Common/ErrorBanner/ErrorBanner.tsx";
import { isApiErrorResponse } from "features/api/utils/apiUtils.ts";

interface CancelOrderDialogProps {
	handleClose: () => void;
	orderDetails?: models["OrderDetailViewModel"];
}

const CancelOrderDialog = ({ handleClose, orderDetails }: CancelOrderDialogProps) => {
	const [cancelOrder, { error, isLoading, isSuccess }] = useCancelOrderMutation();
	const [isCancelling, setIsCancelling] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isProcessingCancellation, setIsProcessingCancellation] = useState(false);

	const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>();

	useEffect(() => {
		return () => {
			clearTimeout(timerRef.current);
		};
	}, []);

	const toggleCancellation = () => {
		setHasError(false);
		setIsCancelling((prevState) => !prevState);
	};

	const handleRequestCancellation = async () => {
		if (isCancelling) {
			setHasError(false);
			setIsProcessingCancellation(true);
			await cancelOrder(orderDetails?.orderId)
				.unwrap()
				.then(async () => {
					timerRef.current = setTimeout(function () {
						location.reload();
						handleClose();
					}, 4000);
				})
				.catch(() => console.log(error));
		} else {
			setHasError(true);
		}
	};

	const isMaintenanceError = error && isApiErrorResponse(error) && error.errors?.[0]?.code === 4002;

	return (
		<CancelModalWrapper
			isLoading={isLoading}
			isSuccess={isSuccess}
		>
			<DialogTitle
				data-testid="new-order-csv-upload-title"
				title={RequestToCancelHeaderText}
				handleClose={handleClose}
			/>
			{isMaintenanceError ? (
				<div>
					<ErrorBanner
						errorMessaging={
							<div style={{ flexDirection: "row" }}>
								<div>{NewOrderErrorMaintenanceHeader}</div>
								<div>{GenericOrderErrorMaintenanceSubHeader}</div>
								<div>(4002)</div>
							</div>
						}
					/>
				</div>
			) : (
				<>
					<CancellationAcknowledgementWrapper
						isCancelling={isProcessingCancellation}
						hasError={hasError}
					>
						<Alert
							data-testid="cancellation-acknowledgement-banner"
							icon={false}
							severity="error"
						>
							<div>
								<div>{HaltProductionText}</div>
								<div>{YouCannotUndoThisActionText}</div>
							</div>
							<div>{CancellationAcknowledgementText}</div>
						</Alert>
						<FormGroup>
							<FormControlLabel
								data-testid="cancellation-acknowledgement-checkbox"
								control={
									<Checkbox
										checked={isCancelling}
										onClick={toggleCancellation}
									/>
								}
								label={CancellationCheckboxLabel}
							/>
						</FormGroup>
					</CancellationAcknowledgementWrapper>
					<ProcessingCancellationContainer
						hasError={Boolean(error)}
						isCancelling={isProcessingCancellation}
					>
						{isLoading && <h1>{HangTightCancellationText}</h1>}
						{!error && !isLoading && <h1>{SuccessfulCancellationText}</h1>}
						<ProgressBarWrapper
							hasError={Boolean(error)}
							isCancelling={isProcessingCancellation}
						>
							<div>{RequestToCancelText}</div>
							<LinearProgress
								sx={{ display: isProcessingCancellation ? "block" : "none" }}
								variant={isLoading ? "indeterminate" : "determinate"}
								value={error ? 2 : 100}
							/>
							{!isLoading && (
								<div>
									<CheckCircleOutline data-testid="new-order-csv-upload-status-success-svg" />
									{error ? <div>{RequestFailedText}</div> : <div>{FileSuccessText}</div>}
								</div>
							)}
						</ProgressBarWrapper>
					</ProcessingCancellationContainer>
					{!isLoading && !isSuccess && (
						<CancellationButtonWrapper>
							<Button
								fullWidth
								variant="outlined"
								data-testid="do-not-cancel-order"
								onClick={handleClose}
								data-id="cancel-order-button"
								aria-label="do not cancel order"
							>
								{NoDoNotCancelButton}
							</Button>
							<Button
								fullWidth
								variant="contained"
								data-testid="request-order-cancellation"
								onClick={handleRequestCancellation}
								data-id="request-order-cancellation-button"
								aria-label="request cancellation"
							>
								{RequestCancellationButton}
							</Button>
						</CancellationButtonWrapper>
					)}
				</>
			)}
		</CancelModalWrapper>
	);
};
export default CancelOrderDialog;
