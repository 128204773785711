import React, { useState, useEffect } from "react";
import { Button, FormControlLabel, FormGroup, Radio } from "@mui/material";
import DatePickerDateField from "./DatePickerDateField";
import {
	SearchButtonText,
	CustomDateText,
	WithinLastSixMonthsLabelText,
	WithinLastThreeMonthsLabelText,
	WithinLastTwelveMonthsLabelText
} from "constants/text";
import { getPriorDateByDaysOffset } from "utils/date";

interface DatePickerFormProps {
	fromDate?: Date;
	toDate?: Date;
	onSetDateRange: (from: Date | null, to: Date | null, label?: string) => void;
	determineRangeLabel: (fromDate: Date, toDate: Date) => string;
}

const DatePickerForm: React.FC<DatePickerFormProps> = ({ onSetDateRange, fromDate, toDate, determineRangeLabel }) => {
	const defaultFromDate = fromDate || getPriorDateByDaysOffset(new Date(), 90);
	const defaultToDate = toDate || new Date();

	const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false);
	const [localFromDate, setLocalFromDate] = useState<Date | undefined>(defaultFromDate);
	const [localToDate, setLocalToDate] = useState<Date | undefined>(defaultToDate);
	const [isFormOpen, setIsFormOpen] = useState(true);
	const [selectedRangeLabel, setSelectedRangeLabel] = useState<string | null>(null);

	useEffect(() => {
		if (fromDate && toDate) {
			const label = determineRangeLabel(fromDate, toDate);
			setSelectedRangeLabel(label);
			setIsCustomRangeOpen(label === "custom");
		}
	}, [fromDate, toDate, determineRangeLabel]);

	const maxDateRange = new Date();
	maxDateRange.setFullYear(maxDateRange.getFullYear() - 20);

	const currentYear = new Date().getFullYear();
	const labelCurrentYear = `${currentYear}`;
	const labelPreviousYear = `${currentYear - 1}`;
	const labelTwoYearsAgo = `${currentYear - 2}`;
	const labelThreeYearsAgo = `${currentYear - 3}`;

	const handlePredefinedRangeSelection = (range: string) => {
		let fromDate: Date | undefined;
		let toDate: Date | undefined;

		switch (range) {
			case "lastThreeMonths":
				fromDate = new Date();
				fromDate.setMonth(fromDate.getMonth() - 3);
				fromDate.setHours(0, 0, 0, 0);
				toDate = new Date();
				toDate.setHours(23, 59, 59, 999);
				setSelectedRangeLabel(WithinLastThreeMonthsLabelText);
				break;

			case "lastSixMonths":
				fromDate = new Date();
				fromDate.setMonth(fromDate.getMonth() - 6);
				fromDate.setHours(0, 0, 0, 0);
				toDate = new Date();
				toDate.setHours(23, 59, 59, 999);
				setSelectedRangeLabel(WithinLastSixMonthsLabelText);
				break;

			case "lastTwelveMonths":
				fromDate = new Date();
				fromDate.setFullYear(fromDate.getFullYear() - 1);
				fromDate.setHours(0, 0, 0, 0);
				toDate = new Date();
				toDate.setHours(23, 59, 59, 999);
				setSelectedRangeLabel(WithinLastTwelveMonthsLabelText);
				break;

			case "currentYear":
				fromDate = new Date(new Date().getFullYear(), 0, 1);
				fromDate.setHours(0, 0, 0, 0);
				toDate = new Date();
				toDate.setHours(23, 59, 59, 999);
				setSelectedRangeLabel(`${new Date().getFullYear()}`);
				break;

			case "previousYear":
				fromDate = new Date(currentYear - 1, 0, 1);
				toDate = new Date(currentYear - 1, 11, 31);
				setSelectedRangeLabel(labelPreviousYear);
				break;

			case "twoYearsAgo":
				fromDate = new Date(currentYear - 2, 0, 1);
				toDate = new Date(currentYear - 2, 11, 31);
				setSelectedRangeLabel(labelTwoYearsAgo);
				break;

			case "threeYearsAgo":
				fromDate = new Date(currentYear - 3, 0, 1);
				toDate = new Date(currentYear - 3, 11, 31);
				setSelectedRangeLabel(labelThreeYearsAgo);
				break;

			case "custom":
				setSelectedRangeLabel("custom");
				setIsCustomRangeOpen(true);
				return;

			default:
				return;
		}

		setLocalFromDate(fromDate);
		setLocalToDate(toDate);
		setIsCustomRangeOpen(false);
	};

	const handleOnClick = () => {
		if (selectedRangeLabel === "custom" && localFromDate && localToDate) {
			onSetDateRange(localFromDate, localToDate);
		} else if (selectedRangeLabel && localFromDate && localToDate) {
			onSetDateRange(localFromDate, localToDate, selectedRangeLabel);
		}
		setIsFormOpen((prev) => !prev);
	};

	return (
		<>
			{isFormOpen && (
				<FormGroup sx={{ p: "20px" }}>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-last-three-months-radio"
								checked={selectedRangeLabel === WithinLastThreeMonthsLabelText}
								onChange={() => handlePredefinedRangeSelection("lastThreeMonths")}
							/>
						}
						label={WithinLastThreeMonthsLabelText}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-last-six-months-radio"
								checked={selectedRangeLabel === WithinLastSixMonthsLabelText}
								onChange={() => handlePredefinedRangeSelection("lastSixMonths")}
							/>
						}
						label={WithinLastSixMonthsLabelText}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-last-twelve-months-radio"
								checked={selectedRangeLabel === WithinLastTwelveMonthsLabelText}
								onChange={() => handlePredefinedRangeSelection("lastTwelveMonths")}
							/>
						}
						label={WithinLastTwelveMonthsLabelText}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-current-year-radio"
								checked={selectedRangeLabel === labelCurrentYear}
								onChange={() => handlePredefinedRangeSelection("currentYear")}
							/>
						}
						label={labelCurrentYear}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-previous-year-radio"
								checked={selectedRangeLabel === labelPreviousYear}
								onChange={() => handlePredefinedRangeSelection("previousYear")}
							/>
						}
						label={labelPreviousYear}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-two-years-ago-radio"
								checked={selectedRangeLabel === labelTwoYearsAgo}
								onChange={() => handlePredefinedRangeSelection("twoYearsAgo")}
							/>
						}
						label={labelTwoYearsAgo}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-three-years-ago-radio"
								checked={selectedRangeLabel === labelThreeYearsAgo}
								onChange={() => handlePredefinedRangeSelection("threeYearsAgo")}
							/>
						}
						label={labelThreeYearsAgo}
					/>
					<FormControlLabel
						control={
							<Radio
								data-testid="date-picker-custom-dates-radio"
								checked={selectedRangeLabel === "custom"}
								onChange={() => handlePredefinedRangeSelection("custom")}
							/>
						}
						label={CustomDateText}
					/>
					{isCustomRangeOpen && (
						<>
							<DatePickerDateField
								data-testid="date-picker-form-item-from"
								label="From"
								value={localFromDate}
								firstValidDate={maxDateRange}
								secondValidDate={localToDate}
								onChange={setLocalFromDate}
							/>
							<DatePickerDateField
								data-testid="date-picker-form-item-to"
								label="To"
								value={localToDate}
								firstValidDate={localFromDate}
								secondValidDate={localToDate}
								onChange={setLocalToDate}
							/>
						</>
					)}
					<Button
						variant="contained"
						onClick={handleOnClick}
						sx={{ margin: ".5rem 0 0 0" }}
						data-id="date-picker-search-button"
						data-testid="date-picker-search-button"
					>
						{SearchButtonText}
					</Button>
				</FormGroup>
			)}
		</>
	);
};

export default DatePickerForm;
