import { ObjectSchema, object, string, mixed, InferType } from "yup";
import { AddressRequiredText, PONumberRequiredText } from "constants/text";
import { shipToFormSchemaObject, type FormSchema as ShipToSchema } from "components/Replacements/ShipToForm/schema";
import {
	CustomDesignerLengthText,
	DesignerIsNotHereText,
	DesignerNumberMatchText,
	DesignerNumberRequiredText
} from "../constants";
import { DesignerViewModel } from "data/api/v1";
import { models } from "types/api/viewModels.ts";

export const PO_NUMBER_MAX_LENGTH = 25;
export const JOB_NAME_MAX_LENGTH = 30;
export const CUSTOM_DESIGNER_LENGTH = 6;

export interface FormSchema extends ShipToSchema {
	designer?: DesignerViewModel | string | null;
	jobName?: string;
	poNumber?: string;
	address?: models["ShipToViewModel"];
	customDesigner?: string | null;
}

export const schema: ObjectSchema<FormSchema> = object({
	...shipToFormSchemaObject,
	designer: mixed<DesignerViewModel | string>().nullable(),
	jobName: string().max(JOB_NAME_MAX_LENGTH),
	poNumber: string().max(PO_NUMBER_MAX_LENGTH).required(PONumberRequiredText),
	address: mixed<models["ShipToViewModel"]>().when("isCustomerPickup", {
		is: true,
		then: (schema) => schema,
		otherwise: (schema) => schema.required(AddressRequiredText)
	}),
	customDesigner: string().when("designer", {
		is: DesignerIsNotHereText,
		then: (schema) =>
			schema
				.required(DesignerNumberRequiredText)
				.matches(/^1\d{0,5}$/, DesignerNumberMatchText)
				.length(CUSTOM_DESIGNER_LENGTH, CustomDesignerLengthText(CUSTOM_DESIGNER_LENGTH)),
		otherwise: (schema) => schema.notRequired().nullable()
	})
});

export type ShippingDetailsSchema = InferType<typeof schema>;
