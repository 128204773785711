import { baseURL, getOrdersInitial } from "constants/endpoints";
import { DateRange } from "pages/OrderPages/OrderListPage/OrderListPage";
import { encodeQueryStringToBase64 } from "utils/string";
import { OrderSummaryViewModel } from "../model";

export interface OrderSummaryRequest {
	searchDates: DateRange;
	searchTerm: string;
}

export interface OrderSummaryResponse {
	data: {
		items: Array<OrderSummaryViewModel>;
		isPartialDataset: boolean;
	};
}

export const formatSummaryPath = (req: OrderSummaryRequest): string => {
	let uri = `${baseURL}${getOrdersInitial}`;

	if (req.searchDates?.fromDate !== undefined) {
		const fromDateISO = req.searchDates?.fromDate.toISOString().split("T")[0];
		uri += `?fromDate=${fromDateISO}`;
	} else {
		const date = new Date();
		date.setDate(date.getDate() - 90);
		const ninetyDays = date.toISOString().split("T")[0];
		uri += `?fromDate=${ninetyDays}`;
	}

	if (req.searchDates?.toDate !== undefined) {
		const toDateISO = req.searchDates?.toDate.toISOString().split("T")[0];
		uri += `&toDate=${toDateISO}`;
	}

	if (req.searchTerm !== undefined && req.searchTerm !== "") {
		uri += `&search=${encodeURIComponent(req.searchTerm)}`;
	}

	return encodeQueryStringToBase64(uri);
};
