import styled from "@emotion/styled";
import { FormControlLabel } from "@mui/material";
import ErrorBanner from "components/Common/ErrorBanner/ErrorBanner.tsx";

export const VerifyAddressErrorBanner = styled(ErrorBanner)({
	margin: 0
});

export const ConfirmAddressWrapper = styled.div({
	alignItems: "flex-start",
	display: "flex",
	flex: 1,
	flexDirection: "column",
	gap: 24,
	width: "100%"
});

export const LoadingWrapper = styled(ConfirmAddressWrapper)(({ theme }) => ({
	...theme.project.typography.heading.h6,
	alignItems: "center",
	color: theme.project.colors.fontColors.main,
	gap: 32,
	justifyContent: "flex-end",
	"& > span": {
		marginTop: 32,
		width: "100%"
	},
	[theme.breakpoints.down("md")]: {
		display: "grid",
		justifyItems: "center",
		"& > span": {
			alignSelf: "flex-end"
		}
	}
}));

export const RadioLabel = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2
}));

export const StyledFormControlLabel = styled(FormControlLabel)({
	marginBottom: 16
});

export const DisplayOnlyAddress = styled.div(({ theme }) => ({
	...theme.project.typography.body.body1,
	color: theme.project.colors.fontColors.main,
	marginTop: 8
}));
