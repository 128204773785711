import { Content, StyledDialog } from "./AddGlobalAttributes/forms/form.styles";
import { DialogTitle } from "../Dialog";
import { FC, MouseEvent, PropsWithChildren } from "react";
import { Button, MobileStepper } from "@mui/material";
import { GlobalAttributeFields } from "./AddGlobalAttributes/forms/schema";
import { useFormContext } from "react-hook-form";
import { AllFieldsRequired, Description } from "components/NewOrders/AddGlobalAttributes/addGlobalAttributes.styles.ts";
import {
	AddGlobalAttributesGroupTitle,
	NewOrderBack,
	NewOrderContinueButton,
	NewOrderSaveButton,
	UpdateGlobalAttributesGroupDescription,
	UpdateGlobalAttributesGroupTitle
} from "components/NewOrders/constants.ts";
import { AllFieldsRequiredText } from "constants/text.ts";

interface AddGlobalAttributesDialogProps extends PropsWithChildren {
	isUpdate: boolean;
	handleDialogClose: () => void;
	dialogState: boolean;
	handleStepperForward: (event: MouseEvent<HTMLButtonElement>) => void;
	handleStepperBack: (event: MouseEvent<HTMLButtonElement>) => void;
	step: number;
	maxSteps: number;
	onSave: (values: GlobalAttributeFields) => void;
}

const AddGlobalAttributesDialog: FC<AddGlobalAttributesDialogProps> = ({
	isUpdate,
	handleDialogClose,
	dialogState,
	handleStepperForward,
	handleStepperBack,
	step,
	maxSteps,
	onSave,
	children
}) => {
	const { handleSubmit } = useFormContext<GlobalAttributeFields>();

	return (
		<StyledDialog
			open={dialogState}
			data-testid="leave-flow-dialog"
			onClose={handleDialogClose}
		>
			<form
				onSubmit={(event) => {
					event.stopPropagation();
					handleSubmit(onSave)(event);
				}}
				noValidate
			>
				<DialogTitle
					title={isUpdate ? UpdateGlobalAttributesGroupTitle : AddGlobalAttributesGroupTitle}
					handleClose={handleDialogClose}
				/>
				<Content>
					<AllFieldsRequired style={{ paddingLeft: 24 }}>{AllFieldsRequiredText}</AllFieldsRequired>
					{isUpdate && <Description>{UpdateGlobalAttributesGroupDescription}</Description>}
					{children}
					<MobileStepper
						variant="text"
						steps={maxSteps}
						position="static"
						activeStep={step}
						nextButton={
							step !== maxSteps - 1 ? (
								<Button
									size="small"
									variant="contained"
									onClick={handleStepperForward}
								>
									{NewOrderContinueButton}
								</Button>
							) : (
								<Button
									data-testid="global-attribute-modal-save-button"
									size="small"
									variant="contained"
									type="submit"
								>
									{isUpdate ? NewOrderSaveButton : NewOrderContinueButton}
								</Button>
							)
						}
						backButton={
							<Button
								data-testid="global-attribute-modal-back-button"
								onClick={handleStepperBack}
								style={{ fontSize: 16 }}
								disabled={step === 0}
								sx={step === 0 ? { visibility: "hidden" } : undefined}
								variant="text"
							>
								{NewOrderBack}
							</Button>
						}
					/>
				</Content>
			</form>
		</StyledDialog>
	);
};

export default AddGlobalAttributesDialog;
