import styled from "@emotion/styled";

interface OrderListErrorWarningWrapperProps {
	isMobile: boolean;
}

export const OrderListErrorWarningWrapper = styled.div<OrderListErrorWarningWrapperProps>(({ isMobile, theme }) => ({
	backgroundColor: theme.project.colors.allColors.hex.orange.orange50,
	display: "flex",
	flexWrap: "nowrap",
	alignItems: "center",
	color: theme.project.colors.allColors.hex.orange.orange500,
	padding: "8px 4px",
	marginBottom: "16px",
	p: {
		margin: "0 8px",
		fontWeight: 500,
		lineHeight: "150%",
		letterSpacing: "0.15px"
	}
}));

interface DefaultMessageWrapperProps {
	removeHeight?: boolean;
}

export const DefaultMessageWrapper = styled.div<DefaultMessageWrapperProps>(({ removeHeight, theme }) => ({
	backgroundColor: theme.project.colors.backgroundColors.main,
	boxShadow: "0 3px 1px -2px var(--gray-400)",
	padding: "24px",
	margin: "0 16px",
	height: removeHeight ? "unset" : "calc(100vh - 280px)",
	minHeight: "450px",
	textAlign: "center",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: theme.project.colors.fontColors.main,
	div: {
		marginTop: "8px"
	},
	h6: {
		margin: "0 0 8px 0"
	},
	p: {
		lineHeight: "var(--line-height-13)",
		letterSpacing: "var(--letter-spacing-4)",
		margin: 0
	}
}));

export const BulletContainer = styled.div({
	textAlign: "left"
});

export const OrderListFiltersContainer = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: "16px",
	[theme.breakpoints.down("lg")]: {
		flexDirection: "column",
		gap: "8px",
		marginTop: "8px"
	}
}));
