import { type FC, type SyntheticEvent, useEffect, useRef, useState } from "react";
import { DividerText, EmulationForm, StyledDialog } from "components/Impersonation/impersonation.styles.ts";
import { useGetUserEmulationQuery } from "features/api/userApi.ts";
import { EmulationSelection, impersonationActions } from "features/reducers/settings/impersonation.ts";
import { DialogTitle, FooterActions } from "components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetEmulationAccountsQuery } from "features/api/accountApi.ts";
import type { RootState } from "stores/application.store.tsx";
import {
	UserEmulationAccountLabel,
	UserEmulationBackButton,
	UserEmulationConfirmButton,
	UserEmulationEmailLabel,
	UserEmulationFormAccountErrorText,
	UserEmulationFormEmailErrorText,
	UserEmulationModalTitle,
	UserEmulationOrDividerText
} from "constants/text.ts";
import { type GetEmulationAccountsResponseData } from "types/api/accounts/getEmulationAccounts.ts";
import Divider from "@mui/material/Divider";
import AutoComplete from "components/Common/Autocomplete/Autocomplete.tsx";
import useDebounce from "hooks/useDebounce.ts";

const getAccountLabel = (account: EmulationSelection | null | undefined) =>
	account ? `${account.accountNumber ?? ""} - ${account.accountName ?? ""}` : "";

interface ModalProps {
	handleModalState(isOpen: boolean): void;
	handleSetReloadKey(): void;
	open: boolean;
}

const Modal: FC<ModalProps> = ({ handleModalState, handleSetReloadKey, open }) => {
	const { data: availableUsers, isFetching } = useGetUserEmulationQuery();
	const [fetchEmulationAccounts, { data: emulationAccounts, isFetching: isEmulationAccountsFetching }] =
		useLazyGetEmulationAccountsQuery();
	const impersonationUser = useSelector((state: RootState) => state.impersonation.impersonationUser);
	const dispatch = useDispatch();

	// Separate state so we can store null for an error state
	const [selectedUser, setSelectedUser] = useState<EmulationSelection | null | undefined>();
	const [selectedAccount, setSelectedAccount] = useState<EmulationSelection | null | undefined>();
	const [selectedEmail, setSelectedEmail] = useState<EmulationSelection | null | undefined>();
	const [accountInput, setAccountInput] = useState<string | undefined>();
	const [accountOptions, setAccountOptions] = useState<NonNullable<GetEmulationAccountsResponseData>>([]);

	const currentAccountInfo = useRef<EmulationSelection | undefined>();
	const debouncedAccountInput = useDebounce(accountInput, 300);

	useEffect(() => {
		if (emulationAccounts && !isEmulationAccountsFetching) {
			setAccountOptions(
				[...emulationAccounts].sort((a, b) => {
					if (a.accountNumber && b.accountNumber && a.accountName && b.accountName) {
						return getAccountLabel(a).localeCompare(getAccountLabel(b));
					}

					return 0;
				})
			);
		}
	}, [emulationAccounts, isEmulationAccountsFetching]);

	useEffect(() => {
		if (
			debouncedAccountInput &&
			debouncedAccountInput.length >= 3 &&
			debouncedAccountInput !== currentAccountInfo.current?.accountNumber &&
			debouncedAccountInput !== getAccountLabel(currentAccountInfo.current)
		) {
			fetchEmulationAccounts({ accountNumber: debouncedAccountInput });
		}
		if (!debouncedAccountInput || debouncedAccountInput.length < 3) {
			setAccountOptions([]);
		}
	}, [debouncedAccountInput, dispatch, fetchEmulationAccounts]);

	useEffect(() => {
		if (!impersonationUser) {
			setSelectedUser(undefined);
			setSelectedEmail(undefined);
			setSelectedAccount(undefined);
			setAccountOptions([]);
			currentAccountInfo.current = undefined;
		}
	}, [impersonationUser]);

	const handleSubmit = () => {
		if (!selectedUser) {
			setSelectedUser(null);
		} else {
			dispatch(impersonationActions.setImpersonationUser(selectedUser));
			handleModalState(false);
		}
	};

	const handleChange = (_event: SyntheticEvent, value: any, reason: string, field: "account" | "email") => {
		const valueToSet = reason === "clear" ? undefined : value;
		const refinedValue = valueToSet
			? {
					accountId: valueToSet?.accountId,
					accountName: valueToSet?.accountName,
					accountNumber: valueToSet?.accountNumber,
					email: typeof valueToSet === "string" ? valueToSet : undefined
				}
			: undefined;

		setSelectedUser(refinedValue);

		if (field === "account") {
			currentAccountInfo.current = refinedValue;
			setSelectedAccount(refinedValue);
			setAccountOptions((prev) => prev.filter((option) => option.accountNumber === refinedValue?.accountNumber));
		} else {
			setSelectedEmail(refinedValue);
		}
	};

	const handleModalClose = () => {
		setSelectedUser(selectedUser === null ? undefined : impersonationUser);

		if (!impersonationUser) {
			currentAccountInfo.current = undefined;
			setAccountOptions([]);
			setSelectedAccount(undefined);
			setSelectedEmail(undefined);
		}

		handleModalState(false);
	};

	const handleBackToCareClick = () => {
		dispatch(impersonationActions.setImpersonationUser(undefined));
		setSelectedUser(undefined);
		setSelectedAccount(undefined);
		setSelectedEmail(undefined);
		currentAccountInfo.current = undefined;
		handleSetReloadKey();
		handleModalState(false);
	};

	return (
		<StyledDialog
			open={open}
			data-testid="user-impersonation-modal"
		>
			<DialogTitle
				title={UserEmulationModalTitle}
				handleClose={handleModalClose}
			/>
			<EmulationForm>
				<AutoComplete
					disabled={Boolean(selectedEmail)}
					isLoading={isEmulationAccountsFetching}
					options={accountOptions}
					getOptionLabel={getAccountLabel}
					onChange={(event, value, reason) => handleChange(event, value, reason, "account")}
					onInputChange={(_event, value) => setAccountInput(value)}
					errorText={UserEmulationFormAccountErrorText}
					isError={selectedUser === null}
					value={selectedAccount}
					required
					label={UserEmulationAccountLabel}
					dataTestId="user-impersonation-account-autocomplete"
					isOptionEqualToValue={(option: string, value: string) => option === value}
				/>
				<div>
					<Divider>
						<DividerText>{UserEmulationOrDividerText}</DividerText>
					</Divider>
				</div>
				<AutoComplete
					disabled={Boolean(selectedAccount)}
					isLoading={isFetching}
					options={availableUsers ?? []}
					onChange={(event, value, reason) => handleChange(event, value, reason, "email")}
					errorText={UserEmulationFormEmailErrorText}
					isError={selectedUser === null}
					value={selectedEmail?.email}
					virtualize
					required
					label={UserEmulationEmailLabel}
					dataTestId="user-impersonation-email-autocomplete"
					isOptionEqualToValue={(option: string, value: string) => option === value}
				/>
				<FooterActions
					cancelText={UserEmulationBackButton}
					confirmText={UserEmulationConfirmButton}
					handleClose={handleBackToCareClick}
					handleConfirm={handleSubmit}
				/>
			</EmulationForm>
		</StyledDialog>
	);
};

export default Modal;
