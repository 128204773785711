import SearchIcon from "@mui/icons-material/Search";
import { SyntheticEvent, useState } from "react";
import { StyledSuggestedSearch, SuggestedSearchPopper, SuggestedSearchTextField } from "./SuggestedSearch.styles";
import { PopperProps } from "@mui/material";

interface SuggestedSearchProps {
	autoFocus?: boolean;
	ariaLabel: string;
	dataTestId: string;
	dropDownOpenOnChar: number;
	placeholder: string;
	id: string;
	// options to render within the presentation portal.
	searchOptions: Array<string>;
	searchValue: string;
	handleSearch: (e: SyntheticEvent, v: string | null) => void;
	size: "small" | "medium" | undefined;
	error?: boolean;
}

const CustomPopper = (props: PopperProps) => {
	return <SuggestedSearchPopper {...props} />;
};

const SuggestedSearch = ({
	autoFocus,
	ariaLabel,
	dataTestId,
	dropDownOpenOnChar,
	placeholder,
	handleSearch,
	id,
	searchOptions,
	searchValue,
	size,
	error
}: SuggestedSearchProps) => {
	const [open, setOpen] = useState(false);
	const [searchInput, setSearchInput] = useState("");
	const [arrowKeyUsed, setArrowKeyUsed] = useState(false);

	const isOptionEqualToValue = (option: string, value: string) => {
		return option.toUpperCase().includes(value.toUpperCase());
	};

	return (
		<StyledSuggestedSearch
			id={id}
			open={open}
			onInputChange={(_, value) => {
				setSearchInput(value);
				const shouldOpen = value.length >= dropDownOpenOnChar;
				if (shouldOpen !== open) {
					setOpen((prevState) => !prevState);
				}
				setArrowKeyUsed(false);
			}}
			onClose={() => setOpen(false)}
			onChange={handleSearch}
			onKeyDown={(event) => {
				if (event.key === "Enter") {
					if (!arrowKeyUsed) {
						event.defaultMuiPrevented = true;
						const searchOptionsFound = searchOptions.find((option) =>
							option.toUpperCase().includes(searchInput.toUpperCase().trim())
						);
						handleSearch(event, searchOptionsFound ?? searchInput);
					}
					setOpen(false);
				} else if (event.key === "ArrowDown" || event.key === "ArrowUp") {
					setArrowKeyUsed(true);
				}
			}}
			value={searchValue}
			isOptionEqualToValue={isOptionEqualToValue}
			aria-label={ariaLabel}
			data-testid={dataTestId}
			options={Array.from(new Set(searchOptions))}
			filterOptions={(options, state) => {
				const query = state.inputValue.trim().toLowerCase();
				return options.filter((option) => option.toLowerCase().includes(query));
			}}
			size={size}
			popupIcon={
				<SearchIcon
					onClick={(e) => {
						handleSearch(e, searchInput.trim());
						setOpen(false);
					}}
				/>
			}
			renderInput={(params) => (
				<div ref={params.InputProps.ref}>
					<SuggestedSearchTextField
						{...params}
						autoFocus={autoFocus}
						error={error}
						data-testid="lineItem-search-input"
						placeholder={placeholder}
						variant="outlined"
						onChange={(e) => setSearchInput(e.target.value)}
					/>
				</div>
			)}
			PopperComponent={CustomPopper}
		/>
	);
};

export default SuggestedSearch;
