import styles from "../order-status-page-styles.module.css";
import { ErrorButtonWrapper } from "../ErrorModal.styles";
import { OrderNotFoundDescription, OrderNotFoundHeader, ReturnToMyOrdersButtonText } from "constants/text";
import React from "react";
import { useNavigate } from "react-router";

const OrderNotFound = () => {
	const navigateTo = useNavigate();

	return (
		<div
			className={styles.errorContainer}
			data-testid="not-found-order-error-container"
		>
			<div className={styles.errorContent}>
				<img
					src="/assets/tandem_logos/tandem.png"
					alt="Tandem Logo"
					className={styles.tandemLogo}
					data-testid="not-found-order-tandem-logo"
				/>
				<img
					src="/assets/NoOrdersSadBoxImage.svg"
					alt="not-found-order-logo"
					className={styles.noServiceSVG}
					data-testid="not-found-order-logo"
				/>

				<div>
					<h1 data-testid="not-found-order-header">{OrderNotFoundHeader}</h1>
					<p
						data-testid="not-found-order-description"
						className={styles.errorText}
					>
						{OrderNotFoundDescription}
					</p>
				</div>

				<ErrorButtonWrapper
					variant="contained"
					onClick={() => navigateTo("/projects")}
					data-testid="not-found-order-home-button"
				>
					{ReturnToMyOrdersButtonText}
				</ErrorButtonWrapper>
			</div>
		</div>
	);
};

export default OrderNotFound;
