import styles from "pages/replacements-page-styles.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { OrderDetailViewModel, OrderType } from "data/api/v1";
import {
	AccountNumberText,
	DesignerNumberText,
	OrderDateText,
	OrderReplacementsJobName,
	OrderReplacementsPONumber,
	ReplacementConfirmationLabel,
	ReplacementConfirmationOriginalOrderNumber,
	ReplacmentConfirmationOrderNumber,
	ReplacmentConfirmationOrderPending,
	UnavailableDataPlaceholderText
} from "constants/text";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import SelectedReplacementReason from "./SelectedReplacementReason";
import { dateFormat } from "utils/date";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import { ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import ReplacementShipTo from "./ReplacementShipTo";
import { brandCheck, displayDesigner } from "utils/order";
import {
	OrderDetailsContainer,
	OrderInformationWrapper,
	OriginalOrderContainer,
	ShippingDetailsHeaderWrapper
} from "./ReplacementConfirmationOrderShippingDetails.styles";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import { useGetAccountsByIdQuery, useGetBillToInfoQuery } from "features/api/accountApi.ts";
import { models } from "types/api/viewModels.ts";
import { NewOrderNumberHeader } from "../NewOrders/NewOrders.styles.ts";

export interface ReplacementConfirmationOrderShippingDetailsProps {
	orderDetail: OrderDetailViewModel | null;
	accounts: models["CustomerAccountViewModel"] | undefined;
}

const ReplacementConfirmationOrderShippingDetails = ({
	orderDetail,
	accounts
}: ReplacementConfirmationOrderShippingDetailsProps) => {
	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const replacementOrder = useSelector((state: RootState) => state.submittingReplacementOrder.order);
	const { data: userDetails } = useGetUserDetailsQuery();
	const userAccountInformation: string = useMemo(() => {
		const accountMatch = userDetails?.user?.accounts?.find(
			(account) => account.number === orderDetail?.accountNumber
		);
		if (accountMatch) {
			return `${accountMatch.number}` + (accountMatch.description ? ` - ${accountMatch.description}` : "");
		} else {
			return "";
		}
	}, [userDetails, orderDetail]);

	const todayDate = useRef(new Date());

	const [billTo, setBillTo] = useState<models["BillToViewModel"] | null>(null);

	const { data: account } = useGetAccountsByIdQuery(orderDetail?.orderId ?? "", { skip: !orderDetail?.orderId });

	const accountId = account?.[0]?.accountId;

	const { data: billToInfo } = useGetBillToInfoQuery(
		{ accountId: accountId ?? "", billToId: orderDetail?.billToId ?? "" },
		{ skip: !accountId || !orderDetail?.billToId }
	);

	useEffect(() => {
		if (billToInfo && !deepEqual(billTo, billToInfo)) {
			setBillTo(billToInfo);
		}
	}, [billTo, billToInfo]);

	const deepEqual = (obj1: any, obj2: any) => JSON.stringify(obj1) === JSON.stringify(obj2);

	return (
		<div className={styles.confirmationOrderContainer}>
			<ShippingDetailsHeaderWrapper>
				{ReplacementConfirmationLabel}
				<div>
					<StyledSummaryCardLogo
						parentBrand={orderDetail?.parentBrand}
						alt={
							brandCheck(orderDetail?.parentBrand)
								? (orderDetail?.parentBrand ?? undefined)
								: "CabinetworksGroup Logo"
						}
						data-testid="replacement-confirmation-brand-logo"
						src={
							brandCheck(orderDetail?.parentBrand)
								? `/assets/manufacture_logos/${orderDetail?.parentBrand}.png`
								: "/assets/tandem_logos/cwg_logo.png"
						}
					/>
					<div data-testid="replacement-confirmation-brand">{orderDetail?.brand}</div>
				</div>
			</ShippingDetailsHeaderWrapper>
			<OrderInformationWrapper>
				<NewOrderNumberHeader>
					<div>
						<div data-testid="replacement-confirmation-order-number">
							{ReplacmentConfirmationOrderNumber}
						</div>
						<div data-testid="replacement-confirmation-pending">
							&nbsp;
							{ReplacmentConfirmationOrderPending}
						</div>
					</div>
				</NewOrderNumberHeader>
				<OriginalOrderContainer data-testid="replacement-confirmation-original-order-number">
					{ReplacementConfirmationOriginalOrderNumber} {orderDetail?.orderNumber}
				</OriginalOrderContainer>
			</OrderInformationWrapper>

			<OrderDetailsContainer>
				<div>
					<span className="subtitle2">{OrderDateText}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-order-date"
					>
						{dateFormat(String(todayDate.current))}
					</span>
				</div>

				<div>
					<span className="subtitle2">{AccountNumberText}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-account-information"
					>
						{(userAccountInformation.length ? userAccountInformation : orderDetail?.accountNumber) ||
							UnavailableDataPlaceholderText}
					</span>
				</div>

				<div>
					<span className="subtitle2">{DesignerNumberText}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-designer-number"
					>
						{displayDesigner(orderDetail?.designer) ?? UnavailableDataPlaceholderText}
					</span>
				</div>

				<div>
					<span className="subtitle2">{OrderReplacementsJobName}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-job-name"
					>
						{replacementOrder?.jobName ?? orderDetail?.jobName ?? UnavailableDataPlaceholderText}
					</span>
				</div>

				<div>
					<span className="subtitle2">{OrderReplacementsPONumber}</span>
					<br />
					<span
						className="body2"
						data-testid="replacement-confirmation-po-number"
					>
						{replacementOrder?.poNumber ?? orderDetail?.poNumber ?? UnavailableDataPlaceholderText}
					</span>
				</div>
			</OrderDetailsContainer>
			<BillToBox
				billTo={billTo}
				orderType={OrderType.REPLACEMENT}
				isNoChargeOrder
				hasBorder
				dataTestIdPrefix="replacement"
			/>
			<ReplacementShipTo
				accounts={accounts}
				orderDetail={orderDetail}
			/>
			{cartItems.map((item: ReplacementCartItem) => (
				<div key={item.sku}>
					<SelectedReplacementReason item={item} />
				</div>
			))}
		</div>
	);
};

export default ReplacementConfirmationOrderShippingDetails;
