export const InitialFilterOptions = {
	accounts: [],
	designers: [],
	brands: [],
	status: [],
	types: [],
	hasStatusTriggered: false
};

export const InitialDraftFilterOptions = {
	accounts: [],
	designers: [],
	brands: [],
	status: [],
	types: [],
	hasStatusTriggered: false
};

export const InitialSearchDates = {
	fromDate: undefined,
	toDate: undefined
};

export enum CacheKeys {
	Filters = "_cabinet_filters",
	DraftFilters = "_cabinet_draft_filters",
	TemplateFilters = "_cabinet_template_filters",
	Partial = "_cabinet_partial",
	Search = "_cabinet_search",
	Dates = "_cabinet_dates",
	Orders = "_cabinet_orders"
}

export const InitialOrderResponse = {
	isPartialDataset: false,
	orders: []
};
