import styled from "@emotion/styled";

export const ShippingDetailsHeaderWrapper = styled.div(({ theme }) => ({
	...theme.project.typography.overline,
	color: theme.project.colors.fontColors.main,
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	"div:nth-of-type(1)": {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end"
	}
}));

export const OrderInformationWrapper = styled.div(({ theme }) => ({
	color: theme.project.colors.fontColors.main,
	"div:nth-of-type(2)": {
		...theme.project.typography.overline
	}
}));

export const OriginalOrderContainer = styled.div(({ theme }) => ({
	...theme.project.typography.body.body2,
	marginBottom: 16
}));

export const OrderDetailsContainer = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(5, 1fr)",
	marginBottom: 16,
	gap: 16,
	[theme.breakpoints.down("md")]: {
		display: "flex",
		flexDirection: "column",
		gap: 8,
		marginTop: 16,
		div: {
			display: "flex",
			gap: 5
		}
	}
}));
