import { Button, Divider } from "@mui/material";
import { NewOrderFlowButtons, NewOrdersContent, SummaryProductLine } from "components/NewOrders/NewOrders.styles";
import ViewPriceEstimatesLineItems from "./ViewPriceEstimatesLineItems";
import { useNavigate, useParams } from "react-router";
import {
	NewOrderBack,
	NewOrderContinue,
	NewOrderViewPriceEstimateEstimatedSubtotalHeader,
	NewOrderViewPriceEstimateTitle
} from "components/NewOrders/constants";
import EstimatedSubtotal from "components/NewOrders/ViewPriceEstimates/EstimatedSubtotal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import NewOrdersSubheader from "../NewOrdersSubheader/NewOrdersSubheader";
import { AddOnsProps } from "pages/OrderPages/BuildOrderPage/BuildOrderPage";
import {
	ConfigurationGroup,
	getConfigDisplay,
	getConfigurationGroupsByProductLine,
	getLineItemCount
} from "components/NewOrders/utils/NewOrderUtils.tsx";
import {
	AccountBillToCard,
	AccountBillToCardContainer,
	AccountBillToCardDetails,
	AccountBillToCardDetailsContainer,
	AccountBillToCardHeader,
	LineItemCount,
	PriceEstimateCard,
	PriceEstimateCardContent,
	PriceEstimateGroupedContent,
	TotalPriceContainer
} from "components/NewOrders/ViewPriceEstimates/viewPriceEstimates.styles.ts";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo.tsx";
import { brandCheck } from "utils/order.ts";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox.tsx";
import { OrderType } from "data/api/v1";
import { newOrderActions } from "features/reducers/newOrder/newOrder";

const ViewPriceEstimatesContent = ({ isAddOn }: AddOnsProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams();
	const importedCSVData = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const draftOrder = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);

	const handleBack = () => {
		dispatch(newOrderActions.clearDraftError());
		navigate(`/${isAddOn ? "add-ons" : "new-order"}/build-order${isAddOn ? "/" + params.orderId : ""}`);
	};

	const handleContinue = () => {
		dispatch(newOrderActions.clearDraftError());
		navigate(`/${isAddOn ? "add-ons" : "new-order"}/shipping-details${isAddOn ? "/" + params.orderId : ""}`);
	};

	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const groupedDisplayConfigs = useMemo<ConfigurationGroup[] | undefined>(
		() => getConfigurationGroupsByProductLine(validatedOrderResponse?.configurations),
		[validatedOrderResponse?.configurations]
	);

	const [searchValue, setSearchValue] = useState<string | null>("");
	const [searchOptions, setSearchOptions] = useState<string[]>([]);

	useEffect(() => {
		validatedOrderResponse?.configurations?.forEach((config) => {
			setSearchOptions((prev) =>
				// eslint-disable-next-line sonarjs/no-nested-functions
				prev.concat(config?.lineItems?.map((item) => `${item.sku} - ${item.description}`) ?? [])
			);
		});
	}, [validatedOrderResponse]);

	const handleLineItemSearch = (_event: SyntheticEvent, values: string | null) => {
		const foundSearch = searchOptions.find((option) =>
			option.toUpperCase().includes((values as string).toUpperCase())
		);
		setSearchValue(foundSearch ? values : "");
	};

	return (
		<NewOrdersContent>
			<NewOrdersSubheader
				draftOrder={draftOrder}
				title={NewOrderViewPriceEstimateTitle}
				dataTestId="new-order-price-estimate-label"
				hasSearch
				handleSearch={(event, values) => handleLineItemSearch(event, (values as string) ?? "")}
				searchOptions={searchOptions}
				searchValue={searchValue ?? ""}
				autoCompleteId="new-order-price-estimate-search"
				isAddOn={isAddOn}
			/>
			{groupedDisplayConfigs?.map((group, groupIndex) => (
				<PriceEstimateCard
					key={group.id}
					data-testid="price-estimate-line-items-subtotal-container"
				>
					<PriceEstimateCardContent>
						<AccountBillToCardContainer>
							<AccountBillToCard>
								<AccountBillToCardHeader>Account #</AccountBillToCardHeader>
								<AccountBillToCardDetailsContainer>
									<AccountBillToCardDetails>
										<p data-testid="view-price-estimate-selected-account-number">
											{group.accountNumber}
										</p>
										<p data-testid="view-price-estimate-selected-account-description">
											{group.accountName}
										</p>
									</AccountBillToCardDetails>
									<div>
										<StyledSummaryCardLogo
											data-testid="view-price-estimate-account-card-brand-logo"
											parentBrand={group?.globals?.brand?.code}
											src={
												brandCheck(group?.globals?.brand?.code)
													? `/assets/manufacture_logos/${group?.globals?.brand?.code}.png`
													: "/assets/tandem_logos/cwg_logo.png"
											}
											alt={
												brandCheck(group?.globals?.brand?.description)
													? (group?.globals?.brand?.description ?? "")
													: "CabinetworksGroup Logo"
											}
										/>
										{location.pathname == "/new-order/view-price-estimates" && (
											<SummaryProductLine fontVariant="large">
												{group?.globals?.productLine?.description}
											</SummaryProductLine>
										)}
									</div>
								</AccountBillToCardDetailsContainer>
							</AccountBillToCard>
							<BillToBox
								billTo={productLineAccountInfo[group?.globals?.productLine?.code ?? ""]
									?.find((accountInfo) => accountInfo.accountNumber === group.accountNumber)
									?.billTos?.find((billTo) => billTo.billToId === group.billToId)}
								orderType={OrderType.UNKNOWN}
								hasBorder
								containsSubtotal={false}
								dataTestIdPrefix="view-price-estimate-selected"
							/>
						</AccountBillToCardContainer>
						<PriceEstimateGroupedContent>
							<LineItemCount data-testid="new-order-build-table-count">
								{getLineItemCount(group)}
							</LineItemCount>
							{group?.configurations?.map((priceEstimatesConfiguration, configIndex) => {
								const foundSearch = priceEstimatesConfiguration?.lineItems?.find((item) =>
									`${item.sku} - ${item.description}`
										.toUpperCase()
										.includes((searchValue as string).toUpperCase())
								);
								if (priceEstimatesConfiguration && foundSearch) {
									return (
										<div key={JSON.stringify(priceEstimatesConfiguration)}>
											<ViewPriceEstimatesLineItems
												priceEstimateConfiguration={priceEstimatesConfiguration}
												searchValue={searchValue ?? ""}
											/>
											{getConfigDisplay(priceEstimatesConfiguration, importedCSVData)}
											{configIndex !==
												(groupedDisplayConfigs?.[groupIndex]?.configurations?.length ?? 0) -
													1 && <Divider sx={{ margin: "24px 0", padding: 0 }} />}
										</div>
									);
								}
								return null;
							})}
						</PriceEstimateGroupedContent>
						<TotalPriceContainer>
							<p>{NewOrderViewPriceEstimateEstimatedSubtotalHeader}</p>
							<p data-testid="view-price-estimate-line-item-accordionSubtotal">
								{group.configurations
									.reduce((accum: number, current) => {
										const price = "pricing" in current ? (current.pricing?.extendedPrice ?? 0) : 0;
										return accum + price;
									}, 0)
									?.toLocaleString("en-US", {
										style: "currency",
										currency: "USD"
									})}
							</p>
						</TotalPriceContainer>
					</PriceEstimateCardContent>
				</PriceEstimateCard>
			))}
			<EstimatedSubtotal subtotal={validatedOrderResponse?.pricing?.extendedPrice ?? 0} />
			<NewOrderFlowButtons>
				<Button
					data-testid="new-order-view-price-estimates-back-button"
					variant="text"
					onClick={handleBack}
				>
					{NewOrderBack}
				</Button>
				<Button
					data-testid="new-order-view-price-estimates-continue-button"
					onClick={handleContinue}
					data-id={`${isAddOn ? "add-ons" : "new-order"}-continue-to-shipping-button`}
					variant="contained"
				>
					{NewOrderContinue}
				</Button>
			</NewOrderFlowButtons>
		</NewOrdersContent>
	);
};

export default ViewPriceEstimatesContent;
