import { Box } from "@mui/material";
import styles from "pages/replacements-page-styles.module.css";
import IndividualShoppingCartItem from "./IndividualShoppingCartItem";
import { ReplacementCartItem, cartActions } from "features/reducers/replacementOrder/cart.ts";
import { useDispatch } from "react-redux";
import { EditText } from "constants/text";
import { useLocation } from "react-router";
import { LinkButton } from "components/Common/Link";

interface SelectedShoppingCartItemsProps {
	item: ReplacementCartItem;
	edit: () => void;
}

const SelectedShoppingCartItems = ({ item, edit }: SelectedShoppingCartItemsProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const originalItemSelected = item.wholeCabinetQuantity > 0;
	const isReview = location.pathname.includes("review");

	const handleOnclick = (id: string) => {
		edit();
		dispatch(cartActions.startEdit(id));
	};

	return (
		<Box
			data-testid="shopping-cart-items"
			className={styles.shoppingCartItemsWrapper}
		>
			<div className={`${styles.shoppingCartItemsHeader} ${"body1"}`}>
				<div className={styles.shoppingCartSubHeader}>
					<div>
						{item.lineItemNumber}
						<span className={styles.itemSku}>{item.sku}</span>
					</div>

					{!isReview && (
						<div className={styles.editTextContainer}>
							<LinkButton
								data-testid="edit-item"
								onClick={() => handleOnclick(item.id)}
							>
								{EditText}
							</LinkButton>
						</div>
					)}
				</div>

				<span className={styles.itemDescription}>{item.description}</span>
			</div>

			{originalItemSelected && (
				<IndividualShoppingCartItem
					item={item}
					sku={item.sku}
					description={item.description}
				/>
			)}

			{item.replaceableParts?.map(
				(part) =>
					part.partQuantity > 0 && (
						<IndividualShoppingCartItem
							key={part.id}
							description={part.description}
							partId={part.id}
							partSku={part.partSku}
							sku={item.sku}
							partQuantity={part.partQuantity}
						/>
					)
			)}
		</Box>
	);
};

export default SelectedShoppingCartItems;
