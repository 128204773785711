import { ErrorBannerStyles } from "./ErrorBanner.styles.ts";
import { forwardRef, ReactNode } from "react";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

export interface ErrorBannerProps {
	errorMessaging: ReactNode;
	isLoading?: boolean;
}
const ErrorBanner = forwardRef<HTMLDivElement, ErrorBannerProps>(
	({ isLoading, errorMessaging }: ErrorBannerProps, ref) => {
		return (
			<ErrorBannerStyles
				ref={ref}
				isLoading={isLoading}
				data-testid="api-error-banner-header"
			>
				<ErrorOutline />
				{errorMessaging}
			</ErrorBannerStyles>
		);
	}
);

ErrorBanner.displayName = "ErrorBanner";
export default ErrorBanner;
