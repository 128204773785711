import { MouseEvent, useEffect, useMemo, useState } from "react";
import { GlobalAttributeFields } from "./forms/schema";
import { useFormContext } from "react-hook-form";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import StepFour from "./components/StepFour";
import { useDispatch, useSelector } from "react-redux";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { Button, DialogContent } from "@mui/material";
import AddGlobalAttributesDialog from "../AddGlobalAttributesDialog";
import { AddGlobalAttributesGroupButton, NAOptionText, NewOrderUpdateGroup } from "../constants";
import { AddGlobalsButton } from "./addGlobalAttributes.styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { RootState } from "stores/application.store";
import { AddItemText } from "constants/text";
import { useNavigate, useParams } from "react-router";
import { models } from "types/api/viewModels.ts";
import { v4 as uuidv4 } from "uuid";
import { useLazyGetFinishDataQuery } from "features/api/globalsApi.ts";
import { NewOrderModalTracking, OrderType } from "data/api/v1";

const stepKeys = {
	stepOneUpdate: ["doorStyle"],
	stepOneAdd: ["productLine", "doorStyle"],
	stepTwo: ["shape", "profile", "hinge", "caseColor", "species", "finish"],
	stepThree: ["distressing", "accentColor", "accentApplication", "construction", "packaging"],
	stepFour: ["accountNumber", "billToNumber"]
} as const;

interface AddGlobalAttributesProps {
	isUpdate?: boolean;
	showAddItem?: boolean;
	isAddOn?: boolean;
	isLandingPage?: boolean;
	handleConfigurationUpdate?: (configuration: models["PendingOrderConfigurationViewModel"]) => void;
	setConfigurationToUpdate?: () => void;
}

const AddGlobalAttributes = ({
	isUpdate,
	showAddItem,
	isAddOn = false,
	isLandingPage,
	handleConfigurationUpdate,
	setConfigurationToUpdate
}: AddGlobalAttributesProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [step, setStep] = useState(0);
	const { resetField, trigger, watch, reset } = useFormContext<GlobalAttributeFields>();
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [getFinishData, { data: finishData }] = useLazyGetFinishDataQuery();
	const draft = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);

	const steps = useMemo(
		() => [
			{
				title: "1 Step",
				description: "1 Step",
				content: <StepOne isUpdate={isUpdate} />
			},
			{
				title: "2 Step",
				description: "2 Step",
				content: <StepTwo isUpdate={isUpdate} />
			},
			{
				title: "3 Step",
				description: "3 Step",
				content: <StepThree isUpdate={isUpdate} />
			},
			{
				title: "4 Step",
				description: "4 Step",
				content: (
					<StepFour
						isAddOn={isAddOn}
						isUpdate={isUpdate}
					/>
				)
			}
		],
		[isAddOn, isUpdate]
	);

	const maxSteps = 4;
	const currentStep = steps[step];

	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedFinish = watch("finish");
	const isFinishDataEmpty =
		finishData?.distressings?.length === 0 &&
		finishData?.accentColors?.length === 0 &&
		finishData?.accentApplications?.length === 0 &&
		finishData?.packagings?.length === 0 &&
		finishData?.constructions?.length === 0;

	let pageProps: {
		pathStub: string;
		buttonVariant: "contained" | "outlined";
		buttonWidth: "100%" | "max-content";
	} = {
		pathStub: isAddOn ? "add-ons" : "new-order",
		buttonVariant: isAddOn ? "contained" : "outlined",
		buttonWidth: isAddOn ? "100%" : "max-content"
	};

	useEffect(() => {
		if (selectedProductLine) {
			const fields = ["doorStyle", ...stepKeys.stepTwo, ...stepKeys.stepThree, ...stepKeys.stepFour] as const;

			fields.forEach((field) => resetField(field, { defaultValue: null as any }));
		}
	}, [dispatch, resetField, selectedProductLine]);

	useEffect(() => {
		if (selectedDoorStyle) {
			const fields = [...stepKeys.stepTwo, ...stepKeys.stepThree] as const;
			fields.forEach((field) => resetField(field, { defaultValue: null as any }));
		}
	}, [resetField, selectedDoorStyle]);

	useEffect(() => {
		if (selectedFinish) {
			if (selectedFinish.id) {
				getFinishData(selectedFinish.id);
			}
			const fields = [...stepKeys.stepThree] as const;
			fields.forEach((field) => resetField(field, { defaultValue: null as any }));
		}
	}, [resetField, selectedFinish, getFinishData]);

	const onStepperForward = async (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		let isValid = false;

		if (step === 0) {
			isValid = await trigger(isUpdate ? stepKeys.stepOneUpdate : stepKeys.stepOneAdd);
		} else if (step === 1) {
			isValid = await trigger(stepKeys.stepTwo);
		} else if (step === 2) {
			isValid = await trigger(stepKeys.stepThree);
		}

		if (isValid) {
			if (step === 1 && isFinishDataEmpty) {
				setStep((prevActiveStep) => prevActiveStep + 2);
			} else {
				setStep((prevActiveStep) => prevActiveStep + 1);
			}
		}
	};

	const onStepperBack = (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		if (step === 3 && isFinishDataEmpty) {
			setStep((prevActiveStep) => prevActiveStep - 2);
		} else {
			setStep((prevActiveStep) => prevActiveStep - 1);
		}
	};

	const modalState = useSelector((state: RootState) => state.newOrder.modalStatus);

	const handleAddAttributes = () => {
		reset();
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.MANUAL_ORDER_ENTRY));
		setIsOpen(true);
	};

	const closeAndClear = () => {
		reset();
		setStep(0);
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
		setIsOpen(false);
	};

	const startUpdateAttributes = () => {
		reset();
		if (setConfigurationToUpdate) {
			setConfigurationToUpdate();
		}
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.MANUAL_ORDER_ENTRY));
		setIsOpen(true);
	};

	const onSave = (values: GlobalAttributeFields) => {
		if (!isUpdate) {
			const newConfiguration: models["PendingOrderConfigurationViewModel"] = {
				globals: {
					construction: {
						code: values.construction?.code,
						description: values.construction?.description,
						id: values.construction?.id
					},
					finish: {
						code: values.finish?.code,
						description: values.finish?.description,
						id: values.finish?.id
					},
					packaging: {
						code: values.packaging?.code,
						description: values.packaging?.description,
						id: values.packaging?.id
					},
					shape: {
						code: values.shape?.code,
						description: values.shape?.description,
						id: values.shape?.id
					},
					species: {
						code: values.species?.code,
						description: values.species?.description,
						id: values.species?.id
					},
					hingeType: {
						code: values.hinge?.code,
						description: values.hinge?.description,
						id: values.hinge?.id
					},
					profile: {
						code: values.profile?.code,
						description: values.profile?.description,
						id: values.profile?.id
					},
					case: {
						code: values.caseColor?.code,
						description: values.caseColor?.description,
						id: values.caseColor?.id
					},
					distressing: {
						code: values.distressing?.code,
						description:
							values.distressing?.description !== NAOptionText ? values.distressing?.description : null,
						id: values.distressing?.id
					},
					accentColor: {
						code: values.accentColor?.code,
						description:
							values.accentColor?.description !== NAOptionText ? values.accentColor?.description : null,
						id: values.accentColor?.id
					},
					accentApplication: {
						code: values.accentApplication?.code,
						description:
							values.accentApplication?.description !== NAOptionText
								? values.accentApplication?.description
								: null,
						id: values.accentApplication?.id
					},
					style: {
						code: values.doorStyle?.code,
						description: values.doorStyle?.description,
						id: values.doorStyle?.id
					},
					productLine: {
						code: values.productLine?.code,
						description: values.productLine?.description,
						id: values.productLine?.id
					},
					brand: {
						code: values.productLine?.parentBrand
					}
				},
				configurationId: uuidv4(),
				accountId: values.accountNumber?.accountId,
				accountName: values.accountNumber?.accountName,
				accountNumber: values.accountNumber?.accountNumber,
				billToId: values.billToNumber?.billToId ?? null,
				lineItems: []
			};
			dispatch(newOrderActions.addSingleConfigToOrder(newConfiguration));
			if (isAddOn) {
				dispatch(newOrderActions.overwriteDraftOrder({ ...draft, originalOrderId: params.orderId }));
				dispatch(newOrderActions.updateOrderType(OrderType.ADDON));
				navigate(`/add-ons/build-order/${params.orderId}`);
			} else if (showAddItem) {
				dispatch(newOrderActions.updateOrderType(OrderType.ORIGINAL));
				navigate("/new-order/build-order");
			}
		} else if (handleConfigurationUpdate && updatedGlobalAttributes) {
			handleConfigurationUpdate(updatedGlobalAttributes);
		}
		closeAndClear();
	};

	return (
		<>
			{isUpdate ? (
				<Button
					variant="outlined"
					data-testid="update-global-attributes"
					onClick={startUpdateAttributes}
					startIcon={<EditIcon />}
					sx={{ alignItems: "center" }}
					data-id={`${pageProps.pathStub}-build-order-edit-global-attributes-button`}
				>
					{NewOrderUpdateGroup}
				</Button>
			) : (
				<>
					{showAddItem ? (
						<Button
							onClick={handleAddAttributes}
							variant={pageProps.buttonVariant}
							data-id={`${pageProps.pathStub}-landing-page-add-item-button`}
							startIcon={
								<AddIcon
									fontSize="small"
									sx={{ marginBottom: "2px" }}
								/>
							}
							sx={{
								display: "flex",
								alignItems: "center",
								height: "fit-content",
								width: pageProps.buttonWidth,
								marginLeft: "auto"
							}}
						>
							{AddItemText}
						</Button>
					) : (
						<AddGlobalsButton
							variant="text"
							onClick={handleAddAttributes}
							data-testid="add-global-attribute-button"
							data-id={`${pageProps.pathStub}-build-order-add-global-attributes-button`}
						>
							<AddIcon />
							{AddGlobalAttributesGroupButton}
						</AddGlobalsButton>
					)}
				</>
			)}
			<AddGlobalAttributesDialog
				isUpdate={isUpdate ?? false}
				dialogState={isLandingPage ? modalState === NewOrderModalTracking.MANUAL_ORDER_ENTRY : isOpen}
				handleDialogClose={closeAndClear}
				handleStepperBack={onStepperBack}
				handleStepperForward={onStepperForward}
				step={step}
				maxSteps={maxSteps}
				onSave={onSave}
			>
				<DialogContent>{currentStep?.content}</DialogContent>
			</AddGlobalAttributesDialog>
		</>
	);
};

export default AddGlobalAttributes;
