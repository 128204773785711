import { AddItemText, CreateYourItemSubtitleText, ManualOrderEntryText } from "constants/text";
import { ManualOrderEntryBox } from "./ManualOrderEntry.styles";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { NewOrderModalTracking } from "data/api/v1";

const ManualOrderEntry = () => {
	const dispatch = useDispatch();
	return (
		<ManualOrderEntryBox>
			<h6>{ManualOrderEntryText}</h6>
			<span>{CreateYourItemSubtitleText}</span>
			<Button
				onClick={() => {
					dispatch(newOrderActions.setModalState(NewOrderModalTracking.MANUAL_ORDER_ENTRY));
				}}
				variant="outlined"
				data-id="new-order-landing-page-add-item-button"
				data-testid="new-order-landing-page-add-item-button"
				startIcon={<AddIcon fontSize="small" />}
			>
				{AddItemText}
			</Button>
		</ManualOrderEntryBox>
	);
};

export default ManualOrderEntry;
