import acceptedReplacementFileUploadTypes from "constants/acceptedReplacementFileUploadTypes";
import { ReplacementFile } from "features/reducers/replacementOrder/cart.ts";
import { format } from "date-fns";

export const getFileNameBasedOnCurrentDateTime = (filePrefix: string) => {
	const localDate = new Date();

	return `${filePrefix} ${format(localDate, "yyyy-MM-dd 'at' hh_mm_ss a")}`;
};

export function formatBytes(bytes: number, decimals = 1) {
	if (!+bytes || bytes < 0) return "0 Bytes";

	const bytesPerKB = 1024;
	const decimalPlaces = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const exponent = Math.floor(Math.log(bytes) / Math.log(bytesPerKB));
	return `${parseFloat((bytes / Math.pow(bytesPerKB, exponent)).toFixed(decimalPlaces))} ${sizes[exponent]}`;
}

export function getFileType(fileName: string): string {
	const fileTypeRegex = /\.([0-9a-z]+)(?:[?#]|$)/i;
	const match = fileTypeRegex.exec(fileName)?.[1] ?? "Unknown";
	return match;
}

export function isValidFile(file: File, uploadedFiles: ReplacementFile[] = []): boolean {
	// Check if the file is already uploaded
	if (
		uploadedFiles.length > 0 &&
		uploadedFiles.find((selectedFile: ReplacementFile) => selectedFile.name === file.name)
	) {
		return false;
	}

	// Check if the file type is allowed
	const fileType = acceptedReplacementFileUploadTypes.find((type) => type === file.type);
	if (!fileType) {
		return false;
	}

	// Check if the file size is 10MB or below
	const maxSizeInBytes: number = 10 * 1024 * 1024;

	return file.size <= maxSizeInBytes;
}

export function isValidCSVFile(file: File, uploadedFiles: File[] = []): boolean {
	// Check if the file is already uploaded
	if (uploadedFiles.length > 0 && uploadedFiles.find((selectedFile: File) => selectedFile.name === file.name)) {
		return false;
	}

	// Check if the file type is allowed
	return file.type === "text/csv";
}

export function sanitizeBlobName(blobName: string): string {
	// matches any character that is not a letter, number, hyphen, underscore, or period.
	return blobName.replace(/[^a-zA-Z0-9\-_.]/g, "_");
}
