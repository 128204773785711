import styled from "@emotion/styled";

export const BuildOrderOptionsDialogWrapper = styled.div({
	padding: "16px 24px 24px"
});

export const BuildOrderOptionsDialogHeader = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	marginBottom: "16px",
	h6: {
		margin: 0
	},
	button: {
		padding: "4px"
	}
});

export const BuildOrderOptionsDialogContent = styled.div(({ theme }) => ({
	display: "flex",
	gap: 24,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));
