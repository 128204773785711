import {
	AccountNumberText,
	DesignerNumberText,
	DoorStyleText,
	FinishText,
	JobNameText,
	OrderNumberText,
	OriginalOrderText,
	PONumberText,
	ProductLineText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { UserAccountViewModel } from "data/api/v1";
import { useEffect, useState } from "react";
import { displayDesigner } from "utils/order";
import {
	PlaceOrderOriginalOrderDetailsDetails,
	PlaceOrderOriginalOrderDetailsWrapper
} from "./PlaceOrderOriginalOrderDetails.styles";
import { useGetUserDetailsQuery } from "features/api/userApi";
import { useLocation } from "react-router";
import { models } from "types/api/viewModels";

export interface PlaceOrderOriginalOrderDetailsProps {
	orderDetail: models["OrderDetailViewModel"] | null;
	isAddOnLandingPage?: boolean;
}

const PlaceOrderOriginalOrderDetails = ({
	orderDetail,
	isAddOnLandingPage = false
}: PlaceOrderOriginalOrderDetailsProps) => {
	const { data: userDetails } = useGetUserDetailsQuery();
	const [userAccountInformation, setUserAccountInformation] = useState("");
	const userAccount: UserAccountViewModel[] | undefined = userDetails?.user?.accounts?.map(
		(account: UserAccountViewModel) => account
	);
	const location = useLocation();
	const isReplacements = location.pathname.includes("replacements/");

	useEffect(() => {
		userAccount?.forEach(
			(account: UserAccountViewModel) =>
				account.number === orderDetail?.accountNumber &&
				setUserAccountInformation(
					`${account.number}` + (account.description ? ` - ${account.description}` : "")
				)
		);
	});

	const orderDetails = [
		{
			title: ProductLineText,
			value: orderDetail?.brand?.replaceAll("_", " "),
			dataTestId: "replacements-header-productLine"
		},
		{
			title: JobNameText,
			value: orderDetail?.jobName,
			dataTestId: "replacements-header-jobName"
		},
		{
			title: PONumberText,
			value: orderDetail?.poNumber,
			dataTestId: "replacements-header-poNumber"
		},
		{
			title: OrderNumberText,
			value: orderDetail?.orderNumber,
			dataTestId: "replacements-header-orderNumber"
		},
		{
			title: AccountNumberText,
			value: userAccountInformation.length ? userAccountInformation : orderDetail?.accountNumber,
			dataTestId: "replacements-header-accountNumber"
		},
		{
			title: DesignerNumberText,
			value: displayDesigner(orderDetail?.designer),
			dataTestId: "replacements-header-designerNumber"
		},
		{
			title: DoorStyleText,
			value: orderDetail?.doorStyle,
			dataTestId: "replacements-header-doorStyle"
		},
		{
			title: FinishText,
			value: orderDetail?.finish,
			dataTestId: "replacements-header-finish"
		}
	];

	return (
		<PlaceOrderOriginalOrderDetailsWrapper
			isAddOnLandingPage={isAddOnLandingPage || isReplacements}
			data-testid="place-order-original-order-details"
		>
			{(isAddOnLandingPage || isReplacements) && <span>{OriginalOrderText.toUpperCase()}</span>}
			<PlaceOrderOriginalOrderDetailsDetails isAddOnLandingPage={isAddOnLandingPage || isReplacements}>
				{orderDetails.map((detail) => (
					<div key={detail.title}>
						<span>{detail.title}</span>
						<span data-testid={detail.dataTestId}>{detail.value ?? UnavailableDataPlaceholderText}</span>
					</div>
				))}
			</PlaceOrderOriginalOrderDetailsDetails>
		</PlaceOrderOriginalOrderDetailsWrapper>
	);
};

export default PlaceOrderOriginalOrderDetails;
