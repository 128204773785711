import { createContext } from "react";
import { models } from "types/api/viewModels";

interface ConfigurationsContextType {
	configurationsData: models["ConfigurationViewModelApiResponse"]["data"];
	isBannerVisible?: boolean;
}

const ConfigurationsContext = createContext<ConfigurationsContextType>({ configurationsData: {} });

export default ConfigurationsContext;
