import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetByIdQuery } from "features/api/orderApi.ts";
import { Grid } from "@mui/material";
import OrderDetailsLineItemGrid, { LineItemGridProps } from "components/OrderDetailsLineItem/OrderDetailsLineItemGrid";
import styles from "../replacements-page-styles.module.css";
import ReplacementsHeader from "components/Replacements/ReplacementsHeader";
import { RootState } from "stores/application.store";
import { OrderLineItemViewModel } from "data/api/v1";
import { useDispatch, useSelector } from "react-redux";
import { submittingReplacementOrderActions } from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import { LineItemsInOrderText, SearchItemText } from "constants/text";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import { useGetAccountsByIdQuery } from "features/api/accountApi.ts";
import { SelectPartsLineItemWrapper } from "components/Replacements/ReplacementHeaderText.styles";

interface PartSelectionHeaderProps {
	searchOptions: Array<string>;
	lineItemsCount: number;
	searchValue: string;
	handleSearch: (e: SyntheticEvent, v: string | null) => void;
}

const PartSelectionHeader = ({
	searchOptions = [],
	lineItemsCount = 0,
	searchValue,
	handleSearch
}: PartSelectionHeaderProps) => {
	return (
		<div className={styles.partSelectionHeader}>
			<h4>Select Part(s)</h4>
			<Grid
				container
				className={styles.searchGridContainer}
			>
				<Grid
					item
					xl={4}
					lg={7}
					xs={12}
				>
					<SelectPartsLineItemWrapper data-testid="orderDetails-lineItem-count">
						{lineItemsCount} {LineItemsInOrderText}
					</SelectPartsLineItemWrapper>
				</Grid>
				<Grid
					item
					xl={4}
					lg={5}
					xs={12}
				>
					<SuggestedSearch
						ariaLabel="search-replacements"
						dataTestId="replacements-lineItem-search"
						dropDownOpenOnChar={3}
						placeholder={SearchItemText}
						handleSearch={handleSearch}
						id="replacements-search-line-items"
						searchOptions={searchOptions}
						searchValue={searchValue}
						size="small"
					/>
				</Grid>
			</Grid>
		</div>
	);
};

const Replacements = () => {
	const params = useParams();
	const { data } = useGetByIdQuery(params?.orderId || "");
	const [filteredLineItems, setFilteredLineItems] = useState<Array<OrderLineItemViewModel>>(data?.lineItems || []);
	const searchValueInitialState = "";
	const [searchValue, setSearchValue] = useState<string>(searchValueInitialState);
	const cartSelections = useSelector((state: RootState) => state.cart.replacements);
	const { error, data: accounts } = useGetAccountsByIdQuery(params?.orderId || "");
	const navigateTo = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (error && "status" in error) {
			if (error.status === 403) {
				navigateTo("/unapproved/account");
			}

			if (error.status === 404) {
				navigateTo("/ordernotfound");
			}
		}

		if (accounts?.[0] && (accounts?.[0]?.billTos?.length ?? 0) > 0) {
			const findIsSelectedAddresses = accounts?.[0]?.billTos?.find((address) => address.isSelected);
			dispatch(submittingReplacementOrderActions.setSelectedBillToAddress(findIsSelectedAddresses));
		}
	}, [error, navigateTo, accounts, dispatch]);

	const handleSearch = (_event: SyntheticEvent, values: string | null) => {
		if (values) {
			setSearchValue(values);
			const foundSearch = data?.lineItems?.find((item: any) =>
				`${item.sku.toUpperCase()} ${item.description.toUpperCase()}`.includes(values.toUpperCase() ?? "")
			);
			setFilteredLineItems(
				data?.lineItems?.filter(
					(lineItem: { sku: string; description: string }) =>
						`${lineItem.sku.toUpperCase()} ${lineItem.description.toUpperCase()}`.includes(
							values.toUpperCase() ?? ""
						) || !foundSearch
				)
			);
		} else {
			setSearchValue("");
			setFilteredLineItems(data?.lineItems);
		}
	};

	const searchOptions = useMemo(() => {
		return data?.lineItems.map((x: { sku: string; description: string }) => x?.sku + " " + x?.description);
	}, [data?.lineItems]);

	useEffect(() => setFilteredLineItems(data?.lineItems), [data]);

	return (
		<div>
			<div className={styles.replacementsHeaderContainer}>
				<ReplacementsHeader orderDetail={data} />
			</div>

			<div
				className={styles.orderListContainer}
				data-testid="replacement-lineitem-page"
			>
				<PartSelectionHeader
					lineItemsCount={data?.lineItems?.length || 0}
					searchOptions={searchOptions}
					searchValue={searchValue}
					handleSearch={handleSearch}
				/>

				{/* Order Replacements Button */}
				{filteredLineItems?.map((item: OrderLineItemViewModel) => (
					<OrderDetailsLineItemGrid
						key={item.orderLineItemId}
						isPartAdded={!!cartSelections.find((cartItem: { id: string }) => item.id === cartItem.id)}
						{...(item as LineItemGridProps)}
						variant="replacement"
					/>
				))}
			</div>
		</div>
	);
};

export default Replacements;
