import styled from "@emotion/styled";

export const ManualOrderEntryBox = styled.div(({ theme }) => ({
	flex: 1,
	border: "1px solid " + theme.project.colors.allColors.hex.grayscale.gray300,
	padding: "16px",
	h6: {
		margin: "0 0 8px"
	},
	span: {
		...theme.project.typography.body.body1
	},
	button: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		marginTop: "36px"
	}
}));
