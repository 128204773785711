import { Button, ButtonProps } from "@mui/material";
import { NewOrderLandingPageBoxWrapper } from "./NewOrderLandingPageBox.styles";

export interface NewOrderLandingPageBoxProps {
	title: string;
	text: string;
	imgSrc: string;
	buttonText: string;
	buttonVariant: ButtonProps["variant"];
	buttonDataTestId: string;
	onClick: () => void;
}

const NewOrderLandingPageBox = ({
	title,
	text,
	imgSrc,
	buttonText,
	buttonVariant,
	buttonDataTestId,
	onClick
}: NewOrderLandingPageBoxProps) => {
	return (
		<NewOrderLandingPageBoxWrapper>
			<div>
				<img
					src={imgSrc}
					alt={imgSrc}
				/>
				<div>
					<h6>{title}</h6>
					<span>{text}</span>
				</div>
			</div>
			<Button
				variant={buttonVariant}
				onClick={onClick}
				data-testid={buttonDataTestId}
			>
				{buttonText}
			</Button>
		</NewOrderLandingPageBoxWrapper>
	);
};

export default NewOrderLandingPageBox;
