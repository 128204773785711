import { useState, Dispatch, SetStateAction, KeyboardEvent, useEffect } from "react";
import { guardAgainstInvalidDatePickerRange } from "./helpers/dates";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Card, ClickAwayListener, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePickerForm } from "./components";
import styles from "containers/Orders/order-styles.module.css";
import { DateRange } from "pages/OrderPages/OrderListPage/OrderListPage";
import { formatDateToMMDDYYYY } from "utils/date";
import { DatePickerWrapper } from "components/DatePicker/datePicker.styles";
import CloseIcon from "@mui/icons-material/Close";
import {
	OrderDateRangeLabel,
	WithinLastSixMonthsLabelText,
	WithinLastThreeMonthsLabelText,
	WithinLastTwelveMonthsLabelText
} from "constants/text";

export interface Props {
	searchDates: DateRange;
	setSearchDates: Dispatch<SetStateAction<DateRange>>;
	resetSearch: () => void;
	invokeSearch: () => void;
}

const determineRangeLabel = (fromDate: Date, toDate: Date) => {
	const today = new Date();

	const threeMonthsAgo = new Date(today);
	threeMonthsAgo.setMonth(today.getMonth() - 3);

	const sixMonthsAgo = new Date(today);
	sixMonthsAgo.setMonth(today.getMonth() - 6);

	const twelveMonthsAgo = new Date(today);
	twelveMonthsAgo.setFullYear(today.getFullYear() - 1);

	const isFullYearRange = (year: number) =>
		fromDate.getFullYear() === year &&
		fromDate.getMonth() === 0 &&
		fromDate.getDate() === 1 &&
		toDate.getFullYear() === year &&
		toDate.getMonth() === 11 &&
		toDate.getDate() === 31;

	switch (true) {
		case fromDate.toDateString() === threeMonthsAgo.toDateString() &&
			toDate.toDateString() === today.toDateString():
			return WithinLastThreeMonthsLabelText;
		case fromDate.toDateString() === sixMonthsAgo.toDateString() && toDate.toDateString() === today.toDateString():
			return WithinLastSixMonthsLabelText;
		case fromDate.toDateString() === twelveMonthsAgo.toDateString() &&
			toDate.toDateString() === today.toDateString():
			return WithinLastTwelveMonthsLabelText;
		case fromDate.getFullYear() === today.getFullYear() &&
			fromDate.getMonth() === 0 &&
			fromDate.getDate() === 1 &&
			toDate.toDateString() === today.toDateString():
			return `${today.getFullYear()}`;
		case isFullYearRange(today.getFullYear() - 1):
			return `${today.getFullYear() - 1}`;
		case isFullYearRange(today.getFullYear() - 2):
			return `${today.getFullYear() - 2}`;
		case isFullYearRange(today.getFullYear() - 3):
			return `${today.getFullYear() - 3}`;
		default:
			return "custom";
	}
};

const DatePicker = ({ searchDates, setSearchDates, resetSearch, invokeSearch }: Props) => {
	const [showPickerCard, setShowPickerCard] = useState(false);
	const [rangeDisplay, setRangeDisplay] = useState<string>("");
	const [shouldInvokeSearch, setShouldInvokeSearch] = useState(false);

	const daysOffset = 90;
	const maxYearsAgoOffset = 20;

	const onSetDateRange = (from: Date | null, to: Date | null, label?: string) => {
		if (label && from && to) {
			setRangeDisplay(label);
			setSearchDates({ fromDate: from, toDate: to });
		} else if (from && to) {
			const [cleanFrom, cleanTo] = guardAgainstInvalidDatePickerRange(from, to, maxYearsAgoOffset, daysOffset);
			setRangeDisplay(
				`${formatDateToMMDDYYYY(cleanFrom ?? new Date())} - ${formatDateToMMDDYYYY(cleanTo ?? new Date())}`
			);
			setSearchDates({ fromDate: cleanFrom, toDate: cleanTo });
		} else {
			setRangeDisplay("");
			setSearchDates({ fromDate: undefined, toDate: undefined });
		}

		setShouldInvokeSearch(true);
		setShowPickerCard(false);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key !== "Tab") {
			event.preventDefault();
		}
		if (event.key === "Enter" || event.key === " " || event.key === "ArrowDown") {
			setShowPickerCard(true);
		}
		if (event.key === "Escape") {
			setShowPickerCard(false);
		}
	};

	const handleClearDateRange = () => {
		setRangeDisplay("");
		setSearchDates({ fromDate: undefined, toDate: undefined });
		resetSearch();
		setShowPickerCard(false);
	};

	useEffect(() => {
		if (shouldInvokeSearch && searchDates.fromDate && searchDates.toDate) {
			invokeSearch();
			setShouldInvokeSearch(false);
		}
	}, [searchDates, shouldInvokeSearch, invokeSearch]);

	useEffect(() => {
		if (searchDates.fromDate && searchDates.toDate) {
			const label = determineRangeLabel(searchDates.fromDate, searchDates.toDate);
			setRangeDisplay(
				label === "custom"
					? `${formatDateToMMDDYYYY(searchDates.fromDate)} - ${formatDateToMMDDYYYY(searchDates.toDate)}`
					: label
			);
		}
	}, [searchDates]);

	return (
		<DatePickerWrapper
			widthValue="100%"
			role="search"
			data-testid="date-picker-range"
		>
			<div
				className={styles.datePickerRangeInputField}
				onClick={() => setShowPickerCard((prev) => !prev)}
				onKeyDown={handleKeyDown}
			>
				<TextField
					className="datePickerInputTypeDate"
					label={OrderDateRangeLabel}
					fullWidth
					type="text"
					variant="outlined"
					size="small"
					sx={{
						"& .MuiInputBase-input.Mui-disabled": {
							WebkitTextFillColor: "var(--text-primary)"
						},
						"& .MuiInputBase-input": {
							cursor: "pointer"
						},
						"& .MuiInputBase-root": {
							paddingRight: "6px"
						}
					}}
					value={rangeDisplay}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start">
								{rangeDisplay && (
									<CloseIcon
										data-testid="close-icon"
										cursor="pointer"
										onClick={(e) => {
											e.stopPropagation();
											handleClearDateRange();
										}}
									/>
								)}
								<DateRangeIcon data-id="date-picker-range-field" />
							</InputAdornment>
						)
					}}
					inputProps={{
						"data-testid": "date-picker-range-field",
						"data-id": "date-picker-range-field",
						readOnly: true
					}}
				/>
			</div>
			{showPickerCard && (
				<ClickAwayListener onClickAway={() => setShowPickerCard(false)}>
					<div
						data-testid="date-picker-form-card"
						style={{
							position: "absolute",
							zIndex: "100",
							width: "100%",
							minWidth: "300px"
						}}
					>
						<Card
							sx={{
								mt: "1rem",
								flexDirection: "column"
							}}
						>
							<DatePickerForm
								onSetDateRange={onSetDateRange}
								fromDate={searchDates.fromDate}
								toDate={searchDates.toDate}
								determineRangeLabel={determineRangeLabel}
							/>
						</Card>
					</div>
				</ClickAwayListener>
			)}
		</DatePickerWrapper>
	);
};

export default DatePicker;
