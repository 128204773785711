import { Typography } from "@mui/material";
import DatePickerCalendarDayButton from "./DatePickerCalendarDayButton";
import { isAfterDate, isBeforeDate } from "utils/date";

interface DatePickerCalendarDaysProps {
	year: number;
	month: number;
	day: number;
	selectedDate: Date;
	enableAfterDate?: Date;
	disabledAfterDate?: Date;
	onSelect: (date: Date) => void;
}

const DatePickerCalendarDays = ({
	year,
	month,
	day,
	onSelect,
	selectedDate,
	enableAfterDate,
	disabledAfterDate
}: DatePickerCalendarDaysProps) => {
	const daysInMonth = new Date(year, month + 1, 0).getDate();
	const firstDayOfMonth = new Date(year, month, 1).getDay();
	const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	const handleDayButtonClick = (day: number) => {
		const date = new Date(year, month, day);
		onSelect(date);
	};

	const renderWeekHeaders = () => {
		return daysOfWeek.map((day) => (
			<th
				style={{ padding: "0 0 1rem 0", color: "rgba(86, 87, 89, 0.38)" }}
				key={day}
			>
				<Typography variant="body1">{day.charAt(0)}</Typography>
			</th>
		));
	};

	const disabledDay = (day: number) => {
		const disabledPastDay = enableAfterDate && isBeforeDate(new Date(year, month, day), enableAfterDate);
		const disabledFutureDay = disabledAfterDate && isAfterDate(new Date(year, month, day), disabledAfterDate);

		return disabledPastDay || disabledFutureDay;
	};

	const renderDays = () => {
		const days = [];
		let dayCount = 1;

		for (let row = 0; row < 6; row++) {
			const cells = [];
			for (let column = 0; column < 7; column++) {
				if (row === 0 && column < firstDayOfMonth) {
					cells.push(<td key={`${row}-${column}`}>&nbsp;</td>);
				} else if (dayCount > daysInMonth) {
					break;
				} else {
					cells.push(
						<td
							align="center"
							key={`${row}-${column}`}
						>
							<DatePickerCalendarDayButton
								label={dayCount}
								isSelected={
									dayCount === day &&
									month === selectedDate.getMonth() &&
									year === selectedDate.getFullYear()
								}
								onClick={handleDayButtonClick}
								disabled={disabledDay(dayCount)}
							/>
						</td>
					);
					dayCount++;
				}
			}
			days.push(<tr key={row}>{cells}</tr>);
		}

		return days;
	};

	return (
		<div
			style={{
				width: "100%",
				display: "flex",
				justifyContent: "space-between"
			}}
		>
			<table style={{ width: "100%", padding: "0.5rem" }}>
				<thead>
					<tr>{renderWeekHeaders()}</tr>
				</thead>
				<tbody>{renderDays()}</tbody>
			</table>
		</div>
	);
};

export default DatePickerCalendarDays;
