import { ButtonBase } from "@mui/material";

interface DatePickerCalendarDayButtonProps {
	label: number;
	onClick: (day: number) => void;
	isSelected?: boolean;
	disabled?: boolean;
}

const DatePickerCalendarDayButton = ({ label, onClick, isSelected, disabled }: DatePickerCalendarDayButtonProps) => {
	const handleOnClick = (event: any) => {
		event.preventDefault();
		const day = Number(event.target.innerText);
		onClick(day);
	};

	return (
		<ButtonBase
			disabled={disabled}
			data-testid="date-picker-calendar-day-button"
			sx={{
				height: "30px",
				width: "30px",
				borderRadius: "50%",
				backgroundColor: isSelected ? "var(--cwg-blue-900)" : "white",
				// eslint-disable-next-line sonarjs/no-nested-conditional
				color: isSelected ? "white" : disabled ? "var(--text-disabled)" : "var(--text-primary)"
			}}
			onClick={handleOnClick}
		>
			{label}
		</ButtonBase>
	);
};

export default DatePickerCalendarDayButton;
