import OpenInNew from "@mui/icons-material/OpenInNew";
import { AlertTitle } from "@mui/material";
import { DesignerConnectionEnsureText, DesignerConnectionFormText } from "../constants";
import { DesignerFormAlertWrapper } from "./DesignerFormAlert.styles";

interface DesignerFormAlertProps {
	header: string;
	isConfirmation?: boolean;
}

const DesignerFormAlert = ({ header, isConfirmation }: DesignerFormAlertProps) => {
	return (
		<DesignerFormAlertWrapper
			severity="info"
			isConfirmation={isConfirmation}
			data-testid="designer-form-alert-banner"
		>
			<AlertTitle>{header}</AlertTitle>
			<div>
				<span>{DesignerConnectionEnsureText}</span>
				<a
					href="https://cabinetworksgroup.bubbleapps.io/version-test/designerconnection"
					target="_blank"
					rel="noreferrer"
				>
					<span>{DesignerConnectionFormText}</span>
					<OpenInNew />
				</a>
			</div>
		</DesignerFormAlertWrapper>
	);
};

export default DesignerFormAlert;
