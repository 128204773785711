import { useRef, useState } from "react";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { RotatingArrowIcon, SaveDraftButton, SaveDraftMenu } from "./SaveDraft.styles";
import { SaveAsDraftText, SaveAsTemplateText } from "../constants";
import SaveDraftModal from "./SaveDraftModal";
import { CancelButtonText, SaveButtonText, SaveDraftButtonText } from "constants/text";
import { models } from "types/api/viewModels.ts";
import { MenuItem } from "@mui/material";

interface Props {
	draftOrder?: models["DraftOrderViewModel"];
	isAddOn?: boolean;
}

const SaveDraft = ({ draftOrder, isAddOn }: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isTemplate, setIsTemplate] = useState(false);

	const handleMenuOpen = () => {
		if (isAddOn) {
			setIsModalOpen(true);
		} else {
			setIsMenuOpen(true);
		}
	};

	const handleModalOpen = (isTemplate: boolean = false) => {
		setIsModalOpen(true);
		setIsTemplate(isTemplate);
		setIsMenuOpen(false);
	};

	const handleClose = () => {
		setIsMenuOpen(false);
		setIsModalOpen(false);
	};
	const anchorRef = useRef(null);

	return (
		<>
			<SaveDraftButton
				variant="text"
				onClick={handleMenuOpen}
				data-testid="save-draft-template-button"
				ref={anchorRef}
			>
				<SaveOutlined />
				{isAddOn ? SaveDraftButtonText : SaveButtonText}
				{!isAddOn && <RotatingArrowIcon open={isMenuOpen} />}
			</SaveDraftButton>
			<SaveDraftMenu
				open={isMenuOpen}
				anchorEl={anchorRef.current}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
				data-test-id="save-draft-menu"
			>
				<MenuItem
					onClick={() => handleModalOpen(false)}
					data-testid="save-draft-menu-item"
				>
					{SaveAsDraftText}
				</MenuItem>
				{!isAddOn && (
					<MenuItem
						onClick={() => handleModalOpen(true)}
						data-testid="save-template-menu-item"
					>
						{SaveAsTemplateText}
					</MenuItem>
				)}
			</SaveDraftMenu>
			<SaveDraftModal
				draftOrder={draftOrder}
				closeModal={handleClose}
				handleSecondaryButton={handleClose}
				saveDraftModalIsOpen={isModalOpen}
				firstButtonText={SaveButtonText}
				secondButtonText={CancelButtonText}
				isTemplate={isTemplate}
			/>
		</>
	);
};

export default SaveDraft;
