import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import {
	ETAText,
	ItemAffectedText,
	PartOnBackorderText,
	QuantityFullText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { models } from "types/api/viewModels";

interface AccordionGridProps {
	orderDetail: models["OrderDetailViewModel"] | null;
	parts?: any;
	sku?: string | null;
	itemDescription?: string | null;
	quantityBackordered?: number | null;
	partsQuantityBackordered?: number | null;
}

const OrderDetailsOnBackorderAccordionGrid = ({
	orderDetail,
	parts,
	sku,
	itemDescription,
	quantityBackordered,
	partsQuantityBackordered
}: AccordionGridProps) => {
	const eta = orderDetail?.eta;

	return (
		<TableContainer>
			{((partsQuantityBackordered !== undefined && partsQuantityBackordered !== 0) ||
				quantityBackordered !== 0) && (
				<Table
					sx={{ minWidth: 650, marginTop: 1, marginBottom: 2 }}
					aria-label="backorder-item-details-table"
					style={{ border: "1px solid var(--gray-400)" }}
				>
					<TableRow>
						<TableCell
							align="left"
							style={{ paddingTop: "5px", paddingBottom: ".4rem", width: "33%" }}
						>
							<span className="subtitle2">{PartOnBackorderText}</span>
						</TableCell>
						<TableCell
							align="left"
							style={{ paddingTop: "5px", paddingBottom: ".4rem", width: "33%" }}
						>
							<span className="subtitle2">{ItemAffectedText}</span>
						</TableCell>
						{(partsQuantityBackordered !== 0 || quantityBackordered !== 0) && (
							<TableCell
								align="left"
								style={{ paddingTop: "5px", paddingBottom: ".4rem", width: "33%" }}
							>
								<span className="subtitle2">{QuantityFullText}</span>
							</TableCell>
						)}
						{!partsQuantityBackordered && quantityBackordered === 0 && (
							<TableCell
								align="left"
								style={{ paddingTop: "5px", paddingBottom: ".4rem", width: "33%" }}
							>
								<span className="subtitle2">{ETAText}</span>
							</TableCell>
						)}
					</TableRow>

					<TableBody>
						{partsQuantityBackordered !== 0 && (
							<>
								{parts?.map((each: any, index: any) => (
									<TableRow key={index}>
										<TableCell
											component="th"
											scope="row"
										>
											<span className="subtitle2">
												{each.number ?? UnavailableDataPlaceholderText}
											</span>
											<br />
											<span className="body2">
												{each.description ?? UnavailableDataPlaceholderText}
											</span>
										</TableCell>
										<TableCell
											component="th"
											scope="row"
										>
											<span className="subtitle2">{sku}</span>
											<br />
											<span className="body2">
												{itemDescription ?? UnavailableDataPlaceholderText}
											</span>
										</TableCell>
										{each.quantity && (
											<TableCell
												component="th"
												scope="row"
											>
												<span className="body2">{each.quantity}</span>
											</TableCell>
										)}

										{!partsQuantityBackordered && (
											<TableCell
												component="th"
												scope="row"
											>
												<span className="body2">{eta ?? UnavailableDataPlaceholderText}</span>
											</TableCell>
										)}
									</TableRow>
								))}
							</>
						)}

						{quantityBackordered !== 0 && (
							<TableRow>
								<TableCell
									component="th"
									scope="row"
								>
									<span className="subtitle2">{sku ?? UnavailableDataPlaceholderText}</span>
									<br />
									<span className="body2">{itemDescription ?? UnavailableDataPlaceholderText}</span>
								</TableCell>
								<TableCell
									component="th"
									scope="row"
								>
									<span className="subtitle2">{sku ?? UnavailableDataPlaceholderText}</span>
									<br />
									<span className="body2">{itemDescription ?? UnavailableDataPlaceholderText}</span>
								</TableCell>
								<TableCell
									component="th"
									scope="row"
								>
									<span className="body2">
										{quantityBackordered ?? UnavailableDataPlaceholderText}
									</span>
								</TableCell>

								{quantityBackordered === 0 && (
									<TableCell
										component="th"
										scope="row"
									>
										<span className="body2">{eta ?? UnavailableDataPlaceholderText}</span>
									</TableCell>
								)}
							</TableRow>
						)}
					</TableBody>
				</Table>
			)}
		</TableContainer>
	);
};

export default OrderDetailsOnBackorderAccordionGrid;
