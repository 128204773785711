import { AddressText, ShipToTitle, ShippingMethodText } from "constants/text";
import { convertToTitleCase } from "utils/string";
import { BillToShipToBoxWrapper } from "../BillToShipToBox.styles";
import { models } from "types/api/viewModels";

interface ShipToBoxProps {
	orderDetail: models["OrderDetailViewModel"];
	dataTestIdPrefix: string;
}

const ShipToBox = ({ orderDetail, dataTestIdPrefix }: ShipToBoxProps) => {
	return (
		<BillToShipToBoxWrapper hasBorder>
			<h6>{ShipToTitle}</h6>
			<div>
				<div>
					<b>{AddressText}</b>
					<p data-testid={dataTestIdPrefix + "-shipToName"}>
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.name)}
					</p>
					<p data-testid={dataTestIdPrefix + "-shipToAddress"}>
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.line1)}
						{orderDetail?.shippingDetails?.shipToAddress?.line1 && <br />}
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.line2)}
						{orderDetail?.shippingDetails?.shipToAddress?.line2 && <br />}
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.line3)}
						{orderDetail?.shippingDetails?.shipToAddress?.line3 && <br />}
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.city)},{" "}
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.state)}{" "}
						{convertToTitleCase(orderDetail?.shippingDetails?.shipToAddress?.zip)}
					</p>
				</div>
				<div>
					<b data-testid={dataTestIdPrefix + "-shipViaName"}>{ShippingMethodText}</b>
					<p>{convertToTitleCase(orderDetail?.shippingDetails?.shipVia)}</p>
				</div>
			</div>
		</BillToShipToBoxWrapper>
	);
};

export default ShipToBox;
