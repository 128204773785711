// Added based current swagger as of 8.18.2023

import { isTestEnv } from "constants/environment";
import { GetProductLinesQueryParameters } from "types/api/globals/getProductLines.ts";
import { GetEmulationAccountsQueryParameters } from "types/api/accounts/getEmulationAccounts.ts";

export const baseURL = isTestEnv ? new URL("", window.location.origin).href : `${import.meta.env.VITE_APP_BASEURL}`;

const endpoints = {
	addresses: {
		getSuggestions: (address = ":address") => {
			return `${baseURL}/api/v1/addresses/suggestions/${address}`;
		},
		validateAddress: () => {
			return `${baseURL}/api/v1/addresses/validate`;
		}
	},
	accounts: {
		getAccounts: (productLineCode?: string | null) => {
			return `${baseURL}/api/v1/accounts?productLineCode=${productLineCode}`;
		},
		getBillToShipTos: (accountId: string, billToId: string) => {
			return `${baseURL}/api/v1/accounts/${accountId}/bill-tos/${billToId}/ship-tos`;
		},
		getBillToInfo: (accountId: string, billToId: string) => {
			return `${baseURL}/api/v1/accounts/${accountId}/bill-tos/${billToId}`;
		},
		getAccountsByOrderId: (orderId: string) => {
			return `${baseURL}/api/v1/accounts?orderId=${orderId}`;
		},
		getEmulationAccounts: (queryParams?: GetEmulationAccountsQueryParameters) => {
			const queryString = queryParams ? `?accountNumber=${queryParams.accountNumber}` : "";
			return `${baseURL}/api/v1/accounts/emulation${queryString}`;
		}
	},
	designers: {
		getDesigners: (accountId: string) => {
			return `${baseURL}/api/v1/accounts/${accountId}/designers`;
		}
	},
	globals: {
		getProductLines: (params?: GetProductLinesQueryParameters) => {
			const queryString =
				params && params?.accountId && params?.billToId
					? `?accountId=${params.accountId}&billToId=${params?.billToId}`
					: "";
			return `${baseURL}/api/v1/globals/product-lines${queryString}`;
		},
		getProductLine: (productLineId = ":productLineId") => {
			return `${baseURL}/api/v1/globals/product-lines/${productLineId}`;
		},
		getStyle: (styleId = ":styleId") => {
			return `${baseURL}/api/v1/globals/styles/${styleId}`;
		},
		getFinish: (finishId = ":finishId") => {
			return `${baseURL}/api/v1/globals/finishes/${finishId}`;
		}
	},
	images: {
		getUploadDataById: (tandemOrderId: string) => {
			return `${baseURL}/api/v1/images/${tandemOrderId}/upload`;
		}
	},
	orders: {
		getAllOrders: () => {
			return `${baseURL}/api/v1/orders`;
		},
		getOrdersById: (orderId: string) => {
			return `${baseURL}/api/v2/orders/${orderId}`;
		},
		getAccountsById: (orderId: string) => {
			return `${baseURL}/api/v1/orders/${orderId}/accounts`;
		},
		postReplacementOrder: () => {
			return `${baseURL}/api/v1/orders/replacements`;
		},
		getLinkedOrders: (orderId: string) => {
			return `${baseURL}/api/v1/orders/${orderId}/linked-orders`;
		},
		draftOrders: () => {
			return `${baseURL}/api/v1/orders/drafts`;
		},
		getTemplates: () => {
			return `${baseURL}/api/v1/orders/drafts?type=Template`;
		},
		draftById: (draftId: string = ":id") => {
			return `${baseURL}/api/v1/orders/drafts/${draftId}`;
		},
		templateById: (templateId: string = ":id") => {
			return `${baseURL}/api/v1/orders/drafts/${templateId}?type=Template`;
		}
	},
	users: {
		getUserDetails: () => {
			return `${baseURL}/api/v1/users`;
		},
		getUserEmulation: () => {
			return `${baseURL}/api/v1/users/emulation`;
		}
	},
	version: {
		getAPIVersion: () => {
			return `${baseURL}/api/version`;
		}
	},
	health: {
		getAPIHealth: () => {
			return `${baseURL}/health`;
		}
	},
	newOrder: {
		csvUpload: () => {
			return `${baseURL}/api/v1/orders/import`;
		},
		postNewOrder: () => `${baseURL}/api/v1/orders`,
		cancelOrder: (orderId: string | null = ":orderId") => `${baseURL}/api/v1/orders/${orderId}/cancel`,
		validateOrderRequest: () => {
			return `${baseURL}/api/v1/products/validate`;
		},
		getBillToShipTosNewOrders: () => {
			return `${baseURL}/api/v1/accounts/bill-tos/ship-tos`;
		},
		getProductsByProductLineCode: (productLineCode = "", productId?: string) => {
			const queryParams = new URLSearchParams();
			productLineCode && queryParams.append("productLineCode", productLineCode);
			productId && queryParams.append("productId", productId);
			const queryString = queryParams.toString() ? `?${queryParams.toString()}` : "";

			return `${baseURL}/api/v1/products${queryString}`;
		},
		getModifications: (productId = ":productId", productLineId?: string) => {
			const queryString = productLineId ? `?productLineId=${productLineId}` : "";
			return `${baseURL}/api/v1/products/${productId}/modifications${queryString}`;
		}
	},
	configurations: {
		getConfigurations: () => {
			return `${baseURL}/api/v1/configurations`;
		}
	}
};

export default endpoints;
