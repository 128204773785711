import styled from "@emotion/styled";
import DialogContent from "@mui/material/DialogContent";
import { paperClasses } from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";

export const StyledDialog = styled(Dialog)({
	[`.${paperClasses.root}`]: {
		width: 600
	}
});

export const MaintenanceOrImpersonationBar = styled.div(({ theme }) => ({
	position: "sticky",
	top: 0,
	zIndex: 1000
}));

export const ImpersonationBar = styled.div<{ isActive: boolean }>(({ isActive, theme }) => ({
	background: isActive
		? `linear-gradient(to left, ${theme.project.colors.backgroundColors.active}, ${theme.project.colors.backgroundColors.activeBorder})`
		: theme.project.colors.backgroundColors.disabled,
	display: "flex",
	gap: 16,
	padding: "8px 16px"
}));

export const EmulationForm = styled(DialogContent)({
	display: "flex",
	flexDirection: "column",
	gap: 16,
	overflow: "visible",
	"& > div:nth-of-type(4)": {
		display: "flex",
		gap: 16,
		justifyContent: "flex-end",
		padding: 0,
		button: {
			margin: 0
		}
	}
});

export const DividerText = styled.span(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	color: theme.project.colors.fontColors.main
}));

export const UserWrapper = styled.div(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	gap: 16,
	button: {
		color: theme.project.colors.fontColors.contrast,
		textDecorationColor: theme.project.colors.fontColors.contrast
	},
	[theme.breakpoints.down("md")]: {
		overflow: "hidden"
	}
}));

export const NowViewingText = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	alignItems: "flex-end",
	color: theme.project.colors.fontColors.contrast,
	display: "flex",
	height: 24,
	[theme.breakpoints.down("md")]: {
		display: "block",
		height: 20,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	}
}));

export const BoxOverlay = styled.div(({ theme }) => ({
	position: "fixed",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	zIndex: 999,
	pointerEvents: "none",
	border: `3px solid ${theme.project.colors.backgroundColors.activeBorder}`,
	borderImage: `linear-gradient(to left, ${theme.project.colors.backgroundColors.active}, ${theme.project.colors.backgroundColors.activeBorder}) 1`,
	boxShadow: `inset 0 4px 15px 6px ${theme.project.colors.backgroundColors.activeShadow}`
}));
