import {
	CloseSubMenuButton,
	MainNavigationIcon,
	MainNavigationLinks,
	NavigationContainer,
	NavigationItemButton,
	NavigationPlaceNewOrderButton,
	SubNavigationButton,
	SubNavigationLinks
} from "./appNavigation.styles";
import LeavePageModal from "../LeavePageModal/LeavePageModal";
import { Dispatch, SetStateAction, useContext } from "react";
import { PlaceANewOrderText } from "constants/text";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { models } from "types/api/viewModels";
import ConfigurationsContext from "context/configurations/ConfigurationsContext.ts";

interface Props {
	currentPath: string;
	handleCloseSubMenu: () => void;
	handleClickNavigationOption: (navItem: models["UserNavigationItemViewModel"], index: number) => void;
	leavePageModalOpen: boolean;
	mainNavigationItems: Array<models["UserNavigationItemViewModel"]>;
	selectedIndex: number;
	setLeavePageModalOpen: Dispatch<SetStateAction<boolean>>;
	subMenuOpen: boolean;
	subNavigation: models["UserNavigationItemViewModel"] | null;
}

const AppNavigation = ({
	currentPath,
	handleClickNavigationOption,
	handleCloseSubMenu,
	leavePageModalOpen,
	mainNavigationItems,
	selectedIndex,
	setLeavePageModalOpen,
	subMenuOpen,
	subNavigation
}: Props) => {
	const { configurationsData } = useContext(ConfigurationsContext);

	return (
		<NavigationContainer subMenuOpen={subMenuOpen}>
			<LeavePageModal
				open={leavePageModalOpen}
				url={currentPath}
				continueOrdering={() => setLeavePageModalOpen(false)}
				closeModal={() => setLeavePageModalOpen(false)}
			/>
			<MainNavigationLinks data-testid="main-navigation-link-container">
				{mainNavigationItems.map((mainNavigationItem, index) => (
					<NavigationItemButton
						data-id={mainNavigationItem.id}
						data-testid={mainNavigationItem.id}
						key={mainNavigationItem.title}
						selected={index === selectedIndex}
						onClick={() => handleClickNavigationOption(mainNavigationItem, index)}
					>
						<MainNavigationIcon
							data-testid={`${mainNavigationItem.id}-logo`}
							src={"/assets/navigation_logos/" + mainNavigationItem.icon}
						/>
						<div>
							{mainNavigationItem.title}
							{index === selectedIndex && (
								<img
									alt={mainNavigationItem.title ?? ""}
									src="/assets/navigation_logos/SelectedNavItemBlueDot.svg"
								/>
							)}
						</div>
					</NavigationItemButton>
				))}
			</MainNavigationLinks>
			<SubNavigationLinks data-testid="sub-navigation-link-container">
				<CloseSubMenuButton
					data-testid="navigation-close-sub-menu-button"
					onClick={handleCloseSubMenu}
				>
					<ChevronLeft />
					{subNavigation?.title}
				</CloseSubMenuButton>
				{subNavigation?.navigationItems?.map((subNavigationItem, index) => {
					if (
						subNavigationItem?.title === PlaceANewOrderText &&
						configurationsData?.featureFlags?.createOrdersEnabled
					) {
						return (
							<NavigationPlaceNewOrderButton
								key={subNavigationItem.title}
								variant="contained"
								data-id="navigation-place-new-order-button"
								data-testid="navigation-place-new-order-button"
								fullWidth
								onClick={() => handleClickNavigationOption(subNavigationItem, index)}
							>
								{subNavigationItem.title}
							</NavigationPlaceNewOrderButton>
						);
					} else if (subNavigationItem?.title !== PlaceANewOrderText) {
						return (
							<SubNavigationButton
								data-id={subNavigationItem.id}
								data-testid={`navigation-subMenu-${subNavigationItem.id}`}
								key={subNavigationItem.title}
								onClick={() => handleClickNavigationOption(subNavigationItem, index)}
							>
								{subNavigationItem.title}
							</SubNavigationButton>
						);
					}
				})}
			</SubNavigationLinks>
		</NavigationContainer>
	);
};

export default AppNavigation;
