import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { checkForAndReplaceNullEmptyWhitespace, convertToTitleCase } from "utils/string";
import { ORDER_STATUS_REASON_TOOLTIPS, ORDER_STATUS_TOOLTIPS } from "constants/orderCardStatusTooltips";
import {
	CabinetCountText,
	DesignerNumberText,
	DoorStyleText,
	ETAText,
	FinishText,
	OrderDateText,
	OrderNumberText,
	PONumberText,
	SingleCardTrackingText,
	SingleCardTruckText
} from "constants/text";
import { brandCheck, displayDesigner } from "utils/order";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import {
	SingleCardContent,
	SingleCardGridContainer,
	SingleCardHeaderGridItem,
	SingleCardJobNameTypography,
	SingleCardOrderStatusPillChip,
	SingleCardOrderStatusReasonPillChip,
	SingleCardOrderTypePillChip,
	SingleCardSpanSecondary,
	SingleCardTooltip,
	SingleCardTooltipTextComponent,
	SingleCardTruckTrackingNumberGrid,
	SingleCardWrapper,
	StyledLabel
} from "./SingleCard.styles";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { formatDateToMMDDYYYY } from "utils/date";
import { OrderStatus, OrderType } from "data/api/v1";

const SingleCard = ({ order }: Props) => {
	const navigateToDetails = useNavigate();

	const orderDetails = [
		{
			label: OrderDateText,
			value: formatDateToMMDDYYYY(new Date(order.orderDate)),
			testId: "single-card-order-date"
		},
		{
			label: PONumberText,
			value: order.poNumber,
			testId: "single-card-po-number"
		},
		{
			label: OrderNumberText,
			value: order.orderNumber,
			testId: "single-card-order-number"
		},
		{
			label: DesignerNumberText,
			value: displayDesigner(order?.designer),
			testId: "single-card-designer"
		},
		{
			label: DoorStyleText,
			value: order.doorStyle,
			testId: "single-card-door-style"
		},
		{
			label: FinishText,
			value: order.finish,
			testId: "single-card-finish"
		},
		{
			label: CabinetCountText,
			value: order?.counts?.cabinetCount ?? 0,
			testId: "single-card-cabinetCount"
		}
	];

	return (
		<SingleCardWrapper id="project-card">
			{/* role is for GA targeting */}
			<SingleCardContent
				key={order.id}
				data-testid="single-order-card"
				data-id="single-order-card"
				role="button"
				onClick={() => navigateToDetails(`/details/${order.orderId}`)}
			>
				<SingleCardGridContainer container>
					<SingleCardHeaderGridItem
						item
						container
						xs={10}
					>
						<Grid
							item
							xs={12}
						>
							<SingleCardJobNameTypography data-testid="single-card-job-name-order-number">
								{order.jobName ? order.jobName : order.poNumber}
							</SingleCardJobNameTypography>
						</Grid>
						<Grid
							item
							xs={12}
							lg={2.5}
							xl={2}
						>
							<StyledSummaryCardLogo
								data-testid="single-card-brand-logo"
								parentBrand={order.parentBrand}
								src={
									brandCheck(order.parentBrand)
										? `/assets/manufacture_logos/${order.parentBrand}.png`
										: "/assets/tandem_logos/cwg_logo.png"
								}
								alt={brandCheck(order.parentBrand) ? order.parentBrand : "CabinetworksGroup Logo"}
							/>
						</Grid>
						{order.orderType && (
							<Grid
								data-testid="single-card-order-type"
								aria-label={`Order Type: ${convertToTitleCase(order.orderType)}`}
							>
								<SingleCardOrderTypePillChip label={convertToTitleCase(order.orderType)} />
							</Grid>
						)}
						{order.status !== "HOLD" && order.status !== "UNKNOWN" && order.status && (
							<Grid
								data-testid="single-card-order-status"
								aria-label={`Order Status: ${convertToTitleCase(order.status)}`}
							>
								<SingleCardTooltip
									title={
										<SingleCardTooltipTextComponent>
											{
												ORDER_STATUS_TOOLTIPS.find((status) => status.status === order.status)
													?.description
											}
										</SingleCardTooltipTextComponent>
									}
									placement="bottom-start"
									aria-label={
										ORDER_STATUS_TOOLTIPS.find((status) => status.status === order.status)
											?.description
									}
								>
									<SingleCardOrderStatusPillChip
										label={convertToTitleCase(order.status)}
										orderStatus={order.status}
									/>
								</SingleCardTooltip>
							</Grid>
						)}
						{order.statusReason && (
							<Grid
								data-testid="single-card-order-status-reason"
								aria-label={`Order Status Reason: ${convertToTitleCase(order.statusReason)}`}
							>
								<SingleCardTooltip
									title={
										<SingleCardTooltipTextComponent>
											{
												ORDER_STATUS_REASON_TOOLTIPS.find(
													(status) => status.status === order.statusReason
												)?.description
											}
										</SingleCardTooltipTextComponent>
									}
									placement="bottom-start"
									aria-label={
										ORDER_STATUS_REASON_TOOLTIPS.find((status) => status.status === order.status)
											?.description
									}
								>
									<SingleCardOrderStatusReasonPillChip
										label={convertToTitleCase(order.statusReason)}
										orderStatus={order.statusReason}
									/>
								</SingleCardTooltip>
							</Grid>
						)}
						{order.orderType !== OrderType.BILLING_ONLY && order.status !== OrderStatus.CANCELLED && (
							<Grid
								xs={12}
								lg={2.5}
								xl={2}
							>
								<SingleCardTruckTrackingNumberGrid>
									<Typography>
										<span className="subtitle1">{ETAText} &nbsp;</span>
										<span data-testid="single-card-eta">{order.eta}</span>
									</Typography>
								</SingleCardTruckTrackingNumberGrid>
							</Grid>
						)}
						{order.shippingDetails.truckNumber && order.shippingDetails.trackingNumber && (
							<SingleCardTruckTrackingNumberGrid>
								<Typography>
									<span className="subtitle1">{SingleCardTrackingText}: </span>
									<span data-testid="single-card-tracking-number">
										{order.shippingDetails.trackingNumber}
									</span>
								</Typography>
							</SingleCardTruckTrackingNumberGrid>
						)}

						{(order.shippingDetails.truckNumber || order.shippingDetails.trackingNumber) &&
							!(order.shippingDetails.truckNumber && order.shippingDetails.trackingNumber) && (
								<SingleCardTruckTrackingNumberGrid>
									<Typography>
										{order.shippingDetails.truckNumber ? (
											<>
												<span>{SingleCardTruckText}: </span>
												<span data-testid="single-card-truck-number">
													{order.shippingDetails.truckNumber}
												</span>
											</>
										) : (
											<>
												<span className="subtitle1">{SingleCardTrackingText}: </span>
												<span data-testid="single-card-tracking-number">
													{order.shippingDetails.trackingNumber}
												</span>
											</>
										)}
									</Typography>
								</SingleCardTruckTrackingNumberGrid>
							)}
					</SingleCardHeaderGridItem>
					<ChevronRight data-testid="order-summary-detail-arrow-link" />
				</SingleCardGridContainer>
				<SingleCardGridContainer
					isOrderInfo
					container
					columns={8}
				>
					{orderDetails.map((detail) => (
						<Grid
							item
							xs={12}
							lg={1}
							key={detail.label}
						>
							<Grid
								container
								direction={{ xs: "row", lg: "column" }}
							>
								<StyledLabel>{detail.label}</StyledLabel>
								<SingleCardSpanSecondary data-testid={detail.testId}>
									{checkForAndReplaceNullEmptyWhitespace(detail.value)}
								</SingleCardSpanSecondary>
							</Grid>
						</Grid>
					))}
				</SingleCardGridContainer>
			</SingleCardContent>
		</SingleCardWrapper>
	);
};

interface Props {
	order: any;
}

export default SingleCard;
