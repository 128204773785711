import ConfirmationThanks from "components/Common/ConfirmationThanks/ConfirmationThanks";
import {
	NewOrderButton,
	NewOrderConfirmationOrderNumber,
	NewOrderConfirmationOrderPending,
	NewOrderOrderDate,
	NewOrdersHeaderSummaryDetailsAccessoriesCount,
	NewOrdersHeaderSummaryDetailsCabinetsCount,
	NewOrdersHeaderSummaryDetailsCubeCount,
	NewOrdersHeaderSummaryDetailsProductLines,
	NewOrderThanksSubheader
} from "components/NewOrders/constants";
import {
	AddOnOriginalOrderNumber,
	NewOrderConfirmationOrderListHeader,
	NewOrderNumberHeader,
	NewOrdersContent,
	NewOrdersContentContainer,
	NewOrdersLayoutContainer,
	NewOrdersSummaryDetailsDesktop,
	NewOrdersSummaryDetailsMobile
} from "components/NewOrders/NewOrders.styles";
import ReviewOrderDetails from "components/NewOrders/Review/ReviewOrderDetails";
import ReviewOrderList from "components/NewOrders/Review/ReviewOrderList";
import { formatOrderType, todayDate } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { OriginalOrderText, UnavailableDataPlaceholderText } from "constants/text";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import useHandlePopState from "hooks/useHandlePopState";
import useInitOrderDetail from "hooks/useInitOrderDetail";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { RootState } from "stores/application.store";
import { dateFormatTwoDigitDays } from "utils/date";
import { epsilonRounding } from "utils/epsilonRounding";
import { AddOnsProps } from "../BuildOrderPage/BuildOrderPage";

const NewOrderConfirmation = ({ isAddOn }: AddOnsProps) => {
	const dispatch = useDispatch();
	const orderType = useSelector((state: RootState) => state.newOrder.newOrderDetails?.orderType);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const param = useParams();
	const { orderDetail } = useInitOrderDetail(param.orderId);

	useHandlePopState({
		title: isAddOn ? "Add-Ons Confirmation" : "New Order Confirmation",
		path: isAddOn ? `/details/${param.orderId}` : "/new-order",
		dispatchActions: () => {
			dispatch(newOrderActions.clearNewOrder());
		}
	});

	const countsByProductLine = validatedOrderResponse?.countsByProductLine ?? {};

	return (
		<NewOrdersLayoutContainer>
			<ConfirmationThanks
				subheader={NewOrderThanksSubheader}
				button={NewOrderButton}
				isAddOn={isAddOn}
			/>
			<NewOrdersContentContainer>
				<NewOrdersContent>
					<NewOrderConfirmationOrderListHeader>
						<div> {formatOrderType(orderType)} </div>
						<div>
							<span data-testid="new-order-confirmation-header">
								{isAddOn && (
									<NewOrderNumberHeader>
										<div>
											<div data-testid="new-order-confirmation-order-number">
												{NewOrderConfirmationOrderNumber}
											</div>
											<div data-testid="new-order-confirmation-pending">
												&nbsp;
												{NewOrderConfirmationOrderPending}
											</div>
										</div>
										<AddOnOriginalOrderNumber>
											{OriginalOrderText} # {orderDetail?.orderNumber}
										</AddOnOriginalOrderNumber>
									</NewOrderNumberHeader>
								)}
							</span>
							<NewOrdersSummaryDetailsMobile
								data-testid="new-orders-header-summary-details"
								isConfirmation={true}
							>
								<div>
									<div>{NewOrderOrderDate}</div>
									<div data-testid="new-order-header-summary-details-order-date">
										{dateFormatTwoDigitDays(String(todayDate))}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsProductLines}</div>
									{Object.entries(countsByProductLine).map(([productLine]) => (
										<div
											data-testid="new-orders-header-summary-details-product-lines"
											key={productLine}
										>
											{productLine}
										</div>
									))}
								</div>
								{Object.entries(countsByProductLine).map(([productLine, counts]) => (
									<div key={productLine}>
										<div>
											<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
											<div>{counts?.cabinetCount ?? UnavailableDataPlaceholderText}</div>
										</div>
										<div>
											<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
											<div>{counts?.accessoryCount ?? UnavailableDataPlaceholderText}</div>
										</div>
										<div>
											<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
											<div>
												{epsilonRounding(
													Number(counts?.cubeCount ?? UnavailableDataPlaceholderText)
												)
													.toFixed(2)
													.toString()}
											</div>
										</div>
									</div>
								))}
							</NewOrdersSummaryDetailsMobile>
							<NewOrdersSummaryDetailsDesktop
								data-testid="new-orders-header-summary-details"
								isConfirmation={true}
							>
								<div>
									<div>{NewOrderOrderDate}</div>
									<div data-testid="new-order-header-summary-details-order-date">
										{dateFormatTwoDigitDays(String(todayDate))}
									</div>
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsProductLines}</div>
									{Object.entries(countsByProductLine).map(([productLine]) => (
										<div
											data-testid="new-orders-header-summary-details-product-lines"
											key={productLine}
										>
											{productLine}
										</div>
									))}
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
									{Object.entries(countsByProductLine).map(([productLine, counts]) => (
										<div
											data-testid="new-order-header-summary-details-cabinets-count"
											key={productLine}
										>
											{counts?.cabinetCount ?? UnavailableDataPlaceholderText}
										</div>
									))}
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
									{Object.entries(countsByProductLine).map(([productLine, counts]) => (
										<div
											data-testid="new-order-header-summary-details-accessories-count"
											key={productLine}
										>
											{counts?.accessoryCount ?? UnavailableDataPlaceholderText}
										</div>
									))}
								</div>
								<div>
									<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
									{Object.entries(countsByProductLine).map(([productLine, counts]) => (
										<div
											data-testid="new-order-header-summary-details-cubes-count"
											key={productLine}
										>
											{epsilonRounding(
												Number(counts?.cubeCount ?? UnavailableDataPlaceholderText)
											)
												.toFixed(2)
												.toString()}
										</div>
									))}
								</div>
							</NewOrdersSummaryDetailsDesktop>
						</div>
					</NewOrderConfirmationOrderListHeader>
					<ReviewOrderDetails />
					<ReviewOrderList
						isConfirmationPage
						isAddOn
					/>
				</NewOrdersContent>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default NewOrderConfirmation;
