import { PropsWithChildren, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
	AccountBillToContentWrapper,
	AccountBillToLogo,
	EmptyMobileBlock,
	FileContentsContainer,
	FileContentsLogo,
	SummaryProductLine
} from "./NewOrders.styles";
import {
	DoorStyleText,
	FileNameText,
	FinishText,
	NoAccountText,
	SelectAccountText,
	SelectBillToText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { Button, FormControl, PopperProps } from "@mui/material";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { brandCheck } from "utils/order";
import { useDispatch, useSelector } from "react-redux";
import { NewOrderRemoveGroup, NewOrderSelectAccountError, NewOrderSelectBillToError } from "./constants";
import AutoComplete from "components/Common/Autocomplete/Autocomplete";
import { AutoCompletePopper } from "components/Common/Autocomplete/Autocomplete.styles";
import { convertToTitleCase } from "utils/string";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalFormProvider from "./AddGlobalAttributes/forms/GlobalFormProvider";
import AddGlobalAttributes from "./AddGlobalAttributes/AddGlobalAttributes";
import { ImportOrderResponseData } from "types/api/orders/importOrder";
import { models } from "types/api/viewModels.ts";
import { RootState } from "stores/application.store.tsx";
import {
	type ConfigurationGroup,
	findAccount,
	findBillTo,
	runForAllConfigs,
	sortedAccounts,
	sortedBillTos
} from "components/NewOrders/utils/NewOrderUtils.tsx";

interface AccountBillToContentProps extends PropsWithChildren {
	csvContents: ImportOrderResponseData[] | undefined;
	productLine: string;
	configGroup?: ConfigurationGroup;
	handleRemoveGroup?: () => void;
	isBuildOrderPage?: boolean;
	updateButtons?: boolean;
	handleUpdateAccount?: (
		account: models["CustomerAccountViewModel"] | null,
		configurationId: string | null | undefined
	) => void;
	handleUpdateBillTo?: (
		billTo: models["BillToViewModel"] | null | undefined,
		configurationId: string | null | undefined
	) => void;
	handleConfigurationUpdate?: (configuration: models["PendingOrderConfigurationViewModel"]) => void;
	setConfigurationToUpdate?: () => void;
	isAddOn?: boolean;
	addOnAccount?: models["CustomerAccountViewModel"] | null;
	addOnBillTo?: models["BillToViewModel"] | null;
}

const AccountBillToContent = ({
	children,
	csvContents,
	productLine,
	configGroup,
	handleRemoveGroup,
	handleUpdateBillTo,
	handleUpdateAccount,
	isAddOn,
	isBuildOrderPage,
	updateButtons,
	handleConfigurationUpdate,
	setConfigurationToUpdate,
	addOnAccount,
	addOnBillTo
}: AccountBillToContentProps) => {
	const dispatch = useDispatch();
	const importedCSVs = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const tempCSVContents = useSelector((state: RootState) => state.newOrder.parsedTempCSV);
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);
	const currentCSVs = tempCSVContents ?? importedCSVs;
	const csvGroupConfig = csvContents?.[0]?.configurations?.[0];

	const csvGroup = csvGroupConfig
		? {
				accountNumber: csvGroupConfig?.accountNumber,
				accountName: csvGroupConfig?.accountName,
				accountId: csvGroupConfig?.accountId,
				billToId: csvGroupConfig?.billToId
			}
		: undefined;

	const accounts = useMemo(() => productLineAccountInfo[productLine], [productLine, productLineAccountInfo]);
	const [groupAccountNumber, setGroupAccountNumber] = useState<models["CustomerAccountViewModel"] | null | undefined>(
		addOnAccount ?? findAccount(accounts, configGroup?.accountNumber ?? csvGroup?.accountNumber)
	);
	const [groupBillToId, setGroupBillToId] = useState<models["BillToViewModel"] | null | undefined>(
		addOnBillTo ?? findBillTo(groupAccountNumber, configGroup?.billToId ?? csvGroup?.billToId)
	);

	useEffect(() => {
		const account = addOnAccount ?? findAccount(accounts, configGroup?.accountNumber ?? csvGroup?.accountNumber);
		const billTo = addOnBillTo ?? findBillTo(account, configGroup?.billToId ?? csvGroup?.billToId);
		(configGroup?.accountNumber || isAddOn) && setGroupAccountNumber(account);
		(configGroup?.billToId || isAddOn) && setGroupBillToId(billTo);
	}, [
		accounts,
		addOnAccount,
		addOnBillTo,
		configGroup?.accountNumber,
		configGroup?.billToId,
		csvGroup?.accountNumber,
		csvGroup?.billToId,
		groupAccountNumber,
		isAddOn
	]);

	useEffect(() => {
		const hasValidAccount =
			csvContents?.every((csv) => csv?.configurations?.every((config) => config.accountNumber !== null)) ?? true;
		const hasValidBillTo =
			csvContents?.every((csv) => csv?.configurations?.every((config) => config.billToId !== null)) ?? true;

		if (!hasValidAccount && !isAddOn) setGroupAccountNumber(null);
		if (!hasValidBillTo && !isAddOn) setGroupBillToId(null);
	}, [csvContents, isAddOn]);

	const handleAccountChange = useCallback(
		(_event: SyntheticEvent | undefined, value: models["CustomerAccountViewModel"]) => {
			setGroupAccountNumber(value);
			runForAllConfigs(csvContents, currentCSVs, (reduxFileIndex, reduxConfigIndex, config) => {
				dispatch(
					newOrderActions.updateAccountNumber({
						fileIndex: reduxFileIndex,
						productLineCode: config.globals?.productLine?.code,
						newAccountNumber: value
					})
				);
				handleUpdateAccount?.(value, config.configurationId);
			});

			if (groupBillToId !== null) {
				setGroupBillToId(undefined);
				runForAllConfigs(csvContents, currentCSVs, (reduxFileIndex, reduxConfigIndex, config) => {
					dispatch(
						newOrderActions.updateBillToNumber({
							fileIndex: reduxFileIndex,
							productLineCode: config.globals?.productLine?.code,
							newBillToNumber: undefined
						})
					);
					handleUpdateBillTo?.(undefined, config.configurationId);
				});
			}
		},
		[csvContents, currentCSVs, dispatch, groupBillToId, handleUpdateAccount, handleUpdateBillTo]
	);

	const handleBillToChange = useCallback(
		(_event: SyntheticEvent | undefined, value: models["BillToViewModel"]) => {
			if (!groupAccountNumber) {
				setGroupAccountNumber(null);
				runForAllConfigs(csvContents, currentCSVs, (reduxFileIndex, reduxConfigIndex, config) => {
					dispatch(
						newOrderActions.updateAccountNumber({
							fileIndex: reduxFileIndex,
							productLineCode: config.globals?.productLine?.code,
							newAccountNumber: null
						})
					);
					handleUpdateAccount?.(null, config.configurationId);
				});
			} else {
				setGroupBillToId(value);
				runForAllConfigs(csvContents, currentCSVs, (reduxFileIndex, reduxConfigIndex, config) => {
					dispatch(
						newOrderActions.updateBillToNumber({
							fileIndex: reduxFileIndex,
							productLineCode: config.globals?.productLine?.code,
							newBillToNumber: value
						})
					);
					handleUpdateBillTo?.(value, config.configurationId);
				});
			}
		},
		[csvContents, currentCSVs, dispatch, groupAccountNumber, handleUpdateAccount, handleUpdateBillTo]
	);

	useEffect(() => {
		if (addOnAccount && groupAccountNumber) {
			handleAccountChange(undefined, addOnAccount);
		}
		if (addOnBillTo && groupBillToId) {
			handleBillToChange(undefined, addOnBillTo);
		}
		// The change functions re-calculate when the CSVs change, causing an infinite loop here. We just want to change the CSV contents on load.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addOnAccount, addOnBillTo, groupAccountNumber, groupBillToId]);

	const CustomPopper = (props: PopperProps) => {
		if (!groupAccountNumber) {
			return null;
		}
		return <AutoCompletePopper {...props} />;
	};

	const selectedAccount: models["CustomerAccountViewModel"] | undefined = useMemo(
		() => findAccount(accounts, groupAccountNumber?.accountNumber),
		[accounts, groupAccountNumber]
	);
	const selectedBillTo = useMemo(
		() => findBillTo(selectedAccount, groupBillToId?.billToId),
		[groupBillToId?.billToId, selectedAccount]
	);
	const getConfigs = (configurations: models["PendingOrderConfigurationViewModel"][] | null | undefined) =>
		isBuildOrderPage && configurations?.[0] ? [configurations[0]] : configurations;

	return (
		<AccountBillToContentWrapper
			isBuildOrderPage={isBuildOrderPage}
			isBillToError={groupBillToId === null}
		>
			{csvContents?.map((csv, csvIndex) =>
				getConfigs(csv?.configurations)?.map((configuration, index) => (
					<FileContentsContainer
						key={configuration.configurationId}
						isBuildOrderPage={isBuildOrderPage}
					>
						{index === 0 && csvIndex === 0 ? (
							<FileContentsLogo isBuildOrderPage={isBuildOrderPage}>
								<>
									<AccountBillToLogo
										parentBrand={configuration?.globals?.brand?.code}
										className="body2"
										src={
											brandCheck(configuration?.globals?.brand?.code)
												? `/assets/manufacture_logos/${configuration?.globals?.brand?.code}.png`
												: "/assets/tandem_logos/cwg_logo.png"
										}
										alt={
											brandCheck(configuration?.globals?.brand?.code)
												? (configuration?.globals?.brand?.description ?? "")
												: "CabinetworksGroup Logo"
										}
									/>
									<SummaryProductLine
										data-testid="product-line"
										fontVariant={isBuildOrderPage ? "small" : "large"}
									>
										{configuration?.globals?.productLine?.description}
									</SummaryProductLine>
								</>
							</FileContentsLogo>
						) : (
							<EmptyMobileBlock />
						)}

						{!isBuildOrderPage && (
							<>
								{index === 0 ? (
									<div className="subtitle2">
										{FileNameText}
										<div
											className="body2"
											data-testid="uploaded-file-name"
										>
											{csv?.fileName ?? UnavailableDataPlaceholderText}
										</div>
									</div>
								) : (
									<EmptyMobileBlock />
								)}
								{csv?.configurations?.length && (
									<div className="subtitle2">
										{DoorStyleText}
										<div
											className="body2"
											data-testid="door-style"
										>
											{configuration?.globals?.style?.description}
										</div>
									</div>
								)}
								{csv?.configurations?.length && (
									<div className="subtitle2">
										{FinishText}
										<div
											className="body2"
											data-testid="finish"
										>
											{configuration?.globals?.finish?.description}
										</div>
									</div>
								)}
							</>
						)}
					</FileContentsContainer>
				))
			)}
			{!isAddOn && (
				<>
					<FormControl
						size="small"
						required
						fullWidth
						sx={{ alignSelf: "flex-start" }}
					>
						<AutoComplete
							options={sortedAccounts(accounts) ?? []}
							onMouseDownCapture={(e: SyntheticEvent) => {
								if (e.target instanceof HTMLElement && e.target.nodeName === "INPUT") {
									e.stopPropagation();
								}
							}}
							getOptionLabel={(option) =>
								option?.accountNumber && option?.accountName
									? `${option.accountNumber} - ${option.accountName}`
									: (option?.accountNumber ?? "")
							}
							onChange={handleAccountChange}
							value={selectedAccount}
							isError={
								Boolean(accounts) && (!accounts || accounts.length === 0 || groupAccountNumber === null)
							}
							errorText={!accounts || accounts.length === 0 ? NoAccountText : NewOrderSelectAccountError}
							label={SelectAccountText}
							dataTestId="newOrder-select-account"
							required
							disableClearable
						/>
					</FormControl>

					<FormControl
						size="small"
						required
						fullWidth
						sx={{ alignSelf: "flex-start" }}
					>
						<AutoComplete
							key={selectedAccount?.accountNumber}
							options={sortedBillTos(selectedAccount?.billTos) ?? []}
							getOptionLabel={(option: models["BillToViewModel"]) => {
								const projectName =
									option.projectName && option.projectName.trim() !== ""
										? option.projectName + " - "
										: "";
								const logisticsMode = option.logisticsMode
									? " - " + convertToTitleCase(option.logisticsMode)
									: "";
								return option
									? `${projectName}${option.city}, ${option.state}${logisticsMode} - ${option.billToId}`
									: "";
							}}
							onChange={handleBillToChange}
							value={selectedBillTo}
							isError={groupBillToId === null}
							errorText={NewOrderSelectBillToError}
							label={SelectBillToText}
							dataTestId="newOrder-select-billTo"
							PopperComponent={CustomPopper}
							required
							disableClearable
						/>
					</FormControl>
				</>
			)}
			{updateButtons && (
				<>
					<GlobalFormProvider isUpdate>
						<AddGlobalAttributes
							setConfigurationToUpdate={setConfigurationToUpdate}
							handleConfigurationUpdate={handleConfigurationUpdate}
							isAddOn={isAddOn}
							isUpdate
						/>
					</GlobalFormProvider>
					<Button
						onClick={handleRemoveGroup}
						variant="text"
						data-testid="remove-group"
					>
						<DeleteIcon />
						{NewOrderRemoveGroup}
					</Button>
				</>
			)}
			{children}
		</AccountBillToContentWrapper>
	);
};

export default AccountBillToContent;
