import styled from "@emotion/styled";
import { Box, Chip, Grid, Tooltip, Typography } from "@mui/material";
import { ORDER_STATUS_REASONS } from "constants/orderStatusHeaderStatusReasons";
import { OrderStatusReason } from "data/api/v1";

interface SingleCardGridContainerProps {
	isOrderInfo?: boolean;
}

interface SingleCardOrderStatusPillChipProps {
	orderStatus: string;
}

export const SingleCardWrapper = styled.div({
	paddingBottom: "1rem"
});

export const SingleCardContent = styled(Box)({
	border: "1px solid rgba(0, 0, 0, 0.12)",
	backgroundColor: "var(--gray-50)",
	cursor: "pointer",
	"&:hover": {
		backgroundColor: "var(--gray-200)"
	}
});

export const SingleCardGridContainer = styled(Grid, {
	shouldForwardProp: (prop) => prop !== "isOrderInfo"
})<SingleCardGridContainerProps>(({ isOrderInfo = false }) => ({
	margin: !isOrderInfo ? "0px 0 -15px -15px" : "",
	label: {
		letterSpacing: "var(--letter-spacing-9)",
		fontFamily: "Gibson Medium, sans-serif",
		color: "var(--text-primary)",
		fontSize: !isOrderInfo ? "var(--font-size-6)" : "var(--font-size-3)",
		cursor: "pointer",
		display: isOrderInfo ? "block" : ""
	},
	span: isOrderInfo
		? {
				fontFamily: "Gibson Regular, sans-serif",
				fontSize: "var(--font-size-3)",
				overflowWrap: "break-word"
			}
		: {},
	"& .MuiGrid-item": {
		margin: ".5rem 0 0.75rem 1rem"
	},
	"& .MuiSvgIcon-root": {
		color: "var(--gray-600)",
		position: "absolute",
		right: ".75rem",
		top: "1.5rem"
	}
}));

export const SingleCardHeaderGridItem = styled(Grid)({
	marginTop: "1rem",
	display: "flex",
	alignItems: "center",
	paddingBottom: "16px"
});

export const SingleCardJobNameTypography = styled(Typography)({
	letterSpacing: "var(--letter-spacing-9)",
	fontFamily: "Gibson Medium, sans-serif",
	color: "var(--text-primary)",
	fontSize: "var(--font-size-6)",
	paddingRight: "1rem",
	marginTop: ".25rem"
});

export const SingleCardOrderTypePillChip = styled(Chip)({
	display: "flex",
	height: "1.5rem",
	alignItems: "baseline",
	"& .MuiChip-label": {
		fontSize: "0.938rem",
		paddingTop: 2.5
	},
	color: "var(--text-primary)",
	cursor: "pointer",
	marginLeft: "1rem",
	"@media (max-width: 1200px)": {
		marginBottom: "13px"
	}
});

export const SingleCardOrderStatusPillChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "orderStatus"
})<SingleCardOrderStatusPillChipProps>(({ orderStatus }) => ({
	display: "flex",
	height: "1.5rem",
	alignItems: "baseline",
	background: "none",
	color: getColorCode(orderStatus),
	border: getBorderCode(orderStatus),
	"& .MuiChip-label": {
		fontSize: "0.938rem",
		paddingTop: 2.5
	},
	"@media (max-width: 1200px)": {
		marginBottom: "13px"
	}
}));

export const SingleCardOrderStatusReasonPillChip = styled(Chip, {
	shouldForwardProp: (prop) => prop !== "orderStatus"
})<SingleCardOrderStatusPillChipProps>(({ orderStatus }) => ({
	display: "flex",
	height: "1.5rem",
	alignItems: "center",
	color:
		orderStatus !== OrderStatusReason.DELIVERYDATECHANGED && orderStatus !== OrderStatusReason.PARTIALLY_SHIPPED
			? "var(--orange-500)"
			: "var(--white-50)",
	background:
		orderStatus !== OrderStatusReason.DELIVERYDATECHANGED && orderStatus !== OrderStatusReason.PARTIALLY_SHIPPED
			? "var(--orange-50)"
			: "var(--cwg-blue-900)",
	"& .MuiChip-label": {
		paddingTop: 2.5
	},
	"@media (max-width: 1200px)": {
		marginBottom: "13px"
	}
}));

export const SingleCardTooltip = styled(Tooltip)({
	"&.MuiTooltip-tooltip": {
		"&.MuiTooltip-tooltipPlacementBottom": {
			marginTop: "4px"
		}
	},
	marginLeft: "1rem",
	cursor: "pointer"
});

export const SingleCardTooltipTextComponent = styled.p({
	fontFamily: "Gibson Regular",
	fontSize: "var(--font-size-1)",
	lineHeight: "var(--line-height-17)",
	margin: 0
});

export const SingleCardSpanSecondary = styled.span({
	color: "var(--text-secondary)",
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	width: "100%"
});

export const SingleCardTruckTrackingNumberGrid = styled(Grid)({
	"& .MuiTypography-root": {
		letterSpacing: "var(--letter-spacing-13)",
		fontFamily: "Gibson Regular, sans-serif",
		color: "var(--text-primary)",
		fontSize: "var(--font-size-3)",
		marginLeft: "1rem",
		span: {
			fontSize: "0.875rem",
			textTransform: "uppercase"
		}
	}
});

export const StyledLabel = styled.label({
	letterSpacing: "var(--letter-spacing-9)",
	fontFamily: "Gibson Medium, sans-serif",
	color: "var(--text-primary)",
	fontSize: "var(--font-size-6)",
	cursor: "pointer",
	marginRight: "8px",
	"@media (min-width: 1200px)": {
		marginRight: "0px"
	},
	whiteSpace: "nowrap"
});

const getColorCode = (orderStatus: string) => {
	const foundStatus = ORDER_STATUS_REASONS.find((status) => status.status === orderStatus);
	return foundStatus?.statusColor ?? "var(--text-primary)";
};

const getBorderCode = (orderStatus: string) => {
	const foundStatus = ORDER_STATUS_REASONS.find((status) => status.status === orderStatus);
	return "1px solid " + (foundStatus?.statusBorderColor ?? foundStatus?.statusColor ?? "var(--gray-400)");
};
