import { useGetDesignersByAccountIdQuery } from "features/api/designerApi";
import { useGetUserDetailsQuery } from "features/api/userApi";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { models } from "types/api/viewModels";
import useInitOrderDetail from "./useInitOrderDetail";
import { useParams } from "react-router";
import { sortDesigners } from "components/NewOrders/utils/NewOrderUtils";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { DesignerIsNotHereText } from "components/NewOrders/constants";

const useNewOrderDesigners = () => {
	const draftOrder = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);
	const newOrderDetails = useSelector((state: RootState) => state.newOrder.newOrderDetails);
	const defaultConfiguration = newOrderDetails?.configurations?.[0] ?? draftOrder?.configurations?.[0];
	const accountNumberId = defaultConfiguration?.accountId ?? null;

	const params = useParams();
	const formContext = useFormContext();
	const setValue = useMemo(() => formContext?.setValue ?? (() => {}), [formContext]);
	const getValues = useMemo(() => formContext?.getValues ?? (() => {}), [formContext]);
	const dispatch = useDispatch();

	const { orderDetail: originalOrderDetails } = useInitOrderDetail(params.orderId);
	const { data: user } = useGetUserDetailsQuery();
	const { data: designers, isLoading: isLoadingDesigners } = useGetDesignersByAccountIdQuery(accountNumberId ?? "");

	const designerOptions = useMemo(() => {
		let newDesignerList: models["DesignerViewModel"][] = [...(designers || [])];

		return sortDesigners(
			newDesignerList
				.filter(
					(designer): designer is models["DesignerViewModel"] =>
						typeof designer !== "string" && designer.designerNumber != null
				)
				.filter(
					(item, index, arr) =>
						arr.findIndex((el) => typeof el !== "string" && el.designerNumber === item.designerNumber) ===
						index
				)
		);
	}, [designers]);

	const findDesignerNumberInDesignerResponse = useCallback(
		(designerNumber: string | null | undefined): models["DesignerViewModel"] | undefined | null =>
			designerOptions?.find((designer) => designer.designerNumber === designerNumber),
		[designerOptions]
	);

	const convertDesignerNumberToDraftDesigner = (
		designerNumber: string | null | undefined
	): { designerName?: string | null; designerNumber?: string | null } => {
		const foundDesignerNumber = findDesignerNumberInDesignerResponse(designerNumber);
		if (foundDesignerNumber) {
			return {
				designerName: [foundDesignerNumber.firstName, foundDesignerNumber.lastName].filter(Boolean).join(" "),
				designerNumber: foundDesignerNumber.designerNumber
			};
		} else if (originalOrderDetails.designer?.designerNumber === designerNumber) {
			return {
				designerName: [originalOrderDetails.designer?.firstName, originalOrderDetails.designer?.lastName]
					.filter(Boolean)
					.join(" "),
				designerNumber: originalOrderDetails.designer?.designerNumber
			};
		} else {
			return {
				designerName: null,
				designerNumber: designerNumber ?? null
			};
		}
	};

	const addDesignerNumberToOrder = (designerNumber: string | null | undefined) => {
		dispatch(newOrderActions.overwriteOrderDetails({ ...newOrderDetails, designerNumber: designerNumber }));
		dispatch(
			newOrderActions.overwriteDraftOrder({
				...draftOrder,
				designerName: convertDesignerNumberToDraftDesigner(designerNumber).designerName,
				designerNumber: convertDesignerNumberToDraftDesigner(designerNumber).designerNumber
			})
		);
	};

	const setDefaultDesigner = useCallback(() => {
		const foundDesignerNumberInUserResponse = designerOptions?.find(
			(designer) => user?.user?.designerNumber && designer?.designerNumber?.includes(user?.user?.designerNumber)
		);
		const designer = getValues()?.designer;

		if (designer === DesignerIsNotHereText) {
			return;
		} else if (newOrderDetails?.designerNumber !== undefined) {
			setValue(
				"designer",
				findDesignerNumberInDesignerResponse(newOrderDetails.designerNumber) ?? newOrderDetails.designerNumber
			);
		} else if (originalOrderDetails?.designer?.designerNumber) {
			setValue("designer", originalOrderDetails.designer);
		} else if (designerOptions?.length === 1) {
			setValue("designer", designerOptions[0]);
		} else if (foundDesignerNumberInUserResponse) {
			setValue("designer", foundDesignerNumberInUserResponse);
		}
	}, [
		designerOptions,
		user,
		newOrderDetails,
		setValue,
		findDesignerNumberInDesignerResponse,
		originalOrderDetails,
		getValues
	]);

	return {
		findDesignerNumberInDesignerResponse,
		setDefaultDesigner,
		addDesignerNumberToOrder,
		designerOptions,
		isLoadingDesigners
	};
};

export default useNewOrderDesigners;
