export enum FilterType {
	ACCOUNT = "Account",
	DRAFT_ACCOUNT = "Accounts",
	DRAFT_DESIGNER = "Designer",
	DESIGNER = "Designer",
	BRAND = "Brand",
	ORDER_STATUS = "Order Status",
	ORDER_TYPE = "Order Type",
	SORTED_BY = "SORTED_BY"
}
