import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { FormControl } from "@mui/material";

export const OrderSearchContainer = styled.div(({ theme }) => ({
	padding: 20,
	backgroundColor: theme.project.colors.backgroundColors.main,
	h5: {
		margin: "0 0 16px 0",
		color: theme.project.colors.primaryColors.main
	},
	[theme.breakpoints.down("lg")]: {
		padding: 16
	}
}));

export const OrderSearchInputLine = styled.div(({ theme }) => ({
	display: "grid",
	gap: 8,
	gridTemplateColumns: "auto max-content 1px 275px",
	gridTemplateRows: "40px auto",
	marginRight: "20px",
	width: "100%",
	[theme.breakpoints.down("lg")]: {
		display: "flex",
		flexDirection: "column",
		gap: 16
	}
}));

export const OrderSearchTextField = styled(TextField)(({ theme }) => ({
	lineHeight: "var(--line-height-5)",
	paddingBottom: "8px",
	[theme.breakpoints.down("lg")]: {
		width: "100%"
	}
}));

export const OrderSearchFormControl = styled(FormControl)({
	marginBottom: "16px",
	width: "100%"
});
