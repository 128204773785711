import { Button } from "@mui/material";
import {
	BuildAndManageText,
	ClickHere,
	CreateYourItemSubtitleText,
	DraftANewOrderText,
	FindOriginalOrderText,
	FindOriginalText,
	ManualOrderEntryText,
	ReplacementOrAddOnText,
	ToAccessText,
	TruckOrdersText
} from "constants/text";
import {
	DraftNewOrderHeader,
	GridChildTwoContainer,
	GridChildTwoStyles,
	NewOrdersContentContainer,
	NewOrdersDesktopBanner,
	NewOrdersHeaderGrid,
	NewOrdersLayoutContainer,
	NewOrdersMobileBanner,
	NewOrdersTabletBanner,
	OldExperienceContainer,
	OrderOrAddOnText,
	TruckContainer,
	UnsetSecondaryCopy
} from "components/NewOrders/NewOrders.styles";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import SaveDraftTable from "components/NewOrders/SaveDraft/SaveDraftTable/SaveDraftTable.tsx";
import AddGlobalAttributes from "components/NewOrders/AddGlobalAttributes/AddGlobalAttributes";
import GlobalFormProvider from "components/NewOrders/AddGlobalAttributes/forms/GlobalFormProvider";
import ImportCSV from "components/NewOrders/ImportCSV/ImportCSV";
import NewOrdersDialog from "components/NewOrders/NewOrdersDialog/NewOrdersDialog";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { useEffect } from "react";
import {
	AddOnBoxButtonText,
	AddOnBoxText,
	AddOnBoxTitle,
	CustomColorBoxButtonText,
	CustomColorBoxText,
	CustomColorBoxTitle,
	NewOrderBannerAltText,
	NewOrderBoxText,
	NewOrderBoxTitle
} from "components/NewOrders/constants";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import { salesforceBaseURL } from "constants/endpoints.ts";
import Link from "@mui/material/Link";
import { flags } from "constants/featureFlags";
import NewOrderLandingPageBox, {
	NewOrderLandingPageBoxProps
} from "components/NewOrders/NewOrderLandingPageBox/NewOrderLandingPageBox";
import { NewOrderLandingPageBoxes } from "components/NewOrders/NewOrderLandingPageBox/NewOrderLandingPageBox.styles";
import { NewOrderModalTracking } from "data/api/v1";
import { RootState } from "stores/application.store";

const NewOrderPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userDetails = useGetUserDetailsQuery();
	const modalState = useSelector((state: RootState) => state.newOrder.modalStatus);
	const modalStateManualOrderEntry = modalState === NewOrderModalTracking.MANUAL_ORDER_ENTRY;

	const handleFindOriginalOrder = () => {
		navigate("/projects");
	};

	useEffect(() => {
		dispatch(newOrderActions.clearNewOrder());
	}, [dispatch]);

	const isShowingNewLandingPage = `${flags.env}` !== "prod";

	const landingPageBoxes: NewOrderLandingPageBoxProps[] = [
		{
			title: NewOrderBoxTitle,
			text: NewOrderBoxText,
			imgSrc: "assets/new_order_page_boxes/NewOrderPageBox_NewOrder.svg",
			buttonText: NewOrderBoxTitle,
			buttonVariant: "contained",
			buttonDataTestId: "new-order-landing-page-new-order-button",
			onClick: () => {
				dispatch(newOrderActions.setModalState(NewOrderModalTracking.BUILD_ORDER_OPTIONS));
			}
		},
		{
			title: AddOnBoxTitle,
			text: AddOnBoxText,
			imgSrc: "assets/new_order_page_boxes/NewOrderPageBox_FindOrder.svg",
			buttonText: AddOnBoxButtonText,
			buttonVariant: "outlined",
			buttonDataTestId: "new-order-landing-page-find-order-button",
			onClick: handleFindOriginalOrder
		},
		{
			title: CustomColorBoxTitle,
			text: CustomColorBoxText,
			imgSrc: "assets/new_order_page_boxes/NewOrderPageBox_CustomColor.svg",
			buttonText: CustomColorBoxButtonText,
			buttonVariant: "outlined",
			buttonDataTestId: "new-order-landing-page-custom-color-button",
			onClick: () => {}
		}
	];

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				includeDetails={false}
				includeStepper={false}
				isNewOrderPage
			/>

			<NewOrdersContentContainer>
				<NewOrdersDesktopBanner
					src="/assets/welcome_banners/DesktopAnnouncementBanner_NewOrder.svg"
					alt={NewOrderBannerAltText}
				/>
				<NewOrdersTabletBanner
					src="/assets/welcome_banners/TabletAnnouncementBanner_NewOrder.svg"
					alt={NewOrderBannerAltText}
				/>
				<NewOrdersMobileBanner
					src="/assets/welcome_banners/MobileAnnouncementBanner_NewOrder.svg"
					alt={NewOrderBannerAltText}
				/>
				{isShowingNewLandingPage ? (
					<NewOrderLandingPageBoxes>
						{landingPageBoxes.map((box) => (
							<NewOrderLandingPageBox
								key={box.title}
								title={box.title}
								text={box.text}
								imgSrc={box.imgSrc}
								buttonText={box.buttonText}
								buttonVariant={box.buttonVariant}
								buttonDataTestId={box.buttonDataTestId}
								onClick={box.onClick}
							/>
						))}
					</NewOrderLandingPageBoxes>
				) : (
					<>
						<DraftNewOrderHeader>{DraftANewOrderText}</DraftNewOrderHeader>
						<OldExperienceContainer>
							<span>{ToAccessText}</span>
							<Link
								href={`${salesforceBaseURL}/s/place-an-order`}
								target="_blank"
								rel="noreferrer"
								data-testid="legacy-order-experience"
							>
								{ClickHere}
							</Link>
						</OldExperienceContainer>
						<NewOrdersHeaderGrid>
							<ImportCSV />
							<GridChildTwoStyles>
								<GridChildTwoContainer>
									<OrderOrAddOnText>{ReplacementOrAddOnText}</OrderOrAddOnText>
									<Button
										onClick={handleFindOriginalOrder}
										variant="outlined"
										startIcon={
											<ArrowOutwardIcon
												fontSize="small"
												sx={{ marginBottom: "2px" }}
											/>
										}
										data-id="new-order-find-original-order-button"
										sx={{
											display: "flex",
											alignItems: "center",
											height: "fit-content",
											width: "max-content",
											marginLeft: "auto"
										}}
									>
										{FindOriginalOrderText}
									</Button>
									<UnsetSecondaryCopy>{FindOriginalText}</UnsetSecondaryCopy>
								</GridChildTwoContainer>
								<div style={{ height: 20, background: "#f7f7f7" }} />
								<GridChildTwoContainer>
									<OrderOrAddOnText>{ManualOrderEntryText}</OrderOrAddOnText>
									<GlobalFormProvider>
										<AddGlobalAttributes showAddItem />
									</GlobalFormProvider>
									<UnsetSecondaryCopy>{CreateYourItemSubtitleText}</UnsetSecondaryCopy>
								</GridChildTwoContainer>
							</GridChildTwoStyles>
						</NewOrdersHeaderGrid>
					</>
				)}

				{userDetails?.data?.user?.trucksEnabled && (
					<TruckContainer>
						<div>
							<h4>{TruckOrdersText}</h4>
							<span>{BuildAndManageText}</span>
						</div>

						<Button
							variant="outlined"
							startIcon={<LocalShippingIcon />}
							data-testid="new-orders-truck-orders"
							onClick={() => window.open(`${salesforceBaseURL}/s/place-an-order`, "_blank")}
						>
							{TruckOrdersText.toUpperCase()}
						</Button>
					</TruckContainer>
				)}
				<SaveDraftTable />
			</NewOrdersContentContainer>
			<NewOrdersDialog />
			{modalStateManualOrderEntry && isShowingNewLandingPage && (
				<GlobalFormProvider>
					<AddGlobalAttributes
						showAddItem
						isLandingPage
					/>
				</GlobalFormProvider>
			)}
		</NewOrdersLayoutContainer>
	);
};

export default NewOrderPage;
