import { NewOrdersContentContainer, NewOrdersLayoutContainer } from "components/NewOrders/NewOrders.styles";
import BuildNewOrderLineItems from "components/NewOrders/BuildNewOrderLineItems/BuildNewOrderLineItems";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { BuildOrderHeaderSkeleton } from "pages/OrderPages/BuildOrderPage/buildOrderPage.styles.ts";
import { useParams } from "react-router";
import { OrderAddOns, PlaceANewOrderText } from "constants/text";

export interface AddOnsProps {
	isAddOn?: boolean;
}

const BuildOrderPage = ({ isAddOn }: AddOnsProps) => {
	const params = useParams();
	const [isLoading, setIsLoading] = useState<boolean>(Boolean(params?.draftId));
	const draftOrder = useSelector((state: RootState) => state.newOrder.draftDetails?.draftOrder);

	const handleSetIsLoading = useCallback((value: boolean) => {
		setIsLoading(value);
	}, []);

	return (
		<NewOrdersLayoutContainer>
			{isLoading ? (
				<BuildOrderHeaderSkeleton title={isAddOn ? OrderAddOns : PlaceANewOrderText} />
			) : (
				<NewOrdersHeader
					draftOrder={draftOrder}
					isAddOn={isAddOn}
				/>
			)}

			<NewOrdersContentContainer>
				<BuildNewOrderLineItems
					handleSetIsLoading={handleSetIsLoading}
					draftOrder={draftOrder}
					isAddOn={isAddOn}
				/>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default BuildOrderPage;
