import { FC, useRef, useState } from "react";
import { Card } from "@mui/material";
import { BuildNewOrderLineItemsHeaderWrapper } from "../NewOrders.styles.ts";
import { LineItemsInOrderText } from "constants/text.ts";
import EstimateHeaderLoadingSkeleton from "../EstimatePageSkeletons/EstimateHeaderLoadingSkeleton.tsx";
import AccountBillToContent from "../AccountBillToContent.tsx";
import { models } from "types/api/viewModels.ts";
import { LineItemCount } from "./buildNewOrderLineItems.styles.ts";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store.tsx";
import { ConfigurationGroup } from "../utils/NewOrderUtils.ts";
import ConfigurationContent from "./ConfigurationContent.tsx";
import { LineItemErrors } from "components/NewOrders/BuildNewOrderLineItems/BuildNewOrderLineItems.tsx";

export interface FileContentProps {
	group: ConfigurationGroup;
	isLoading: boolean;
	isAddOn?: boolean;
	handleUpdateAccount: (
		account: models["CustomerAccountViewModel"] | null,
		configurationId: string | null | undefined
	) => void;
	handleUpdateBillTo: (
		billTo: models["BillToViewModel"] | null | undefined,
		configurationId: string | null | undefined
	) => void;
	handleMoveLineItem: (
		item: models["PendingLineItemViewModel"],
		deleteItemIndex: number,
		deleteConfigId: string,
		destinationConfigId: string
	) => void;
	groupIndex: number;
	handleRemoveGroup: (configuration: models["PendingOrderConfigurationViewModel"]) => void;
	handleShowEstimateButton: (shouldShow: boolean) => void;
	handleConfigurationUpdate: (configuration: models["PendingOrderConfigurationViewModel"]) => void;
	searchValue: string | null;
	lineItemErrors: LineItemErrors;
	postError: boolean;
	handleOpenRemoveModal: (item: models["PendingLineItemViewModel"], itemIndex: number, configIndex: number) => void;
	handleLineItem: (configIndex: string, newLineItem: models["PendingLineItemViewModel"]) => void;
	groupedDisplayConfigs: ConfigurationGroup[];
}

const FileContent: FC<FileContentProps> = ({
	group,
	isLoading,
	isAddOn,
	handleUpdateAccount,
	handleUpdateBillTo,
	handleMoveLineItem,
	groupIndex,
	handleRemoveGroup,
	handleShowEstimateButton,
	handleConfigurationUpdate,
	searchValue,
	lineItemErrors,
	postError,
	handleOpenRemoveModal,
	handleLineItem,
	groupedDisplayConfigs
}) => {
	const dispatch = useDispatch();
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const selectedEditItem = useRef<models["PendingLineItemViewModel"] | undefined>();
	const [showEditLineItem, setShowEditLineItem] = useState<number | undefined>(undefined);
	const [editLineItemIndex, setEditLineItemIndex] = useState<number>();
	const [showAddItemButton, setShowAddItemButton] = useState<[number, number] | undefined>(undefined);
	const setConfigurationToUpdate = (configuration: models["PendingOrderConfigurationViewModel"]) => {
		dispatch(newOrderActions.updateGlobalAttributes(configuration));
	};

	const toggleAddItem = (groupIndex?: number, configIndex?: number, shouldShowEstimateButton: boolean = false) => {
		const isUnset = typeof groupIndex === "undefined" || typeof configIndex === "undefined";
		setShowAddItemButton(isUnset ? undefined : [groupIndex, configIndex]);
		handleShowEstimateButton(shouldShowEstimateButton);
	};

	const toggleEditItem = (index?: number) => {
		setShowEditLineItem(index);
		selectedEditItem.current = undefined;
		toggleAddItem(undefined, undefined, true);
	};

	const handleEditLineItem = (item: models["PendingLineItemViewModel"], itemIndex: number, index: number) => {
		setShowEditLineItem(index);
		selectedEditItem.current = item;
		setEditLineItemIndex(itemIndex);
		handleShowEstimateButton(false);
	};

	return (
		<Card>
			<EstimateHeaderLoadingSkeleton isLoading={isLoading} />
			<BuildNewOrderLineItemsHeaderWrapper isLoading={isLoading}>
				{!isLoading && !isAddOn && (
					<AccountBillToContent
						handleUpdateAccount={handleUpdateAccount}
						handleUpdateBillTo={handleUpdateBillTo}
						productLine={group.globals?.productLine?.code ?? ""}
						configGroup={group}
						csvContents={[
							{
								configurations: group.configurations,
								fileName: parsedCSV?.[groupIndex]?.fileName
							}
						]}
						isBuildOrderPage
						handleRemoveGroup={() => handleRemoveGroup(group)}
						handleConfigurationUpdate={(configuration: models["PendingOrderConfigurationViewModel"]) =>
							handleConfigurationUpdate(configuration)
						}
						setConfigurationToUpdate={() => setConfigurationToUpdate(group)}
					>
						<LineItemCount data-testid="new-order-build-table-count">
							{group.configurations.flatMap((config) => config.lineItems).length} {LineItemsInOrderText}
						</LineItemCount>
					</AccountBillToContent>
				)}
			</BuildNewOrderLineItemsHeaderWrapper>
			{group.configurations.map((configuration, configIndex) => (
				<ConfigurationContent
					key={configuration.configurationId}
					configuration={configuration}
					isAddOn={isAddOn}
					isLoading={isLoading}
					postError={postError}
					setConfigurationToUpdate={setConfigurationToUpdate}
					handleConfigurationUpdate={handleConfigurationUpdate}
					configIndex={configIndex}
					handleRemoveGroup={handleRemoveGroup}
					searchValue={searchValue}
					showEditLineItem={showEditLineItem}
					editLineItemIndex={editLineItemIndex}
					group={group}
					toggleEditItem={toggleEditItem}
					selectedEditItem={selectedEditItem}
					handleMoveLineItem={handleMoveLineItem}
					lineItemErrors={lineItemErrors}
					handleEditLineItem={handleEditLineItem}
					handleOpenRemoveModal={handleOpenRemoveModal}
					groupIndex={groupIndex}
					showAddItemButton={showAddItemButton}
					toggleAddItem={toggleAddItem}
					parsedCSV={parsedCSV}
					handleLineItem={handleLineItem}
					groupedDisplayConfigs={groupedDisplayConfigs}
					handleUpdateAccount={() => {}}
					handleUpdateBillTo={() => {}}
					handleShowEstimateButton={() => {}}
				/>
			))}
		</Card>
	);
};

export default FileContent;
