import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";
import { baseQueryWithRedirect } from "features/api/utils/apiUtils.ts";
import { models } from "types/api/viewModels.ts";

export const designerApiSlice = createApi({
	reducerPath: "designerApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getDesignersByAccountId: builder.query<models["DesignerViewModel"][], string>({
			query: (accountId: string) => endpoints.designers.getDesigners(accountId),
			transformResponse: (response: { data: models["DesignerViewModel"][] }) => {
				return response.data;
			}
		})
	})
});

export const { useGetDesignersByAccountIdQuery } = designerApiSlice;
