import { Part, ReplacementCartItem } from "features/reducers/replacementOrder/cart.ts";
import { IMask } from "react-imask";
import { ReplaceablePartsViewModel } from "data/api/v1/model/replaceable-parts-view-model.ts";

export const createReplaceableParts = (replaceableParts: ReplaceablePartsViewModel[], lineItemId: string): Part[] => {
	return replaceableParts.map((part) => {
		return {
			id: part.id ?? "",
			lineItemId: lineItemId,
			partSku: part.sku ?? "",
			partQuantity: 0,
			description: part.description ?? "",
			maxQuantity: part.maxQuantity,
			partFileUploads: [],
			partReplacementReason: null,
			partReplacementProblem: "",
			partDefectCode: "",
			doorStyle: null,
			finish: ""
		};
	});
};

export const getAllItemsCount = (cartItems: ReplacementCartItem[]) => {
	const totalPartQuantity = cartItems?.reduce((total, item) => {
		const itemTotal = item?.replaceableParts?.reduce((partTotal, part) => partTotal + part.partQuantity, 0);
		return total + itemTotal;
	}, 0);
	const wholeCabinetQuantity = cartItems.map((item: ReplacementCartItem) => item.wholeCabinetQuantity);
	const wholeCabinetQuantitySum = wholeCabinetQuantity?.reduce(
		(accumulator: number, currentValue: number) => accumulator + currentValue,
		0
	);

	return totalPartQuantity + wholeCabinetQuantitySum;
};

export const calculateMaxCabinetsToBeReplaced = (lineItem: ReplacementCartItem) => {
	const selectedParts = lineItem.replaceableParts.filter((part) => part.partQuantity > 0);

	const maxTotalCabinets = selectedParts.reduce((max, part) => {
		const partsPerLineItem =
			lineItem.quantityOrdered === 0 ? part.maxQuantity : part.maxQuantity / lineItem.quantityOrdered;
		const totalCabinets = Math.ceil(part.partQuantity / partsPerLineItem);
		return totalCabinets > max ? totalCabinets : max;
	}, 0);

	return lineItem.quantityOrdered - maxTotalCabinets;
};

export const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
	const phoneMasker = IMask.createMask({
		mask: "(000) 000 - 0000"
	});
	if (phoneNumber && phoneNumber.length > 0) {
		phoneMasker.resolve(phoneNumber ?? "");
		return phoneMasker.value;
	}
	return phoneNumber;
};

export const sortReplacementParts = (parts: Part[]) => {
	return parts.sort((a, b) => {
		const isANumber = /^\d/.test(a.partSku);
		const isBNumber = /^\d/.test(b.partSku);

		if (isANumber && !isBNumber) return 1;
		if (!isANumber && isBNumber) return -1;

		return a.partSku.localeCompare(b.partSku, undefined, { numeric: true });
	});
};
