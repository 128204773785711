import styled from "@emotion/styled";

export const NewOrderLandingPageBoxes = styled.div(({ theme }) => ({
	display: "flex",
	gap: "10px",
	marginTop: 8,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

export const NewOrderLandingPageBoxWrapper = styled.div(({ theme }) => ({
	padding: 16,
	backgroundColor: theme.project.colors.backgroundColors.main,
	display: "flex",
	color: theme.project.colors.fontColors.main,
	flexDirection: "column",
	justifyContent: "space-between",
	gap: "12px",
	flex: 1,
	div: {
		display: "flex",
		gap: "8px",
		img: {
			width: "100px",
			height: "100px"
		},
		div: {
			display: "flex",
			flexDirection: "column",
			gap: "8px",
			h6: {
				margin: 0
			},
			span: {
				...theme.project.typography.body.body2,
				color: theme.project.colors.fontColors.secondary
			}
		}
	},
	[theme.breakpoints.down("lg")]: {
		div: {
			flexDirection: "column"
		}
	}
}));
