import styled from "@emotion/styled";
import { LoadingSkeletonProps } from "../LineItemGrid/LineItemGridStyles.ts";

export const ErrorBannerStyles = styled.div<LoadingSkeletonProps>(({ isLoading, theme }) => ({
	...theme.project.typography.body.body2,
	display: isLoading ? "none" : "flex",
	flexDirection: "row",
	padding: 16,
	backgroundColor: theme.project.colors.backgroundColors.error,
	margin: "16px 0",
	color: theme.project.colors.semanticColors.error,
	div: {
		paddingLeft: 4,
		":first-child": {
			...theme.project.typography.body.body1,
			fontWeight: 500
		},
		ul: {
			...theme.project.typography.body.body2,
			marginTop: 0
		},
		li: {
			...theme.project.typography.body.body2
		}
	}
}));
