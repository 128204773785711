import { Component, ErrorInfo, ReactNode } from "react";
import { msalInstance } from "auth/authConfig";
import { AUTH_ERROR, PAGE_ERROR } from "constants/text";

interface Props {
	children: ReactNode;
}

interface State {
	error: Error | undefined;
	errorInfo?: ErrorInfo;
	hasError: boolean;
	hasSourceChanged: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		error: undefined,
		errorInfo: undefined,
		hasError: false,
		hasSourceChanged: false
	};

	public static getDerivedStateFromError(error: Error): State {
		const hasSourceChanged = error.message.includes("Failed to fetch dynamically imported module");
		return { error, hasError: true, hasSourceChanged };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error("Error:", error, errorInfo);
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
	}

	public render() {
		if (this.state.hasError) {
			const isAuthError =
				this.state?.error?.stack?.includes("AADB2C") ||
				this.state?.error?.stack?.includes("BrowserAuthError") ||
				this.state?.error?.stack?.includes("ClientAuthError");

			const urlParams = new URLSearchParams(window.location.search);
			const reloaded = urlParams.get("reloaded");

			if (isAuthError) {
				// Only try again once - here or from apiUtils via token acquisition.
				// The loginRedirect catch has the reloaded check for getting a token so a user who is already in can be
				// sent to the unauthorized page.
				if (reloaded !== "true") {
					// eslint-disable-next-line sonarjs/void-use
					void msalInstance.logoutRedirect().catch((err) => {
						console.error(err);
						window.location.href = "/?reloaded=true";
					});
				} else {
					console.error("Auth Error: Retry failed.");
				}

				return <h1>{AUTH_ERROR}</h1>;
			}

			return <h1>{PAGE_ERROR}</h1>;
		}

		return this.props.children;
	}
}

// Needed for fast-refresh so it's a named export
const ErrorBoundaryClass = ErrorBoundary;

export default ErrorBoundaryClass;
