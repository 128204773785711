import { InferType, object, string } from "yup";
import {
	Address2FormError,
	AddressFormError,
	AddressFormRequiredError,
	CityFormError,
	CountyFormError,
	EmailFormError,
	FullNameFormError,
	PhoneNumberFormError,
	StateFormError,
	ZipFormError
} from "constants/text";
import { RADIO_BUTTON_VALUES } from "pages/Replacements/components/AddNewAddressModal/components/ConfirmAddress.tsx";

const ZipRegex = /^\d{5}(-\d{4})?$/;
// eslint-disable-next-line sonarjs/slow-regex
const EmailRegex = /^.+@.+\..+$/;
const poBoxRegex = /\b(?:P\.? ?O\.?|Post Office)\b.*\b(?:Box|Drawer)\b/i;

export const FULL_NAME_MAX_LENGTH = 30;

export const schema = object({
	fullName: string().max(FULL_NAME_MAX_LENGTH).required(FullNameFormError),
	address: string()
		.required(AddressFormRequiredError)
		.test("notPOBox", AddressFormError, (value) => !poBoxRegex.test(value)),
	address2: string().test(
		"notPOBox",
		Address2FormError,
		(value) => typeof value === "string" && !poBoxRegex.test(value)
	),
	city: string().required(CityFormError).nullable(),
	state: string().required(StateFormError).nullable(),
	zip: string().required(ZipFormError).matches(ZipRegex, ZipFormError).nullable(),
	contactPhoneNumber: string().required(PhoneNumberFormError).min(16, PhoneNumberFormError),
	email: string().matches(EmailRegex, { message: EmailFormError, excludeEmptyString: true }).nullable(),
	county: string().required(CountyFormError).nullable(),
	validateChoice: string().oneOf(Object.values(RADIO_BUTTON_VALUES))
});

export type AddressFormFields = InferType<typeof schema>;
