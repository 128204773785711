import { NewOrderModalTracking } from "data/api/v1";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { ValidateProductsResponseData } from "types/api/products/postValidate";
import { models } from "types/api/viewModels.ts";
import { GetAccountsResponseData } from "types/api/accounts/getAccounts.ts";
import { BillTosShipTosResponseData } from "types/api/accounts/billTosShipTos.ts";
import { convertShipToToAddress } from "utils/address";
import { ImportOrderResponseData } from "types/api/orders/importOrder";

export interface LinkNavigation {
	navItem?: models["UserNavigationItemViewModel"];
	index?: number;
	state: "proceed" | "pause";
}

interface DraftDetails {
	draftOrder: models["DraftOrderViewModel"] | undefined;
	draftError: any;
	// added id state to save drafts and templates in the same session
	currentDraftId?: string;
	currentTemplateId?: string;
}

const newOrder = read("_newOrder");

export interface NewOrderSlice {
	leaveNewOrderFlow: LinkNavigation | undefined;
	modalStatus: NewOrderModalTracking;
	newAddresses: models["ShipToViewModel"][];
	parsedCSV: ImportOrderResponseData[] | undefined;
	parsedTempCSV: ImportOrderResponseData[] | undefined;
	validatedOrderResponse: ValidateProductsResponseData | undefined;
	newGlobalAttributes: models["PendingOrderConfigurationViewModel"] | undefined;
	updatedGlobalAttributes: models["PendingOrderConfigurationViewModel"] | undefined;
	productLineAccounts: Record<string, GetAccountsResponseData>;
	newOrderDetails: models["PendingOrderViewModel"] | undefined;
	draftDetails: DraftDetails | undefined;
}

export const initialState: NewOrderSlice = newOrder || {
	leaveNewOrderFlow: undefined,
	modalStatus: NewOrderModalTracking.NONE,
	newAddresses: [],
	parsedCSV: undefined,
	parsedTempCSV: undefined,
	validatedOrderResponse: undefined,
	newGlobalAttribute: undefined,
	updatedGlobalAttributes: undefined,
	productLineAccounts: {},
	draftDetails: undefined,
	newOrderDetails: undefined
};

const newOrderSlice = createSlice({
	name: "newOrder",
	initialState,
	reducers: {
		addNewAddress: (state, action) => {
			const addressMatchCallback = (shipTo: models["ShipToViewModel"] | null) => {
				return (
					shipTo?.address?.line1 === action.payload?.line1 &&
					shipTo?.address?.line2 === action.payload?.line2 &&
					shipTo?.address?.line3 === action.payload?.line3 &&
					shipTo?.address?.city === action.payload?.city &&
					shipTo?.address?.zip === action.payload?.zip
				);
			};

			if (action.payload && !state.newAddresses?.some(addressMatchCallback)) {
				state.newAddresses?.push(action.payload);
			}

			return state;
		},
		updateOrderType: (state, action) => {
			state.newOrderDetails = {
				...state.newOrderDetails,
				orderType: action.payload
			};
			state.draftDetails = {
				draftError: state.draftDetails?.draftError,
				draftOrder: {
					...state.draftDetails?.draftOrder,
					orderType: action.payload
				}
			};
		},
		setDraftId: (
			state,
			action: PayloadAction<{
				currentDraftId: string | undefined;
				isTemplate?: boolean;
			}>
		) => {
			state.draftDetails = {
				draftError: state.draftDetails?.draftError,
				draftOrder: state.draftDetails?.draftOrder,
				currentDraftId: action.payload.isTemplate
					? state.draftDetails?.currentDraftId
					: action.payload.currentDraftId,
				currentTemplateId: action.payload.isTemplate
					? action.payload.currentDraftId
					: state.draftDetails?.currentTemplateId
			};
		},
		setModalState: (state, action) => {
			state.modalStatus = action.payload;
		},
		addParsedCSV: (state, action: PayloadAction<ImportOrderResponseData>) => {
			state.parsedCSV = [...(state.parsedCSV ?? []), action.payload];
		},
		overwriteParsedCSV: (state, action: PayloadAction<ImportOrderResponseData[] | undefined>) => {
			state.parsedCSV = action.payload;
		},
		addTempParsedCSV: (
			state,
			action: PayloadAction<{
				importedCSV: models["ImportedOrderViewModel"];
				existingConfigs: models["PendingOrderConfigurationViewModel"][];
			}>
		) => {
			const importedCSVData = { ...action.payload.importedCSV };

			const recreatedCSVData: ImportOrderResponseData[] = Array.from(
				action.payload.existingConfigs
					?.reduce((accum: Map<number, ImportOrderResponseData>, current) => {
						const findCSVCb = (csv: ImportOrderResponseData) =>
							csv?.configurations?.find((config) => config.configurationId === current.configurationId);
						const existingStateCSVIndex = state.parsedCSV?.findIndex(findCSVCb) ?? -1;
						const existingStateCSV = state.parsedCSV?.[existingStateCSVIndex];
						const accumCSV = accum.get(existingStateCSVIndex);

						if (!accumCSV) {
							accum.set(existingStateCSVIndex, {
								...(existingStateCSVIndex !== -1 ? existingStateCSV : {}),
								configurations: [current]
							});
						} else {
							accum.set(existingStateCSVIndex, {
								...accumCSV,
								configurations: [...(accumCSV?.configurations ?? []), current]
							});
						}
						return accum;
					}, new Map())
					?.values() ?? []
			);

			const allExistingConfigs = recreatedCSVData?.flatMap((csv) => csv?.configurations);

			if (!state.parsedTempCSV) state.parsedTempCSV = recreatedCSVData;

			if (importedCSVData) {
				const importedCSVDataCodes = recreatedCSVData?.flatMap((e) =>
					e?.configurations?.map((e) => e?.globals?.productLine?.code)
				);
				importedCSVDataCodes?.forEach((code) => {
					// we want to find an existing config with a given product line code
					// and then apply the account and billing info to all configs with our product line
					const existingConfigInProductLine = allExistingConfigs?.find(
						(config) => config?.globals?.productLine?.code === code
					);
					// this needs to be only for configs in the product line
					const updatedConfigs = importedCSVData?.configurations?.map((config) => {
						if (config.globals?.productLine?.code === code) {
							return {
								...config,
								accountId: existingConfigInProductLine?.accountId,
								accountName: existingConfigInProductLine?.accountName,
								accountNumber: existingConfigInProductLine?.accountNumber,
								billToId: existingConfigInProductLine?.billToId
							};
						}
						return config;
					});
					importedCSVData.configurations = updatedConfigs;
				});

				state?.parsedTempCSV?.push(importedCSVData);
			}
		},
		clearTempParsedCSV: (state) => {
			state.parsedTempCSV = undefined;
		},
		updateGlobalAttributes: (state, action: PayloadAction<models["PendingOrderConfigurationViewModel"]>) => {
			state.updatedGlobalAttributes = { ...state.updatedGlobalAttributes, ...action.payload };
		},
		clearUpdateGlobalAttributes: (state) => {
			state.updatedGlobalAttributes = undefined;
		},
		updateAccountNumber: (
			state,
			action: PayloadAction<{
				fileIndex?: number;
				productLineCode: string | undefined | null;
				newAccountNumber: models["CustomerAccountViewModel"] | null;
			}>
		) => {
			const { fileIndex, productLineCode, newAccountNumber } = action.payload;
			if (fileIndex !== undefined && productLineCode !== undefined && ~fileIndex && productLineCode) {
				(state?.parsedTempCSV ?? state?.parsedCSV)?.forEach((file) => {
					file?.configurations?.forEach((configuration) => {
						if (configuration.globals?.productLine?.code === productLineCode) {
							configuration.accountId = newAccountNumber === null ? null : newAccountNumber?.accountId;
							configuration.accountName =
								newAccountNumber === null ? null : newAccountNumber?.accountName;
							configuration.accountNumber =
								newAccountNumber === null ? null : newAccountNumber?.accountNumber;
						}
					});
				});
			}
			if (productLineCode !== undefined && state.draftDetails?.draftOrder?.configurations && productLineCode) {
				state.draftDetails?.draftOrder.configurations.forEach((configuration) => {
					if (configuration.globals?.productLine?.code === productLineCode) {
						configuration.accountId = newAccountNumber === null ? null : newAccountNumber?.accountId;
						configuration.accountName = newAccountNumber === null ? null : newAccountNumber?.accountName;
						configuration.accountNumber =
							newAccountNumber === null ? null : newAccountNumber?.accountNumber;
					}
				});
			}
		},
		updateBillToNumber: (
			state,
			action: PayloadAction<{
				fileIndex?: number;
				productLineCode: string | undefined | null;
				newBillToNumber: models["BillToViewModel"] | null | undefined;
			}>
		) => {
			const { fileIndex, productLineCode, newBillToNumber } = action.payload;
			if (fileIndex !== undefined && productLineCode !== undefined && ~fileIndex && productLineCode) {
				(state?.parsedTempCSV ?? state?.parsedCSV)?.forEach((file) => {
					file?.configurations?.forEach((configuration) => {
						if (configuration.globals?.productLine?.code === productLineCode) {
							configuration.billToId = newBillToNumber === null ? null : newBillToNumber?.billToId;
						}
					});
				});
			}
			if (productLineCode !== undefined && state.draftDetails?.draftOrder?.configurations && productLineCode) {
				state.draftDetails.draftOrder.configurations.forEach((configuration) => {
					if (configuration.globals?.productLine?.code === productLineCode) {
						configuration.billToId = newBillToNumber?.billToId ?? "";
					}
				});
			}
		},
		addValidatedOrder: (state, action) => {
			state.validatedOrderResponse = action.payload;
		},
		clearNewOrder: (state) => {
			state.modalStatus = NewOrderModalTracking.NONE;
			state.newAddresses = [];
			state.parsedCSV = undefined;
			state.validatedOrderResponse = undefined;
			state.newGlobalAttributes = undefined;
			state.updatedGlobalAttributes = undefined;
			state.newOrderDetails = undefined;
			state.draftDetails = undefined;
		},
		leaveNewOrderFlow: (state, action) => {
			state.leaveNewOrderFlow = action.payload;
		},
		continueNewOrderFlow: (state) => {
			if (state.leaveNewOrderFlow) {
				state.leaveNewOrderFlow = { ...state.leaveNewOrderFlow, state: "proceed" };
			}
		},
		resetNewOrderFlow: (state) => {
			state.leaveNewOrderFlow = undefined;
		},
		addProductLineAccountInfo: (
			state,
			{ payload: [productLineCode, data] }: PayloadAction<[string, GetAccountsResponseData]>
		) => {
			state.productLineAccounts = {
				...state.productLineAccounts,
				[productLineCode]: data
			};
		},
		updateProductLineAccountInfoShipTos: (state, { payload }: PayloadAction<BillTosShipTosResponseData>) => {
			// Unused now but maybe useful later to update product line billTos with their shipTos
			if (state.productLineAccounts) {
				Object.entries(state.productLineAccounts).forEach(([productLineCode, accounts]) => {
					if (state.productLineAccounts[productLineCode]) {
						state.productLineAccounts[productLineCode] = accounts?.map((account) => ({
							...account,
							billTos: account.billTos?.map((billTo) => ({
								...billTo,
								// eslint-disable-next-line sonarjs/no-nested-functions
								shipTos: payload?.filter((shipTo) => shipTo?.billToId === billTo?.billToId) ?? []
							}))
						}));
					}
				});
			}
		},
		overwriteOrderDetails: (state, action: { payload: models["PendingOrderViewModel"] }) => {
			state.newOrderDetails = {
				...action.payload,
				tandemOrderId: state.newOrderDetails?.tandemOrderId ?? action.payload.tandemOrderId
			};
		},
		updateShippingDetails: (state, action: { payload: models["ShipToViewModel"] }) => {
			state.newOrderDetails = {
				...state.newOrderDetails,
				shipToId: convertShipToToAddress(action.payload).shipToId,
				shipToAddress: convertShipToToAddress(action.payload).address
			};
		},
		addSingleConfigToOrder: (
			state,
			action: { payload: models["PendingOrderConfigurationViewModel"] | undefined }
		) => {
			if (action.payload) {
				state.newOrderDetails = {
					...state.newOrderDetails,
					configurations: [...(state.newOrderDetails?.configurations || []), action.payload]
				};
				state.draftDetails = {
					...state.draftDetails,
					draftError: state.draftDetails?.draftError ?? undefined,
					draftOrder: {
						...state.draftDetails?.draftOrder,
						configurations: [...(state.draftDetails?.draftOrder?.configurations || []), action.payload]
					},
					currentDraftId: state?.draftDetails?.currentDraftId,
					currentTemplateId: state?.draftDetails?.currentTemplateId
				};
			}
			state.newGlobalAttributes = action.payload;
		},
		addMultiConfigsToOrder: (state, action: { payload: models["PendingOrderConfigurationViewModel"][] }) => {
			state.newOrderDetails = {
				...state.newOrderDetails,
				configurations: [...(state.newOrderDetails?.configurations || []), ...action.payload]
			};
			state.draftDetails = {
				draftError: state.draftDetails?.draftError ?? undefined,
				draftOrder: {
					...state.draftDetails?.draftOrder,
					configurations: [...(state.draftDetails?.draftOrder?.configurations || []), ...action.payload]
				},
				currentDraftId: state?.draftDetails?.currentDraftId,
				currentTemplateId: state?.draftDetails?.currentTemplateId
			};
		},
		overwriteDraftOrder: (state, action: { payload: models["DraftOrderViewModel"] }) => {
			state.draftDetails = {
				draftError: state.draftDetails?.draftError ?? undefined,
				draftOrder: {
					...action.payload,
					tandemOrderId: state.newOrderDetails?.tandemOrderId ?? action.payload.tandemOrderId
				},
				currentDraftId: state?.draftDetails?.currentDraftId,
				currentTemplateId: state?.draftDetails?.currentTemplateId
			};
		},
		updateDraftError: (state, action) => {
			state.draftDetails = {
				draftOrder: state.draftDetails?.draftOrder,
				draftError: action.payload,
				currentDraftId: state?.draftDetails?.currentDraftId,
				currentTemplateId: state?.draftDetails?.currentTemplateId
			};
		},
		clearDraftError: (state) => {
			state.draftDetails = {
				draftOrder: state.draftDetails?.draftOrder,
				draftError: undefined,
				currentDraftId: state?.draftDetails?.currentDraftId,
				currentTemplateId: state?.draftDetails?.currentTemplateId
			};
		}
	}
});

export const newOrderActions = newOrderSlice.actions;
export default newOrderSlice.reducer;
