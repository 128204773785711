import OrderDetailsLineItemGrid from "./OrderDetailsLineItemGrid";
import { models } from "types/api/viewModels";

export interface LineItemProps {
	orderDetail: models["OrderDetailViewModel"] | null;
	groupAllByNoneTrackingNumber?: any;
	searchValue?: string | null;
	hasPartBackorder?: number | null;
	hasCabinetBackorder?: number | null;
	lineItemsOnBackorderCount?: number | null;
}

const OrderDetailsLineItem = ({ searchValue, orderDetail, groupAllByNoneTrackingNumber }: LineItemProps) => {
	const foundSearch = groupAllByNoneTrackingNumber?.find((item: any) =>
		`${item.sku.toUpperCase()} ${item.description.toUpperCase()}`.includes((searchValue ?? "")?.toUpperCase())
	);
	return (
		<div>
			{groupAllByNoneTrackingNumber?.map(
				(item: any, index: any) =>
					(`${item.sku.toUpperCase()} ${item.description.toUpperCase()}`.includes(
						(searchValue ?? "")?.toUpperCase()
					) ||
						!foundSearch) && (
						<OrderDetailsLineItemGrid
							key={index}
							description={item.description}
							doorStyle={item.doorStyle}
							finish={item.finish}
							quantityOrdered={item.quantityOrdered}
							modifications={item.modifications}
							sku={item?.sku}
							parts={item?.partInfo?.parts}
							extendedPrice={item.extendedPrice}
							partInfoPartCount={item?.partInfo?.parts?.map((part: any) => part).length}
							partsQuantityBackordered={item?.partInfo?.partsQuantityBackordered}
							measurements={item.measurements}
							logo={orderDetail?.parentBrand}
							listPrice={item.listPrice}
							quantityBackordered={item.quantityBackordered}
							arrivalDate={item?.arrivalDate}
							construction={item?.construction}
							hinge={item?.hinge}
							orderType={orderDetail?.orderType}
							lineItemNumber={item.lineItemNumber}
							itemNumber={item.name}
							replaceableParts={item?.replaceableParts}
							lineItemId={item?.lineItemId}
						/>
					)
			)}
		</div>
	);
};

export default OrderDetailsLineItem;
