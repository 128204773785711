import { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import {
	AddressWrapper,
	CommentWrapper,
	OrderDetailsColumnsContainer,
	OrderDetailsSummaryContainer
} from "components/OrderDetailsSummary/orderDetailsSummary.styles";
import {
	AccessoryCountText,
	AccountNumberText,
	CabinetCountText,
	CubeCountText,
	DesignerNumberText,
	DoorStyleText,
	FinishText,
	UnavailableDataPlaceholderText
} from "constants/text";
import BillToBox from "components/Common/BillToShipToBox/BillToBox/BillToBox";
import ShipToBox from "components/Common/BillToShipToBox/ShipToBox/ShipToBox";
import { useGetAccountsByIdQuery, useGetBillToInfoQuery } from "features/api/accountApi.ts";
import { models } from "types/api/viewModels.ts";

const OrderDetailsSummary = ({ orderDetail }: Props) => {
	const [isReadMore, setIsReadMore] = useState(true);

	const toggleReadMore = () => {
		setIsReadMore(!isReadMore);
	};

	const [billTo, setBillTo] = useState<models["BillToViewModel"] | null>(null);

	const { data: accounts } = useGetAccountsByIdQuery(orderDetail.orderId ?? "", { skip: !orderDetail.orderId });

	const accountId = accounts?.[0]?.accountId;

	const { data: billToInfo } = useGetBillToInfoQuery(
		{ accountId: accountId ?? "", billToId: orderDetail.billToId ?? "" },
		{ skip: !accountId || !orderDetail.billToId }
	);

	useEffect(() => {
		if (billToInfo) {
			setBillTo(billToInfo);
		}
	}, [billToInfo]);

	const userAccountInformation = useMemo(() => {
		const accountMatch = accounts?.find(
			(account: models["CustomerAccountViewModel"]) => account.accountNumber === orderDetail?.accountNumber
		);
		if (accountMatch) {
			return `${accountMatch.accountNumber}` + (accountMatch.accountName ? ` - ${accountMatch.accountName}` : "");
		} else {
			return "";
		}
	}, [accounts, orderDetail]);

	return (
		<OrderDetailsSummaryContainer>
			<OrderDetailsColumnsContainer>
				<div data-testid="orderDetails-accountNumberColumn">
					<div>{AccountNumberText}</div>
					<div>
						{(userAccountInformation.length ? userAccountInformation : orderDetail?.accountNumber) ??
							UnavailableDataPlaceholderText}
					</div>
				</div>
				<div>
					<div>{DesignerNumberText}</div>
					<div data-testid="orderDetailsHeader-Designer">
						{orderDetail?.designer?.designerNumber ?? UnavailableDataPlaceholderText}
					</div>
				</div>
				<div data-testid="orderDetails-doorStyleColumn">
					<div>{DoorStyleText}</div>
					<div>{orderDetail?.doorStyle ?? UnavailableDataPlaceholderText}</div>
				</div>
				<div data-testid="orderDetails-finishColumn">
					<div>{FinishText}</div>
					<div>{orderDetail?.finish ?? UnavailableDataPlaceholderText}</div>
				</div>
				<div data-testid="orderDetails-cabinetCountColumn">
					<div>{CabinetCountText}</div>
					{orderDetail?.counts?.cabinetCount ? (
						<div>{orderDetail?.counts?.cabinetCount}</div>
					) : (
						<div>{UnavailableDataPlaceholderText}</div>
					)}
				</div>
				<div data-testid="orderDetails-accessoryCountColumn">
					<div>{AccessoryCountText}</div>
					{orderDetail?.counts?.accessoryCount ? (
						<div>{orderDetail?.counts?.accessoryCount}</div>
					) : (
						<div>{UnavailableDataPlaceholderText}</div>
					)}
				</div>
				<div data-testid="orderDetails-cubeCountColumn">
					<div>{CubeCountText}</div>
					{orderDetail?.counts?.cubeCount ? (
						<div>{orderDetail?.counts?.cubeCount}</div>
					) : (
						<div>{UnavailableDataPlaceholderText}</div>
					)}
				</div>
			</OrderDetailsColumnsContainer>
			<AddressWrapper>
				<ShipToBox
					orderDetail={orderDetail}
					dataTestIdPrefix="orderDetails"
				/>
				<BillToBox
					billTo={billTo}
					orderType={orderDetail.orderType}
					isNoChargeOrder={orderDetail.isNoChargeOrder}
					orderDetailSubTotal={orderDetail.subTotal}
					hasBorder
					hasMinHeight
					dataTestIdPrefix="orderDetails"
				/>
			</AddressWrapper>
			{orderDetail.comments && orderDetail?.comments?.length > 1 && (
				<CommentWrapper>
					<p
						className="body2"
						data-testid="orderDetails-orderComments"
					>
						{orderDetail?.comments?.length > 1 ? <b className="subtitle2">Comment: </b> : null}
						{isReadMore ? orderDetail?.comments?.slice(0, 250) : orderDetail?.comments}
						{orderDetail?.comments?.length > 250 && (
							<Button
								variant="text"
								sx={{ padding: 0 }}
								onClick={toggleReadMore}
								onKeyDown={toggleReadMore}
								data-testid="orderDetails-commentViewMoreViewLess"
							>
								{isReadMore ? "...View More" : "...View Less"}
							</Button>
						)}
					</p>
				</CommentWrapper>
			)}
		</OrderDetailsSummaryContainer>
	);
};

interface Props {
	orderDetail: models["OrderDetailViewModel"];
}

export default OrderDetailsSummary;
