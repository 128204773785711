import { useEffect } from "react";
import { useNavigate } from "react-router";

interface PopStateParams {
	title: string;
	path: string;
	dispatchActions?: () => void;
}

const useHandlePopState = ({ title, path, dispatchActions = () => {} }: PopStateParams) => {
	const navigate = useNavigate();

	useEffect(() => {
		const useHandlePopState = () => {
			window.history.replaceState(null, title, path);
			dispatchActions?.();
			navigate(path, { replace: true });
		};

		window.onpopstate = useHandlePopState;

		return () => {
			window.onpopstate = () => {};
		};
	}, [title, path, dispatchActions, navigate]);
};

export default useHandlePopState;
