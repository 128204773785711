import { NewOrdersSubheaderWrapper } from "../NewOrders.styles";
import { NewDraft400ErrorText, SearchItemText } from "constants/text";
import { SyntheticEvent, useMemo } from "react";
import SaveDraft from "../SaveDraft/SaveDraft";
import SuggestedSearch from "components/Common/SuggestedSearch/SuggestedSearch";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { DraftErrorTitle, ImportItems, NewOrderContactCare } from "../constants";
import AddGlobalAttributes from "../AddGlobalAttributes/AddGlobalAttributes";
import GlobalFormProvider from "../AddGlobalAttributes/forms/GlobalFormProvider";
import { models } from "types/api/viewModels.ts";
import NewOrdersDialog from "../NewOrdersDialog/NewOrdersDialog.tsx";
import { newOrderActions } from "../../../features/reducers/newOrder/newOrder.ts";
import { NewOrderModalTracking } from "../../../data/api/v1";
import { Button } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { useLocation, useParams } from "react-router";
import useInitOrderDetail from "hooks/useInitOrderDetail.ts";
import ErrorBanner from "../../Common/ErrorBanner/ErrorBanner.tsx";
import { APIErrorResponse, UntransformedResponse } from "../../../features/api/utils/apiUtils.ts";

interface NewOrdersSubheaderProps {
	title: string;
	dataTestId: string;
	hasSearch?: boolean;
	handleSearch: (event: SyntheticEvent, values: string | null) => void;
	searchOptions?: Array<string>;
	searchValue?: string;
	autoCompleteId?: string;
	isLoading?: boolean;
	draftOrder: models["DraftOrderViewModel"] | undefined;
	hasAddGlobalAttributesButton?: boolean;
	isAddOn?: boolean;
}

const handleErrorMessaging = (error: UntransformedResponse<APIErrorResponse>) => {
	return (
		<div>
			<div>{DraftErrorTitle}</div>
			<div>{NewDraft400ErrorText}</div>
			<ul>
				{error?.data?.errors?.map((error: any, index: any) => (
					<li key={error.status + index}>{error.message}</li>
				))}
			</ul>
			<div>{NewOrderContactCare}</div>
			<div>({error?.status})</div>
		</div>
	);
};

const NewOrdersSubheader = ({
	title,
	dataTestId,
	hasSearch = false,
	handleSearch,
	isLoading,
	searchOptions,
	searchValue,
	autoCompleteId,
	draftOrder,
	hasAddGlobalAttributesButton = false,
	isAddOn = false
}: NewOrdersSubheaderProps) => {
	const isDraftError = useSelector((state: RootState) => state.newOrder.draftDetails?.draftError);
	const location = useLocation();
	const dispatch = useDispatch();
	const params = useParams();
	const { orderDetail } = useInitOrderDetail(params.orderId);

	const handleImport = () => {
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.CSV_UPLOAD));
	};
	// Keeping an useMemo to ensure add-ons data is updated
	useMemo(() => {
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
	}, [dispatch]);

	return (
		<div>
			<NewOrdersSubheaderWrapper
				isLoading={isLoading}
				data-testid={dataTestId}
			>
				<div>
					<h1>{title}</h1>
					<div>
						{hasAddGlobalAttributesButton && (
							<GlobalFormProvider>
								<AddGlobalAttributes isAddOn={isAddOn} />
							</GlobalFormProvider>
						)}
						{(location.pathname === "/new-order/build-order" ||
							location.pathname === `/new-order/build-order/${params.draftId}` ||
							location.pathname === `/add-ons/build-order/${params.orderId}`) && (
							<Button
								variant="text"
								onClick={handleImport}
							>
								<UploadIcon />
								{ImportItems}
							</Button>
						)}

						<NewOrdersDialog
							isAddOn={isAddOn}
							orderDetails={orderDetail}
						/>

						<SaveDraft
							draftOrder={draftOrder}
							isAddOn={isAddOn}
						/>
						{hasSearch && (
							<div>
								<SuggestedSearch
									ariaLabel={autoCompleteId ?? ""}
									dataTestId={autoCompleteId ?? ""}
									dropDownOpenOnChar={3}
									placeholder={SearchItemText}
									handleSearch={handleSearch}
									id={autoCompleteId ?? ""}
									searchOptions={searchOptions ?? []}
									searchValue={searchValue ?? ""}
									size="small"
								/>
							</div>
						)}
					</div>
				</div>
			</NewOrdersSubheaderWrapper>
			{isDraftError && <ErrorBanner errorMessaging={handleErrorMessaging(isDraftError)} />}
		</div>
	);
};

export default NewOrdersSubheader;
